import { useEffect } from 'react';

/**
 * Auto scroll to the element of the page that its id is specified in hash section of the URL
 * @example https://www.example.com/page#Section1
 * @description Credit goes to:
 * @link https://dev.to/alejomartinez8/how-to-load-a-hash-fragment-to-an-anchor-name-in-react-especially-in-first-loading-g3i
 * @param offset
 * @param trigger
 */
export default function useHashFragment(offset = 0, trigger = true) {
	useEffect(() => {
		const scrollToHashElement = () => {
			const { hash } = window.location;
			const elementToScroll = document.getElementById(hash?.replace("#", ""));
			
			if (!elementToScroll) return;
			
			window.scrollTo({
				top: elementToScroll.offsetTop - offset,
				behavior: "smooth"
			});
		};
		
		if (!trigger) return;
		
		scrollToHashElement();
		window.addEventListener("hashchange", scrollToHashElement);
		return window.removeEventListener("hashchange", scrollToHashElement);
	}, [ offset, trigger ]);
};
