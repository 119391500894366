import './App.scss';

import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from './components/header/header.component';
import Preloader from './components/preloader/preloader.component';
import Footer from './components/footer/footer.component';
import LiveChatSupport from './components/live-chat-support/live-chat-support.component';
import TraderAccountsDetails from './components/trader-accounts-details/trader-accounts-details.component';
import SubscriptionDetails from './components/subscription-details/subscription-details.component';
import SubscriptionSettings from './components/subscription-settings/subscription-settings.component';
import Authenticator from './components/Authenticator';
import ProfitCounter from './components/profit-counter/profit-counter.component';
import Notifications from './components/notifications/notifications.component';
import Home from './pages/home/home.page';
import Pricing from './pages/pricing/pricing.page';
import Cart from './pages/cart/cart.page';
import Payment from './pages/payment/payment.page';
import Tutorials from './pages/tutorials/tutorials.page';
import AboutUs from './pages/about-us/about-us.page';
import Login from './pages/login/login.page';
import Register from './pages/register/register.page';
import Dashboard from './pages/dashboard/dashboard.page';
import { selectCart } from './redux/core/cart/cart.selectors';
import { selectTraderAccount } from './redux/trader-accounts-page/trader-accounts-page.selectors';
import { selectSubscriptionId, selectSubscriptionSettingsId } from './redux/subscription-page/subscription-page.selectors';
import ContactUs from './pages/contact-us/contact-us.page';
import PrivacyPage from './pages/privacy-policy/privacy-policy.page';
import TermsOfServicePage from './pages/terms-of-service/terms-of-service.page';

const App = () => {
    const cart = useSelector(selectCart);
    const traderAccount = useSelector(selectTraderAccount);
    const subscriptionId = useSelector(selectSubscriptionId);
    const subscriptionSettingsId = useSelector(selectSubscriptionSettingsId);
    const location = useLocation();
    const selectedSubscription = cart.items[0];
    
    // For the cart page, if no subscription is selected, we will navigate to the pricing page.
    if (!selectedSubscription && location.pathname === '/cart')
    {
        return <Navigate to='/pricing'/>;
    }
    
    const profitCounterChildEvents = { handleMouseMove: () => {}, handleTouchMove: () => {} };
    
    return (
        <div className='App'
             onMouseMove={ (e) => profitCounterChildEvents.handleMouseMove(e)}
             onTouchMove={ (e) => profitCounterChildEvents.handleTouchMove(e)}>
            <Preloader/>
            <Header/>
            <Routes>
                { /* Routes that do NOT need authentication */ }
                <Route path='/' element={<Authenticator needAuth={false} />}>
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/register' element={<Register />} />
                    <Route exact path='/pricing' element={<Pricing />} />
                    <Route exact path='/tutorials' element={<Tutorials />} />
                    <Route exact path='/about' element={<AboutUs />} />
                    <Route exact path='/contact' element={<ContactUs />} />
                    <Route exact path='/privacy-policy' element={<PrivacyPage />} />
                    <Route exact path='/terms-of-service' element={<TermsOfServicePage />} />
                    <Route exact path='/' element={<Home />}/>
                </Route>
                { /* Routes that need authentication */ }
                <Route path='/' element={<Authenticator needAuth={true} />}>
                    <Route exact path='/cart' element={<Cart />} />
                    <Route exact path='/payment/:subscriptionId' element={<Payment />} />
                    <Route path='/dashboard/*' element={<Dashboard />} />
                </Route>
                { /* Not found routes */ }
                <Route path='/*' element={<Home />}/>
            </Routes>
            <Footer/>
            <ProfitCounter events={profitCounterChildEvents} />
            { 0 ? <LiveChatSupport/> : undefined }
            {
                traderAccount ?
                    <TraderAccountsDetails />
                    :
                    null
            }
            {
                subscriptionId > 0 ?
                    <SubscriptionDetails />
                    :
                    null
            }
            {
                subscriptionSettingsId > 0 ?
                    <SubscriptionSettings />
                    :
                    null
            }
            <Notifications />
        </div>
    );
}

export default App;
