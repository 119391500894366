import './notifications.styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";

import { selectNotifications } from '../../redux/core/notifications/notifications.selectors';
import NotificationItem from '../notification-item/notification-item.component';
import PropTypes from 'prop-types';
import * as appPropTypes from '../app-prop-types';

const Notifications = ({ notifications }) => (
    <div className='notifications'>
        {
            notifications.map(notification => (
                <NotificationItem key={notification.id} notification={notification} />
            ))
        }
    </div>
);

Notifications.propTypes = {
    notifications   : PropTypes.arrayOf(appPropTypes.NotificationType).isRequired
};

const mapStateToProps = createStructuredSelector({
    notifications: selectNotifications
});

export default connect(mapStateToProps)(Notifications);
