import './terms-of-service.styles.scss';
import AOS from 'aos';
import React, { useEffect } from 'react';
import TermsOfServiceComponent from '../../components/terms-of-service/terms-of-service.component';

const TermsOfServicePage = () =>
{
	useEffect(
		() => {
			AOS.init();
		}
		, []
	);
	
	return (
		<>
			<div className="breadcrumbs">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 offset-lg-3 col-md-12 col-12">
							<div className="breadcrumbs-content">
								<h1 className="page-title">Terms of Service</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section bg-white">
				<div className="container">
					<div className="row g-0 mb-5 justify-content-center" data-aos="fade-up" data-aos-delay="600"
						 data-aos-once="true">
						<TermsOfServiceComponent />
					</div>
				</div>
			</div>
		</>
	);
};

export default React.memo(TermsOfServicePage);
