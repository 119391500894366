import './subscription-row.styles.scss';

import React from 'react';
import { useDispatch } from 'react-redux';

import { setSubscriptionId, setSubscriptionSettingsId } from '../../redux/subscription-page/subscription-page.actions';

const SubscriptionRow = ({ subscription, plan }) => {
    const dispatch = useDispatch();
    
    const setSubscriptionIdDispatch = (subscriptionId) => dispatch(setSubscriptionId(subscriptionId));
    const setSubscriptionSettingsIdDispatch = (subscriptionId) => dispatch(setSubscriptionSettingsId(subscriptionId));
    
    return (
        <tr>
            <td className='align-middle text-center p-1 p-md-3'>{subscription.name || 'N/A'}</td>
            <td className='align-middle text-center p-1 p-md-3'>{plan.name}</td>
            <td className='align-middle text-center p-1 p-md-3'>{plan.price_discounted} $</td>
            <td className='align-middle text-center d-none d-md-table-cell p-1 p-md-3'>{plan.days / 30 > 1 ? (Math.floor(plan.days / 30)) + ' Months' : plan.days + ' Days'}</td>
            <td className='align-middle text-center p-1 p-md-3'>
                {
                    subscription.expired || new Date(subscription.expiry_date) <= new Date() ?
                        <span className='text-danger'>Yes</span>
                        :
                        <span className='text-success'>No</span>
                }
            </td>
            <td className='align-middle text-center p-1 p-md-3'>
                <div className='button d-block d-md-inline-block me-0 me-md-2 mb-2 mb-md-0'>
                    <button className='btn px-2 py-1 px-md-3 py-md-2' onClick={() => {setSubscriptionIdDispatch(subscription.id)}}>See details</button>
                </div>
                {
                    plan?.type.endsWith('_binary')
                        ? (
                            <div className='button d-block d-md-inline-block'>
                                <button className='btn px-2 py-1 px-md-3 py-md-2' onClick={() => {setSubscriptionSettingsIdDispatch(subscription.id)}}>Settings</button>
                            </div>
                        )
                       : undefined
                }
            </td>
        </tr>
    );
};

export default SubscriptionRow;
