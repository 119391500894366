import './home.styles.scss';

import React, {useEffect, useRef, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import Clock from 'react-live-clock';
import AOS from 'aos';

import { ReactComponent as HomeSvg } from '../../svgs/home-shape.svg';
import { getPrices } from '../../libs/client/SubscriptionManager';
import { getTodayContractsStats } from '../../libs/client/StatisticsManager';
import PricingItem from '../../components/pricing-item/pricing-item.component';
import { MDBBadge } from 'mdb-react-ui-kit';

const Home = () => {
    //const priceSlide1Ref = useRef();
    //const priceSlide2Ref = useRef();
    //const pauseRef = useRef(false);
    /*const intervalRef = useRef(null);*/
    const tradingsRef = useRef();
    const intervalRef = useRef(null);

    const [pricesList, setPricesList] = useState([]);
    const [tradings, setTradings] = useState([]);
    const [totals, setTotals] = useState({
        profit : 0,
        loss   : 0,
    });

    const faqStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: [
            {
                '@type': 'Question',
                name: `01. What is your strategy's win rate?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    While it is difficult to have consistent results on a daily basis, our
                                    strategy has proven to provide a <u>minimum</u> win rate of 80%. That is, out of
                                    10 trades, 8 of them will definitely end-up being in profit while the other
                                    two might or might not be in loss.
                                </p>
                                <p>
                                    The effectiveness of our strategy can be changed by customizing its parameters
                                    within your subscription's settings.
                                    Please note that we only guarantee 80% win rate when using default parameters.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `02. How much money will I make?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    Since you can can customize the parameters of our strategy, hence having
                                    variable win rates per each subscription/user, the income will be variable
                                    in regard to that.
                                </p>
                                <p>
                                    But, based on our guaranteed 80% win rate as well as our predefined trade
                                    parameters (contract size, martingale type, etc.), you will profit <b>at
                                    least 5%</b> of your invested money weekly. Amazing, isn't it?
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `03. What is the minimum investment?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    To gain a reasonable profit using our platform, we recommend to have
                                    at least <u>1000 USD</u> deposited into your binary.com/deriv.com account. However, the
                                    minimum deposit amount shall be <u>100 USD</u>. Any amount below that must be
                                    avoided.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `04. Are there risks of my account being blocked?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    No. There are absolutely no risks of your account being blocked or banned
                                    by your broker while using BinaryDew Platform.
                                </p>
                                <p>
                                    Our development teams and legal departments have examined our platform
                                    and officially approved it.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `05. Which brokers does BinaryDew work with?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    We currently only support Binary.com/Deriv.com.
                                </p>
                                <p>
                                    We have plans to support more brokers in near future. Please create an
                                    account on our platform to get latest news by email.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `06. What are the accepted payment methods?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    We accept all credit/debit cards as well as mobile money through FlutterWave.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `07. Do I need to leave my PC on all day long?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    No. When you buy a subscription from us, you will enter an API token
                                    of your Binary.com's or Deriv.com's account. Then, our platform will trade on your behalf
                                    automatically 24/7 while you enjoy your life.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `08. Can I connect any MetaTrader indicator?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `
                    <p>
                                    No. Opposing our competitors, we are doing all the hard work for you.
                                    You do not need to learn a strategy or buy/develop EAs anymore. All you
                                    have to do is to deposit money into your Binary.com's or Deriv.com's account and buy a
                                    subscription from us. You will then instantly start profiting.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `09. Can I use Martingale strategy?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    Our platform uses Martingale strategy by default. You can disable it by
                                    editing your subscription's settings. Of course, we recommend to go with
                                    the default options if you are not an experienced trader.
                                </p>`
                }
            },
            {
                '@type': 'Question',
                name: `10. Is BinaryDew available on mobile devices?`,
                acceptedAnswer: {
                    '@type': 'Answer',
                    text: `<p>
                                    Yes. Simply visit our website from your mobile device(s). Since all the tradings
                                    are performed on server-side, there have been no need to design a mobile app.
                                </p>
                                <p>
                                    However, we may design Android and iOS apps in the future if we find out
                                    that our customers are interested.
                                </p>`
                }
            },
        ]
    };
    const faqList1 = [];
    const faqList2 = [];
    const faqBtnClickHandler = (e) => {
        e.currentTarget.classList.toggle('collapsed');
        document.querySelector(e.currentTarget.dataset.target).classList.toggle('show');
    };
    
    faqStructuredData.mainEntity.forEach((item, idx) =>
    {
        const list = idx < (faqStructuredData.mainEntity.length / 2)
            ? faqList1
            : faqList2;
        
        list.push(
            <div className='accordion-item'>
                <h2 className='accordion-header' id={ `heading${idx + 1}` }>
                    <button
                        className='accordion-button collapsed'
                        type='button'
                        onClick={faqBtnClickHandler}
                        data-target={ `#collapse${idx + 1}` }
                    >
                        <span className='title'>{ item.name }</span>
                        <i className='fas fa-chevron-circle-down'/>
                    </button>
                </h2>
                <div id={ `collapse${idx + 1}` } className='accordion-collapse collapse'>
                    <div className='accordion-body' dangerouslySetInnerHTML={{ __html : item.acceptedAnswer.text } }>
                    </div>
                </div>
            </div>
        );
    });

    const getContract = () => {
        getTodayContractsStats()
            .then(
                (stats) => {
                    setTotals({
                        profit : stats.totals.profit,
                        loss   : stats.totals.loss,
                    });
                    // Not limiting # of contracts
                    // contracts = contracts.slice(-10);
                    const contracts = stats.contracts;
                    
                    if (contracts.length === 0 && tradings.length === 0) {
                        return false;
                    }
                    if ((contracts.length > 0 && tradings.length > 0) && (contracts[contracts.length - 1].date_start === tradings[0].date_start)) {
                        return false;
                    }
                    
                    let temp = [];
                    contracts.forEach(
                        (contract) => {
                            if(tradings.length > 0 && tradings[0].date_start >= contract.date_start) {
                                temp.push(
                                    {
                                        ...contract,
                                        is_new: false
                                    }
                                );
                            } else {
                                temp.push(
                                    {
                                        ...contract,
                                        is_new: true
                                    }
                                );
                            }
                        }
                    );
                    setTradings(temp);
                }
            )
            .catch(
                err => {
                    console.log(err.message);
                }
            );
    };

    useEffect(
        () => {
            AOS.init();
            
            //let timeoutHandle;
            
            getPrices()
                .then(
                    prices => {
                        //const mt = prices.filter(p => p.type === 'copier_mt');
                        const binary = prices.filter(p => p.type === 'copier_binary' && p.enabled);
                        setPricesList([
                            binary.find(p => p.color === 'bronze'),
                            binary.find(p => p.color === 'gold'),
                            binary.find(p => p.color === 'silver'),
                        ]);
                        /*intervalRef.current = setInterval(
                            () => {
                                if(!pauseRef.current) {
                                    if(priceSlide1Ref.current.classList.contains('hide-left')) {
                                        priceSlide1Ref.current.classList.remove('hide-left');
                                        priceSlide2Ref.current.classList.add('hide-right');
                                        timeoutHandle = setTimeout(
                                            () => {
                                                priceSlide2Ref.current.classList.remove('hide-right');
                                                priceSlide2Ref.current.classList.add('hide-left');
                                            },
                                            1000
                                        );
                                    } else {
                                        priceSlide2Ref.current.classList.remove('hide-left');
                                        priceSlide1Ref.current.classList.add('hide-right');
                                        timeoutHandle = setTimeout(
                                            () => {
                                                priceSlide1Ref.current.classList.remove('hide-right');
                                                priceSlide1Ref.current.classList.add('hide-left');
                                            },
                                            1500
                                        );
                                    }
                                }
                            },
                            5000
                        );*/
                    }
                )
                .catch(
                    err => {
                        console.log(err.message);
                    }
                );

            intervalRef.current = setInterval(getContract, 10000);
            getContract();

            /*return () => {
                if(intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                if (timeoutHandle)
                {
                    clearTimeout(timeoutHandle);
                }
            };*/
            return () => {
                if(intervalRef.current) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            }
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );
    
    const netProfit = totals.profit + totals.loss;

    return (
        <>
            <script type='application/ld+json'>
                {JSON.stringify(faqStructuredData)}
            </script>
            <section className='hero-area'>
                <HomeSvg className='hero-shape' />
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-12 col-12'>
                            <div className='hero-content'>
                                <h4 data-aos='fade-up' data-aos-delay='200' data-aos-once='true'>Start Investing & Earn Money</h4>
                                <h1 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>
                                    Say goodbye to
                                    <br/>
                                    losing&nbsp;
                                    <span>
                                        <img className='text-shape' src='/images/hero/text-shape.svg' alt='#'/>
                                        strategies.
                                    </span>
                                </h1>
                                <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>Invest your spare change in Binary Option and
                                    earn interest in <br/>
                                    real time with our magic strategies.
                                    <br/> Constant weekly profit is guaranteed. No losses per week.
                                </p>
                                <div className='button' data-aos='fade-up' data-aos-delay='800' data-aos-once='true'>
                                    <a href='/login' className='btn '>Get Started</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-12 mt-lg-0 mt-4'>
                            <div className='ratio ratio-16x9 w-100 iframe-container'>
                                <iframe
                                    className='w-100 h-100'
                                    src='https://www.youtube.com/embed/cGhQ0Res8fU'
                                    title='Binary Dew Live Trading Stream'
                                    frameBorder='0'
                                    allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='feature section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Why choose us</h3>
                                <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Our features</h2>
                                <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                    There are many reasons to invest in our system, but the major dialectics
                                    behind it is summarized below.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='200' data-aos-once='true'>
                            <div className='feature-box'>
                                <div className='tumb'>
                                    <img src='/images/features/feature-icon-1.png' alt=''/>
                                </div>
                                <h4 className='text-title'>Constant Interest</h4>
                                <p>
                                    Take profit with one of the highest interest rates on
                                    the market.
                                    <br/>By far!
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>
                            <div className='feature-box'>
                                <div className='tumb'>
                                    <img src='/images/features/feature-icon-2.png' alt=''/>
                                </div>
                                <h4 className='text-title'>Safe & Secure</h4>
                                <p>
                                    No need to deposit money into our system. Keep all of it in your broker's account.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                            <div className='feature-box'>
                                <div className='tumb'>
                                    <img src='/images/features/feature-icon-3.png' alt=''/>
                                </div>
                                <h4 className='text-title'>Instant Trading</h4>
                                <p>
                                    Our robots watch the market continuously and execute profitable trades instantly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='intro-video-area section tradings-section' ref={tradingsRef}>
                <div className='container h-100'>
                    <div className='row h-100'>
                        <div className='col-10 offset-1'>
                            <div className='inner-content-head h-100 justify-content-center'>
                                <div className='inner-content p-0 m-0 d-flex flex-column justify-content-center'>
                                    <div className='tradings-table d-flex flex-column w-100 h-100'>
                                        <div className='tradings-table-datetime flex-shrink-0 pt-2 pb-1 text-center' data-aos='fade-up' data-aos-delay='200' data-aos-once='true'>
                                            <div className='container'>
                                                <div className='row align-items-center h-100'>
                                                    <div className='col-12 col-md-4 pt-4 pb-4 pt-md-0 pb-md-0 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
                                                         data-aos-once='true'>
                                                        <div className='contracts-stats-box stats-box-success'>
                                                            <h4 className='text-title'>Net Profit</h4>
                                                            <h3><MDBBadge color={netProfit === 0 ? 'dark' : netProfit > 0 ? 'success' : 'danger'}>{ netProfit.toFixed(2) }$</MDBBadge></h3>
                                                            of today
                                                        </div>
                                                    </div>
                                                    {/*<div className='col-lg-4 col-md-4 col-6 d-none d-md-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"*/}
                                                    {/*     data-aos-once='true'>*/}
                                                    {/*    <div className='col-12'>*/}
                                                    {/*        <div><b>Live Trade Result (Last 200)</b></div>*/}
                                                    {/*        <Clock className='fw-bold clock' format={'HH:mm:ss'} ticking={true} timezone='UTC' />*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    <div className='col-12 col-md-4 offset-0 offset-md-3 pt-4 pb-4 pt-md-0 pb-md-0 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
                                                         data-aos-once='true'>
                                                        <div className='col-12'>
                                                            <div><b>Live Trade Result (Last 200)</b></div>
                                                            <Clock className='fw-bold clock' format={'HH:mm:ss'} ticking={true} timezone='UTC' />
                                                        </div>
                                                    </div>
                                                    {/*
                                                    <div className='col-lg-4 col-md-4 col-6 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
                                                         data-aos-once='true'>
                                                        <div className='contracts-stats-box stats-box-danger'>
                                                            <h4 className='text-title'>Total loss</h4>
                                                            <h3><MDBBadge color='danger'>{ totals.loss }$</MDBBadge></h3>
                                                            today
                                                        </div>
                                                    </div>
                                                    */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='tradings-table-header flex-shrink-0 d-flex' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>
                                            <div className='text-center fw-bold py-2'>#</div>
                                            <div className='text-center fw-bold py-2'>Contract type</div>
                                            <div className='text-center fw-bold py-2'>Duration</div>
                                            <div className='text-center fw-bold py-2'>Start time</div>
                                            <div className='text-center fw-bold py-2'>Result</div>
                                            <div className='text-center fw-bold py-2'>Symbol</div>
                                        </div>
                                        <div className='flex-grow-1 position-relative'>
                                            <div className='position-absolute w-100 h-100'>
                                                <Scrollbars>
                                                    <div className='tradings-table-body w-100' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                                        {
                                                            tradings.map(
                                                                (trading, index) =>
                                                                    <div className={'tradings-table-row d-flex' + (trading.is_new ? ' new-row' : '') + (!trading.is_sold ? ' not-sold' : '')} key={trading.contract_id}>
                                                                        <div className='text-center py-2'>{index + 1}</div>
                                                                        <div className='text-center py-2'>{trading.contract_type}</div>
                                                                        <div className='text-center py-2'>{trading.duration + trading.duration_unit}</div>
                                                                        <div className='text-center py-2'>{Intl.DateTimeFormat('en-US', { timeZone: 'UTC', hourCycle: 'h23', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(trading.date_start))}</div>
                                                                        <div className='text-center py-2'>{trading.is_sold ? trading.profit : 'Not Sold Yet'} <i className={!trading.is_sold ? '' : trading.profit > 0 ? 'text-success fas fa-sort-up' : trading.profit === 0 ? 'fa-solid fas fa-minus' : 'text-danger fas fa-sort-down'} /></div>
                                                                        <div className={'text-center py-2' + (!trading.is_sold ? ' text-decoration-line-through' : '')}>{trading.is_sold ? trading.symbol : 'Redacted'}</div>
                                                                    </div>
                                                            )
                                                        }
                                                    </div>
                                                </Scrollbars>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='start-process section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Ready to get started?</h3>
                                <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>3 Steps To Start</h2>
                                <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                    You can set up your account and start trading in less than 2 minutes.
                                    Just follow these 3 easy steps...
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='single-process'>
                                <span className='serial'>01</span>
                                <h3>Prepare Binary/Deriv API Token</h3>
                                <p>
                                    We support <a href='https://binary.com' target='_blank' rel='noopener noreferrer'>binary.com</a> and <a href='https://deriv.com' target='_blank' rel='noopener noreferrer'>deriv.com</a> users.
                                    Log-in or create your account on either platform and then create an API token to be used by our platform. <a href='/tutorials#binaryToken'>Learn more...</a>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='single-process'>
                                <span className='serial'>02</span>
                                <h3>Create Your Account</h3>
                                <p>
                                    <a href='/register'>Signup</a> on our platform with your email address. That's all we need to get your account ready.
                                    No fancy personal information needed!
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='single-process'>
                                <span className='serial'>03</span>
                                <h3>Make Payment</h3>
                                <p>Select a plan from our <a href='/pricing'>pricing page</a> and start earning! You will need
                                    the API token that you created at Step 1 during this step.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='home-pricing section'>
                <div className='container'>
                    <div className='home-pricing-slides w-100' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>
                        <div className='row g-0 w-100 home-pricing-slide justify-content-center'>
                            <div className='col-12 mb-4'>
                                <h2 className='text-center pb-4'>Pricing</h2>
                            </div>
                            {
                                pricesList.map(
                                    p => (
                                        <div className='col-12 col-sm-4 mt-4 mt-lg-0' key={p.id} style={{zIndex : p.color === 'gold' ? 1 : 0}}>
                                            <PricingItem price={p} popularRibbon={true} />
                                        </div>
                                    )
                                )
                            }
                        </div>
                        {/*<div className='row w-100 home-pricing-slide' ref={priceSlide1Ref}>
                            <div className='col-12'>
                                <h2 className='text-center pb-4'>MetaTrader Pricing</h2>
                            </div>
                            {
                                pricesList.mt.map(
                                    p => (
                                        <div className='col-6 col-sm-4 mt-4 mt-lg-0' key={p.id}>
                                            <PricingItem price={p} popularRibbon={true} onMouseEnterBtn={() => {pauseRef.current = true;}} onMouseLeaveBtn={() => {pauseRef.current = false;}}  />
                                        </div>
                                    )
                                )
                            }
                        </div>
                        <div className='row w-100 home-pricing-slide hide-left'  ref={priceSlide2Ref}>
                            <div className='col-12'>
                                <h2 className='text-center pb-4'>Binary.com Pricing</h2>
                            </div>
                            {
                                pricesList.binary.map(
                                    p => (
                                        <div className='col-6 col-sm-4 mt-4 mt-lg-0' key={p.id}>
                                            <PricingItem price={p} popularRibbon={true} onMouseEnterBtn={() => {pauseRef.current = true;}} onMouseLeaveBtn={() => {pauseRef.current = false;}}  />
                                        </div>
                                    )
                                )
                            }
                        </div>*/}
                    </div>
                </div>
            </section>
            {/*
                <section className='team section'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='section-title'>
                                    <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Expert Team</h3>
                                    <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>24/7 Support</h2>
                                    <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                        In case you needed assistance with anything on our platform, drop us a message
                                        and we will be glad to help
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-3 col-md-6 col-12' data-aos='fade-up' data-aos-delay='300'
                                 data-aos-once='true'>
                                <div className='single-team'>
                                    <div className='team-image'>
                                        <img src='/images/team/team4.jpg' alt='#'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Elijah
                                            <span>Support Operator #1</span>
                                        </h4>
                                        <ul className='social'>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-facebook'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-instagram'/>
                                                </a></li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-twitter'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-linkedin'/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12' data-aos='fade-up' data-aos-delay='500'
                                 data-aos-once='true'>
                                <div className='single-team'>
                                    <div className='team-image'>
                                        <img src='/images/team/team1.jpg' alt='#'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Patricia
                                            <span>Support Operator #2</span>
                                        </h4>
                                        <ul className='social'>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-facebook'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-instagram'/>
                                                </a></li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-twitter'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-linkedin'/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12' data-aos='fade-up' data-aos-delay='700'
                                 data-aos-once='true'>
                                <div className='single-team'>
                                    <div className='team-image'>
                                        <img src='/images/team/team2.jpg' alt='#'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Noah
                                            <span>Support Operator #3</span>
                                        </h4>
                                        <ul className='social'>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-facebook'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-instagram'/>
                                                </a></li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-twitter'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-linkedin'/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12' data-aos='fade-up' data-aos-delay='900'
                                 data-aos-once='true'>
                                <div className='single-team'>
                                    <div className='team-image'>
                                        <img src='/images/team/team3.jpg' alt='#'/>
                                    </div>
                                    <div className='content'>
                                        <h4>Robert
                                            <span>Support Operator #4</span>
                                        </h4>
                                        <ul className='social'>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-facebook'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-instagram'/>
                                                </a></li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-twitter'/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href='/'>
                                                    <i className='fab fa-linkedin'/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <section className='intro-video-area section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='inner-content-head'>
                                <div className='inner-content'>
                                    <img className='shape1' src='/images/video/shape1.svg' alt='#'/>
                                    <img className='shape2' src='/images/video/shape2.svg' alt='#'/>
                                    <div className='section-title'>
                                        <span data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Create your own experience</span>
                                        <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Watch Our intro video</h2>
                                        <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                            If you still have any doubts, please take your time and watch our introduction video.
                                            It's just one minute of your life but can change rest of your life for good!
                                        </p>
                                    </div>
                                    <div className='intro-video-play'>
                                        <div className='play-thumb' data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>
                                            <a href='/' className='glightbox video'>
                                                <i className='fas fa-play'/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}
            {
                // <section className='road-map section'>
                //     <div className='container'>
                //         <div className='row'>
                //             <div className='col-12'>
                //                 <div className='section-title'>
                //                     <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Road Map</h2>
                //                     <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>There are many
                //                         variations of passages
                //                         of Lorem
                //                         Ipsum available, but the majority have suffered alteration in some form.</p>
                //                 </div>
                //             </div>
                //         </div>
                //         <div className='top-row'>
                //             <div className=''>
                //                 <div className='row road-map-slider'>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map down'>
                //                             <span className='arrow'></span>
                //                             <h3>Apr 10,2018
                //                                 <span>Development Started</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map'>
                //                             <span className='arrow'></span>
                //                             <h3>May 15,2018
                //                                 <span>Exchange Bitcontent to Bitcoin</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map down'>
                //                             <span className='arrow'></span>
                //                             <h3>Jan 05,2018
                //                                 <span>BTCC mode of payment</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map'>
                //                             <span className='arrow'></span>
                //                             <h3>Mar 210,2019
                //                                 <span>Send-Receive coin Bitconcent & mobile</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map down'>
                //                             <span className='arrow'></span>
                //                             <h3>Feb 25,2020
                //                                 <span>Coin Marketcap, World Coin Index</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map'>
                //                             <span className='arrow'></span>
                //                             <h3>Dec 22,2020
                //                                 <span>BTCC mode of payment in Bitcoin</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map down'>
                //                             <span className='arrow'></span>
                //                             <h3>Feb 25,2020
                //                                 <span>Coin Marketcap, World Coin Index</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map'>
                //                             <span className='arrow'></span>
                //                             <h3>Jan 05,2018
                //                                 <span>BTCC mode of payment</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //
                //                         <div className='single-map down'>
                //                             <span className='arrow'></span>
                //                             <h3>May 15,2018
                //                                 <span>Exchange Bitcontent to Bitcoin</span>
                //                             </h3>
                //                         </div>
                //                     </div>
                //                     <div className='col-lg-2 col-md-3 col-12 p-0'>
                //                         <div className='single-map'>
                //                             <span className='arrow'></span>
                //                             <h3>Dec 22,2020
                //                                 <span>BTCC mode of payment in Bitcoin</span>
                //                             </h3>
                //                         </div>
                //
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </section>
            }

            {/*<section className='testimonials style2 section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Customer Reviews</h3>
                                <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Our Testimonials</h2>
                                <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                    Please send your story to us if you are one of our many customers whose life
                                    has changed ever since using our platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='overflow-hidden testimonial-slider testimonial-slider-1 mb-4'>
                        <div className='testimonial-slides testimonial-slides-1 row flex-nowrap align-items-center'>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was too bored trying variety of strategies with no success. I must
                                                admit that I wasted my time during that time. Now, I am very happy with
                                                my income through your platform.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-1.png' alt='#'/>
                                            <h4 className='name'>Somalia
                                                <span className='deg'>Business Manager</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was very disappointed of my Binary Option trading before, then it is you, and
                                                nothing else matters now! You are the best. Love you guys.
                                                Thanks for every thing”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-2.png' alt='#'/>
                                            <h4 className='name'>Dexter
                                                <span className='deg'>Blockchain Developer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Honestly, when I first saw your website, I thought it might be another scam.
                                                But I am the most thankful to myself for just giving your platform a shot!
                                                That totally worth it. Wish I got to know you sooner. Best of luck.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-3.png' alt='#'/>
                                            <h4 className='name'>David
                                                <span className='deg'>Investor</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Binary Option trading has never been this much easier for me. LOL. That's because
                                                you are doing all the hard work. Your company's motto is really befitting.
                                                The future of your trading. That really made the future of my trading.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-4.png' alt='#'/>
                                            <h4 className='name'>Theo
                                                <span className='deg'>Binary Trader</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “BinaryDew has changed my life! I have a more happier life now than when
                                                I was not using your platform. Good job! Please keep it going as my income
                                                mainly depends on your platform!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-5.png' alt='#'/>
                                            <h4 className='name'>Adeline
                                                <span className='deg'>Graphics Designer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “The day I bought your subscription, I was ashamed of the balance in my
                                                bank account. Look at me now, living a life that I have always dreamed of.
                                                That's just because of the BinaryDew.com. Thanks a million!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-6.png' alt='#'/>
                                            <h4 className='name'>Mika
                                                <span className='deg'>Housewife</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was too bored trying variety of strategies with no success. I must
                                                admit that I wasted my time during that time. Now, I am very happy with
                                                my income through your platform.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-1.png' alt='#'/>
                                            <h4 className='name'>Somalia
                                                <span className='deg'>Business Manager</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was very disappointed of my Binary Option trading before, then it is you, and
                                                nothing else matters now! You are the best. Love you guys.
                                                Thanks for every thing”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-2.png' alt='#'/>
                                            <h4 className='name'>Dexter
                                                <span className='deg'>Blockchain Developer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Honestly, when I first saw your website, I thought it might be another scam.
                                                But I am the most thankful to myself for just giving your platform a shot!
                                                That totally worth it. Wish I got to know you sooner. Best of luck.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-3.png' alt='#'/>
                                            <h4 className='name'>David
                                                <span className='deg'>Investor</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Binary Option trading has never been this much easier for me. LOL. That's because
                                                you are doing all the hard work. Your company's motto is really befitting.
                                                The future of your trading. That really made the future of my trading.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-4.png' alt='#'/>
                                            <h4 className='name'>Theo
                                                <span className='deg'>Binary Trader</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “BinaryDew has changed my life! I have a more happier life now than when
                                                I was not using your platform. Good job! Please keep it going as my income
                                                mainly depends on your platform!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-5.png' alt='#'/>
                                            <h4 className='name'>Adeline
                                                <span className='deg'>Graphics Designer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “The day I bought your subscription, I was ashamed of the balance in my
                                                bank account. Look at me now, living a life that I have always dreamed of.
                                                That's just because of the BinaryDew.com. Thanks a million!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-6.png' alt='#'/>
                                            <h4 className='name'>Mika
                                                <span className='deg'>Housewife</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='overflow-hidden testimonial-slider testimonial-slider-2'>
                        <div className='testimonial-slides testimonial-slides-2 row flex-nowrap align-items-center'>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was too bored trying variety of strategies with no success. I must
                                                admit that I wasted my time during that time. Now, I am very happy with
                                                my income through your platform.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-1.png' alt='#'/>
                                            <h4 className='name'>Somalia
                                                <span className='deg'>Business Manager</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was very disappointed of my Binary Option trading before, then it is you, and
                                                nothing else matters now! You are the best. Love you guys.
                                                Thanks for every thing”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-2.png' alt='#'/>
                                            <h4 className='name'>Dexter
                                                <span className='deg'>Blockchain Developer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Honestly, when I first saw your website, I thought it might be another scam.
                                                But I am the most thankful to myself for just giving your platform a shot!
                                                That totally worth it. Wish I got to know you sooner. Best of luck.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-3.png' alt='#'/>
                                            <h4 className='name'>David
                                                <span className='deg'>Investor</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Binary Option trading has never been this much easier for me. LOL. That's because
                                                you are doing all the hard work. Your company's motto is really befitting.
                                                The future of your trading. That really made the future of my trading.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-4.png' alt='#'/>
                                            <h4 className='name'>Theo
                                                <span className='deg'>Binary Trader</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “BinaryDew has changed my life! I have a more happier life now than when
                                                I was not using your platform. Good job! Please keep it going as my income
                                                mainly depends on your platform!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-5.png' alt='#'/>
                                            <h4 className='name'>Adeline
                                                <span className='deg'>Graphics Designer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “The day I bought your subscription, I was ashamed of the balance in my
                                                bank account. Look at me now, living a life that I have always dreamed of.
                                                That's just because of the BinaryDew.com. Thanks a million!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-6.png' alt='#'/>
                                            <h4 className='name'>Mika
                                                <span className='deg'>Housewife</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was too bored trying variety of strategies with no success. I must
                                                admit that I wasted my time during that time. Now, I am very happy with
                                                my income through your platform.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-1.png' alt='#'/>
                                            <h4 className='name'>Somalia
                                                <span className='deg'>Business Manager</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “I was very disappointed of my Binary Option trading before, then it is you, and
                                                nothing else matters now! You are the best. Love you guys.
                                                Thanks for every thing”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-2.png' alt='#'/>
                                            <h4 className='name'>Dexter
                                                <span className='deg'>Blockchain Developer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Honestly, when I first saw your website, I thought it might be another scam.
                                                But I am the most thankful to myself for just giving your platform a shot!
                                                That totally worth it. Wish I got to know you sooner. Best of luck.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-3.png' alt='#'/>
                                            <h4 className='name'>David
                                                <span className='deg'>Investor</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “Binary Option trading has never been this much easier for me. LOL. That's because
                                                you are doing all the hard work. Your company's motto is really befitting.
                                                The future of your trading. That really made the future of my trading.”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-4.png' alt='#'/>
                                            <h4 className='name'>Theo
                                                <span className='deg'>Binary Trader</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “BinaryDew has changed my life! I have a more happier life now than when
                                                I was not using your platform. Good job! Please keep it going as my income
                                                mainly depends on your platform!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-5.png' alt='#'/>
                                            <h4 className='name'>Adeline
                                                <span className='deg'>Graphics Designer</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='testimonial-slide col-12 flex-shrink-0'>
                                <div className='single-testimonial'>
                                    <div className='inner-content'>
                                        <div className='quote-icon'>
                                            <i className='fas fa-quote-right'/>
                                        </div>
                                        <div className='text'>
                                            <p>
                                                “The day I bought your subscription, I was ashamed of the balance in my
                                                bank account. Look at me now, living a life that I have always dreamed of.
                                                That's just because of the BinaryDew.com. Thanks a million!”
                                            </p>
                                        </div>
                                        <div className='author'>
                                            <img src='/images/testimonial/testi-6.png' alt='#'/>
                                            <h4 className='name'>Mika
                                                <span className='deg'>Housewife</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>*/}

            <section className='testimonials style2 section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Customer Reviews</h3>
                                <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Our Testimonials</h2>
                                <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                    Please send your story to us if you are one of our many customers whose life
                                    has changed ever since using our platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row testimonial-slider'>
                        <div className='col-lg-6 col-12 '>
                            <div className='single-testimonial'>
                                <div className='inner-content'>
                                    <div className='quote-icon'>
                                        <i className='fas fa-quote-right'/>
                                    </div>
                                    <div className='text'>
                                        <p>
                                            “I was too bored trying variety of strategies with no success. I must
                                            admit that I wasted my time during that time. Now, I am very happy with
                                            my income through your platform.”
                                        </p>
                                    </div>
                                    <div className='author'>
                                        <img src='/images/testimonial/testi-1.png' alt='#'/>
                                        <h4 className='name'>Somalia
                                            <span className='deg'>Business Manager</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-6 col-12 '>
                            <div className='single-testimonial'>
                                <div className='inner-content'>
                                    <div className='quote-icon'>
                                        <i className='fas fa-quote-right'/>
                                    </div>
                                    <div className='text'>
                                        <p>
                                            “I was very disappointed of my Binary Option trading before, then it is you, and
                                            nothing else matters now! You are the best. Love you guys.
                                            Thanks for every thing”
                                        </p>
                                    </div>
                                    <div className='author'>
                                        <img src='/images/testimonial/testi-2.png' alt='#'/>
                                        <h4 className='name'>Dexter
                                            <span className='deg'>Blockchain Developer</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-6 col-12 '>
                            <div className='single-testimonial'>
                                <div className='inner-content'>
                                    <div className='quote-icon'>
                                        <i className='fas fa-quote-right'/>
                                    </div>
                                    <div className='text'>
                                        <p>
                                            “Honestly, when I first saw your website, I thought it might be another scam.
                                            But I am the most thankful to myself for just giving your platform a shot!
                                            That totally worth it. Wish I got to know you sooner. Best of luck.”
                                        </p>
                                    </div>
                                    <div className='author'>
                                        <img src='/images/testimonial/testi-3.png' alt='#'/>
                                        <h4 className='name'>David
                                            <span className='deg'>Investor</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 '>
                            <div className='single-testimonial'>
                                <div className='inner-content'>
                                    <div className='quote-icon'>
                                        <i className='fas fa-quote-right'/>
                                    </div>
                                    <div className='text'>
                                        <p>
                                            “Binary Option trading has never been this much easier for me. LOL. That's because
                                            you are doing all the hard work. Your company's motto is really befitting.
                                            The future of your trading. That really made the future of my trading.”
                                        </p>
                                    </div>
                                    <div className='author'>
                                        <img src='/images/testimonial/testi-4.png' alt='#'/>
                                        <h4 className='name'>Theo
                                            <span className='deg'>Binary Trader</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 '>
                            <div className='single-testimonial'>
                                <div className='inner-content'>
                                    <div className='quote-icon'>
                                        <i className='fas fa-quote-right'/>
                                    </div>
                                    <div className='text'>
                                        <p>
                                            “BinaryDew has changed my life! I have a more happier life now than when
                                            I was not using your platform. Good job! Please keep it going as my income
                                            mainly depends on your platform!”
                                        </p>
                                    </div>
                                    <div className='author'>
                                        <img src='/images/testimonial/testi-5.png' alt='#'/>
                                        <h4 className='name'>Adeline
                                            <span className='deg'>Graphics Designer</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 '>
                            <div className='single-testimonial'>
                                <div className='inner-content'>
                                    <div className='quote-icon'>
                                        <i className='fas fa-quote-right'/>
                                    </div>
                                    <div className='text'>
                                        <p>
                                            “The day I bought your subscription, I was ashamed of the balance in my
                                            bank account. Look at me now, living a life that I have always dreamed of.
                                            That's just because of the BinaryDew.com. Thanks a million!”
                                        </p>
                                    </div>
                                    <div className='author'>
                                        <img src='/images/testimonial/testi-6.png' alt='#'/>
                                        <h4 className='name'>Mika
                                            <span className='deg'>Housewife</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {
                // <section className='blog-section section'>
                //     <div className='container'>
                //         <div className='row'>
                //             <div className='col-12'>
                //                 <div className='section-title'>
                //                     <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Blogs</h3>
                //                     <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Our Latest
                //                         News</h2>
                //                     <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>There are many
                //                         variations of passages
                //                         of Lorem
                //                         Ipsum available, but the majority have suffered alteration in some form.</p>
                //                 </div>
                //             </div>
                //         </div>
                //         <div className='row'>
                //             <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='400'
                //                  data-aos-once='true'>
                //                 <div className='single-blog-grid'>
                //                     <div className='blog-img'>
                //                         <a href='/'>
                //                             <img src='/images/blog/blog-1.png' alt='#'/>
                //                         </a>
                //                     </div>
                //                     <div className='blog-content'>
                //                         <div className='meta-info'>
                //                             <a className='date' href='/'><i className='far fa-clock'/> 10
                //                                 June
                //                                 2023
                //                             </a>
                //                             <a className='author' href='/'><i
                //                                 className='far fa-user'/> Anjelio
                //                                 Joly
                //                             </a>
                //                         </div>
                //                         <h4>
                //                             <a href='/'>The Intersection Where Blockchain Meets Energy.</a>
                //                         </h4>
                //                         <p>Lorem ipsum dolor sit amet, adipscing elitr, sit gifted sed diam nonumy
                //                             eirmod
                //                             tempor
                //                             ividunt dolore.</p>
                //                         <div className='button'>
                //                             <a href='/' className='btn btn-outline-white'>Read More</a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //             <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='600'
                //                  data-aos-once='true'>
                //                 <div className='single-blog-grid'>
                //                     <div className='blog-img'>
                //                         <a href='/'>
                //                             <img src='/images/blog/blog-2.png' alt='#'/>
                //                         </a>
                //                     </div>
                //                     <div className='blog-content'>
                //                         <div className='meta-info'>
                //                             <a className='date' href='/'>
                //                                 <i className='far fa-clock'/> 5
                //                                 Aug
                //                                 2023
                //                             </a>
                //                             <a className='author' href='/'><i
                //                                 className='far fa-user'/> Kumila
                //                                 ksusi
                //                             </a>
                //                         </div>
                //                         <h4>
                //                             <a href='/'>Pros & Cons of Premined Cryptocurrencies.</a>
                //                         </h4>
                //                         <p>Lorem ipsum dolor sit amet, adipscing elitr, sit gifted sed diam nonumy
                //                             eirmod
                //                             tempor
                //                             ividunt dolore.</p>
                //                         <div className='button'>
                //                             <a href='/' className='btn btn-outline-white'>Read Blog</a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //             <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='800'
                //                  data-aos-once='true'>
                //                 <div className='single-blog-grid'>
                //                     <div className='blog-img'>
                //                         <a href='/'>
                //                             <img src='/images/blog/blog-3.png' alt='#'/>
                //                         </a>
                //                     </div>
                //                     <div className='blog-content'>
                //                         <div className='meta-info'>
                //                             <a className='date' href='/'>
                //                                 <i className='far fa-clock'/>
                //                                 25
                //                                 Dec
                //                                 2023
                //                             </a>
                //                             <a className='author' href='/'>
                //                                 <i className='far fa-user'/>
                //                                 Alex
                //                                 Jendro
                //                             </a>
                //                         </div>
                //                         <h4>
                //                             <a href='blog-single.html'>How & Where To Market Your ICO Startup.</a>
                //                         </h4>
                //                         <p>Lorem ipsum dolor sit amet, adipscing elitr, sit gifted sed diam nonumy
                //                             eirmod
                //                             tempor
                //                             ividunt dolore.</p>
                //                         <div className='button'>
                //                             <a href='blog-single.html' className='btn btn-outline-white'>Read Blog</a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </section>
            }
            <section className='faq section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='section-title'>
                                <h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>FAQ</h3>
                                <h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Frequently Asked Questions</h2>
                                <p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                                    Below you can find a few questions that are frequently asked by our customers.
                                    Feel free to <a href='/contact'> contact us</a> if you need further help.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='accordion' id='accordionExample'>
                                { faqList1 }
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12 xs-margin'>
                            <div className='accordion' id='accordionExample2'>
                                { faqList2 }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
