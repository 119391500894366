import './contact-us.styles.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AOS from 'aos';

import onlineSupportImg from './assets/support-online.png'
import tutorialsImg from './assets/tutorials.png'
import emailImg from './assets/email.png'
import { getCaptchaLink } from '../../libs/urlFactory';
import { toEnDigits } from '../../libs/utils';
import { selectUserAccount } from '../../redux/core/user/user.selectors';

const ContactUs = () =>
{
	const user = useSelector(selectUserAccount);
	const [captchaTimestampState, setCaptchaTimestampState] = useState(new Date().getTime());
	const [customerName, setCustomerName] = useState(user?.name || '');
	const [customerEmail, setCustomerEmail] = useState(user?.email || '');
	const [captchaValue, setCaptchaValue] = useState('');
	
	useEffect(
		() =>
		{
			AOS.init();
		}
		, []
	);
	
	return (
		<div className='contact-us'>
			<div className='breadcrumbs'>
				<div className='container'>
					<div className='row align-items-center align-content-center align-center'>
						<div className=''>
							<div className='breadcrumbs-content'>
								<h1 className='page-title'>Contact Us</h1>
								<div className='text-success-2'>Do not hesitate to contact us in case you needed assistance with anything on our platform.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='contact-us-content'>
				<div className='contact-us-box-container'>
					<div className='container'>
						<div className='row'>
							<div className='col-md-4 col-sm-6 col-12 justify-content-center' data-aos='fade-in' data-aos-delay='200' data-aos-duration='1000' data-aos-easing='ease-in-cubic'
								 data-aos-once='true'>
								<div className='contact-us-box link-box'>
									<a href='/tutorials'>Tutorials</a>
									<div className='tumb'>
										<img src={tutorialsImg} alt='' />
									</div>
									<h4 className='text-title'>Tutorials</h4>
									<p>
										Get help by reading and searching through our knowledge base.
									</p>
								</div>
							</div>
							<div className='col-lg-4 col-md-4 col-6 d-none d-md-block justify-content-center' data-aos='fade-in' data-aos-delay='200' data-aos-duration='1000' data-aos-easing='ease-in-cubic'
								 data-aos-once='true'>
								<div className='contact-us-box disabled'>
									<div className='tumb'>
										<img src={emailImg} alt='' />
									</div>
									<h4 className='text-title'>Email</h4>
									<p>
										Send us an offline message. We will respond within maximum of 3 hours.
									</p>
								</div>
							</div>
							<div className='col-md-4 col-sm-6 col-12 mt-sm-0 mt-2 justify-content-center' data-aos='fade-in' data-aos-delay='200' data-aos-duration='1000' data-aos-easing='ease-in-cubic'
								 data-aos-once='true'>
								<div className='contact-us-box'>
									<div className='tumb'>
										<img src={onlineSupportImg} alt='' />
									</div>
									<h4 className='text-title'>Live Chat</h4>
									<p>
										Contact with our online support team and receive instant response.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='container account-login'>
					<div className='row justify-content-center'>
						<div className='email-box col-lg-6 col-md-6 col-12' data-aos='fade-up' data-aos-delay='500' data-aos-once='true'>
							<form className='card login-form inner-content'>
								<div className='card-body'>
									<div className='title'>
										<h3>Contact Us</h3>
									</div>
									<div className='input-head'>
										<div className='form-group input-group'>
											<label>
												<i className='fas fa-user' />
											</label>
											<input className='form-control' type='text' name='name' minLength='3'
												   maxLength='30' placeholder='Name' required
												   disabled={!!user} value={customerName}
												   onChange={
													   (e) => {
														   setCustomerName(e.currentTarget.value);
													   }
												   } />
										</div>
										<div className='form-group input-group'>
											<label>
												<i className='fas fa-envelope' />
											</label>
											<input className='form-control' type='email' name='email' placeholder='Email' required
												   disabled={!!user} value={customerEmail}
												   onChange={
													   (e) => {
														   setCustomerEmail(e.currentTarget.value);
													   }
												   } />
										</div>
										<div className='form-group input-group'>
											<label>
												<i className='fas fa-hire-a-helper' />
											</label>
											<select className='form-control' name='department' defaultValue='' required>
												<option value=''>Please select related department</option>
												<option value='finance'>Finance</option>
												<option value='subscriptions'>Subscriptions</option>
												<option value='trading'>Trading</option>
												<option value='website'>IT (Website issues)</option>
												<option value='other'>Other</option>
											</select>
										</div>
										<textarea name='message' className='form-control message-input mt-4 mb-4'
												  placeholder='Write about your issue here. Please be specific.'
												  rows='3' />
										<div className='captcha'>
											<div className='d-flex justify-content-between mb-3'>
												<img className='captcha-img' src={getCaptchaLink(captchaTimestampState)} alt='Captcha' />
												<button
													type='submit'
													className='btn btn-outline-success btn-rounded'
													onClick={
														() => {
															setCaptchaTimestampState(new Date().getTime());
														}
													}
												>
													<i className='fas fa-2x fa-refresh' />
												</button>
											</div>
											<div className='form-group input-group'>
												<label>
													<i className='fas fa-shield' />
												</label>
												<input
													className='form-control'
													type='text'
													name='captcha'
													placeholder='Captcha'
													minLength='5'
													maxLength='5'
													value={captchaValue}
													onChange={
														(e) => {
															if (/^\d+$/.test(e.currentTarget.value) || !e.currentTarget.value)
															{
																setCaptchaValue(toEnDigits(e.currentTarget.value));
															}
														}
													}
													required
												/>
											</div>
										</div>
									</div>
									<div className='button'>
										<button className='btn' type='submit'>Send</button>
									</div>
									<h4 className='create-account'>You can also directly call us at: <a href='tel:+233-595-855100' className='text-success-2'>+233-595-855100</a>
									</h4>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(ContactUs);
