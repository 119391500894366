import SubscriptionPageTypes from './subscription-page.types';

/**
 * Set subscription ID
 * @param {number} subscriptionId
 * @returns {{payload: number, type: string}}
 */
export const setSubscriptionId = (subscriptionId) => {
    return {
        type   : SubscriptionPageTypes.SET_SUBSCRIPTION_ID,
        payload: subscriptionId
    };
};

/**
 * Unset subscription ID
 * @returns {{type: string}}
 */
export const unsetSubscriptionId = () => {
    return {
        type   : SubscriptionPageTypes.UNSET_SUBSCRIPTION_ID
    };
};

/**
 * Set subscription ID
 * @param {number} subscriptionId
 * @returns {{payload: number, type: string}}
 */
export const setSubscriptionSettingsId = (subscriptionId) => {
    return {
        type   : SubscriptionPageTypes.SET_SUBSCRIPTION_SETTINGS_ID,
        payload: subscriptionId
    };
};

/**
 * Unset subscription ID
 * @returns {{type: string}}
 */
export const unsetSubscriptionSettingsId = () => {
    return {
        type   : SubscriptionPageTypes.UNSET_SUBSCRIPTION_SETTINGS_ID
    };
};
