import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import UserTypes from './core/user/user.types';
import UserReducer from './core/user/user.reducer';
import NotificationsReducer from './core/notifications/notifications.reducer';
import CartReducer from './core/cart/cart.reducer';
import TraderAccountsPageReducer from './trader-accounts-page/trader-accounts-page.reducer';
import SubscriptionPageReducer from './subscription-page/subscription-page.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: []
};

const appReducer = combineReducers({
    // Core
    user: UserReducer,
    notifications: NotificationsReducer,
    cart: CartReducer,
    // Components
    traderAccountPage: TraderAccountsPageReducer,
    subscriptionPage: SubscriptionPageReducer,
});

/**
 * This reducer is used to reset full state of the store
 * when a logout occurs.
 * @param state
 * @param action
 * @returns {*}
 */
const rootReducer = (state, action) => {
    if (action.type === UserTypes.LOGOUT_USER) {
        
        // For all keys defined in your persistConfig(s)...
        storage.removeItem('persist:root')
        
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
