import axios from 'axios';
import {Exception} from './Exception';

export const ERROR_CODES_ENUM = {
    SUCCESS: 0,

    /* Communication Errors */
    GENERAL: 2000,
    MISSING_ARGUMENT: 2001,
    INVALID_ARGUMENT: 2002,

    /* User specific errors */
    USER_INCORRECT_CREDENTIALS: 3000,
    USER_ACCESS_DENIED: 3001,
    USER_NOT_LOGGED_IN: 3002,
    USER_UNAUTHORIZED: 3003,
    USER_CAPTCHA_REQUIRED: 3004,
    USER_WRONG_CAPTCHA: 3005,
    USER_WRONG_VERIFICATION_CODE: 3006,
    USER_VERIFICATION_CODE_EXPIRED: 3007,
    USER_NOT_FOUND: 3008,

    /* SYSTEM ERRORS */
    SYSTEM_UNKNOWN_ERROR: 10000,
    SYSTEM_INVALID_STATE: 10001,
};

export function apiErrorRethrow(err)
{
    if (axios.isAxiosError(err))
    {
        if (err.response !== undefined && err.response.data !== undefined)
        {
            throw new Exception(err.response.data.code, err.response.data.error);
        }
    }
    throw new Exception(ERROR_CODES_ENUM.GENERAL, 'Unexpected error occurred');
}
