import axios from 'axios';
import { getAPIUrl } from '../urlFactory';
import { apiErrorRethrow } from '../errors/ErrorHandlers';

/**
 * Gets list of prices
 * @returns {Promise<*[]>}
 */
export async function getPrices() {
    const listURL = new URL('subscriptions/pricing', getAPIUrl()).href;
    try {
        const response = await axios.get(
            listURL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        return response.data.prices;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Get list of subscriptions for currently logged-i user
 * @returns {Promise<*>}
 */
export async function getSubscriptions() {
    const buyURL = new URL('subscriptions', getAPIUrl()).href;
    try {
        const response = await axios.get(
            buyURL,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        return response.data.subscriptions;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Get details of a subscription
 * @param id {number}
 * @returns {Promise<*>}
 */
export async function getSubscription(id) {
    const buyURL = new URL(`subscriptions/${id}`, getAPIUrl()).href;
    try {
        const response = await axios.get(
            buyURL,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        return response.data.subscription;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Response returned from Flutterwave payment processor
 * @typedef FlutterWaveResponse {object}
 */
/**
 * Subscription parameters for buying
 * @typedef {{mt_account_number: number}|{binary_token: string}} SubscriptionBuyParams
 */
/**
 * Buy new subscription
 * @param typeId {number}
 * @param buyParams {SubscriptionBuyParams}
 * @param payment {FlutterWaveResponse} - Response returned from payment processor. (e.g. Flutterwave)
 * @returns {Promise<*>}
 */
export async function buySubscription(typeId, buyParams, payment) {
    const buyURL = new URL('subscriptions', getAPIUrl()).href;
    try {
        const response = await axios.post(
            buyURL,
            {
                subscription_type: typeId,
                ...buyParams,
                payment
            }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        return response.data.subscription;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Get compilation status of the EAs for provided subscription ID
 * @param subscriptionId
 * @return {Promise<{ mt4: 'ready'|'not-ready'|'failed', mt5: 'ready'|'not-ready'|'failed'}>}
 */
export async function getCopierEAStatus(subscriptionId) {
    const buyURL = new URL(`subscriptions/${subscriptionId}/expert_advisor`, getAPIUrl()).href;
    try {
        const response = await axios.get(
            buyURL,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                params : {
                    status: true
                }
            });
        return response.data.status;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Trading parameters of a subscription
 * @typedef {{volume: number}|{multiplier: number}} SubscriptionSettings
 */
/**
 * Update name, MT account number or binary token, or settings of a subscription
 * @param subscriptionId {number}
 * @param name {string}
 * @param account_number {number|undefined}
 * @param binary_token {string|undefined}
 * @param settings {SubscriptionSettings|undefined}
 * @return {Promise<*>}
 */
export async function updateSubscription(subscriptionId, name, account_number, binary_token,settings) {
    const updateURL = new URL(`subscriptions/${subscriptionId}`, getAPIUrl()).href;
    try {
        const response = await axios.patch(
            updateURL,
            {
                name,
                account_number,
                binary_token,
                settings,
            },
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        
        return response.data.subscription;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Get compilation status of the EAs for provided trader ID
 * @param traderId
 * @return {Promise<{ mt4: 'ready'|'not-ready'|'failed', mt5: 'ready'|'not-ready'|'failed'}>}
 */
export async function getTraderEAStatus(traderId) {
    const buyURL = new URL(`profile/traders/${traderId}/expert_advisor`, getAPIUrl()).href;
    try {
        const response = await axios.get(
            buyURL,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                params : {
                    status: true
                }
            });
        return response.data.status;
    } catch (err) {
        apiErrorRethrow(err);
    }
}
