import { createSelector } from 'reselect';

export const selectSubscriptionPage = state => state.subscriptionPage;

export const selectSubscriptionId = createSelector(
    [selectSubscriptionPage],
    subscriptionPage => subscriptionPage.subscriptionId
);

export const selectSubscriptionSettingsId = createSelector(
    [selectSubscriptionPage],
    subscriptionPage => subscriptionPage.subscriptionSettingsId
);

