export default class NotificationData {
    /**
     * Provides an abstract for notifications
     * @param {'info'|'success'|'error'|'warning'} type
     * @param {string} text
     * @param {number} duration - Duration in milliseconds
     */
    constructor(type, text, duration = 5000) {
        /**
         * ID
         * @type {number}
         */
        this.id = -1;
        this.type = type.charAt(0) + type.substring(1);
        this.text = text;
        this.duration = duration;
    }
}
