import CartTypes from './cart.types';

/**
 * Add a item to cart
 * @param {object} item
 * @returns {{payload, type: string}}
 */
export const addItem = (item) => {
    return {
        type   : CartTypes.ADD_ITEM,
        payload: item
    };
};

/**
 * Remove a item from cart
 * @param {number} itemId
 * @returns {{payload, type: string}}
 */
export const removeItem = (itemId) => {
    return {
        type   : CartTypes.REMOVE_ITEM,
        payload: itemId
    };
};

/**
 * Reset Cart
 * @returns {{type: string}}
 */
export const resetCart = () => {
    return {
        type   : CartTypes.RESET_CART
    };
};