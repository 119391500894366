import './trader-accounts-item.styles.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { MDBBadge, MDBBtn } from 'mdb-react-ui-kit';

import { setTraderAccount } from '../../redux/trader-accounts-page/trader-accounts-page.actions';
import PropTypes from 'prop-types';
import TraderAccount from '../../libs/contracts/TraderAccount';

const TraderAccountsItem = ({ traderAccount }) => {
    const dispatch = useDispatch();
    const setTraderAccountDispatch = (accountId) => dispatch(setTraderAccount(accountId));
    return (
        <div className="box">
            <MDBBadge className='trader-account-type ms-2' color='dark'>{ traderAccount.type }</MDBBadge>
            <h3>Account Number:</h3>
            <h5>{ traderAccount.account_number } </h5>
            <MDBBtn outline rounded color='success' onClick={() => { setTraderAccountDispatch(traderAccount); }} >
                See details
            </MDBBtn>
        </div>
    );
};

TraderAccountsItem.propTypes = {
    traderAccount: PropTypes.instanceOf(TraderAccount).isRequired
};

export default TraderAccountsItem;
