import './privacy-policy.styles.scss';
import React from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';

const PrivacyPolicyComponent = () =>
{
	return (
		<div className="privacy-policy">
			<h1 className='title'>Privacy Policy</h1>
			<p>Updated at 2022-06-22</p>
			<p>
								<span className="s1">
									BinaryDew. ("we," "our," or "us") is committed to protecting your privacy.
									This Privacy Policy explains how your personal information is collected, used, and disclosed by BinaryDew.
									<br/>
									This Privacy Policy applies to our website, https://binarydew.com, and its associated subdomains (collectively, our "Service").
									By accessing or using our Service, you signify that you have read,
									understood, and agree to our collection, storage, use, and disclosure of your personal information as
									described in this Privacy Policy and our <a href='terms-of-service' target='_blank'>Terms of Service <MDBIcon fas icon="external-link-alt" /></a>.
								</span>
			</p>
			<p className="p6"><br/></p>
			<h1>Definitions and key terms</h1>
			<p className="p8"><span className="s1">For this Privacy Policy:</span></p>
			<p className="p9"><br/></p>
			<ul className="ul1">
				<li className="li8">
									<span className="s1"><strong>Cookie:</strong> small amount of data generated by a website and
									saved by your web browser. It is used to identify your browser, provide analytics, remember
									information about you such as your language preference or login information.<br/>
									</span>
				</li>
				<li className="li8"><span className="s1"><strong>Company:</strong> when this policy mentions “Company,” “we,” “us,”
                    or “our,” it refers to <a href='/'>BinaryDew Inc</a>, <a href='https://goo.gl/maps/2V2BvJjZmtJr9pbC6'>Accra, Ghana. 00233</a> that is responsible
                    for your information under this Privacy Policy.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Country:</strong> where BinaryDew or the owners/founders of BinaryDew are based, in this case is <a href='https://judicial.gov.gh'>Ghana</a>.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Customer:</strong> refers to the company, organization or person
                    that signs up to use the BinaryDew Service to manage the relationships with your consumers or
                    service users.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Device:</strong> any internet connected device such as a phone,
                    tablet, computer or any other device that can be used to visit BinaryDew and use the
                    services.<br/></span></li>
				<li className="li8"><span className="s1"><strong>IP address:</strong> Every device connected to the Internet is
                    assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in
                    geographic blocks. An IP address can often be used to identify the location from which a device is
                    connecting to the Internet.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Personnel:</strong> refers to those individuals who are employed
					by BinaryDew or are under contract to perform a service on behalf of one of the parties.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Personal Data:</strong> any information that directly, indirectly,
                    or in connection with other information — including a personal identification number — allows for
                    the identification or identifiability of a natural person.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Service:</strong> refers to the service provided by BinaryDew as
					described in the relative terms (if available) and on this platform.<br/></span>
				</li>
				<li className="li8"><span className="s1"><strong>Third-party service:</strong> refers to advertisers, contest
                    sponsors, promotional and marketing partners, and others who provide our content or whose products
                    or services we think may interest you.<br/></span></li>
				<li className="li8"><span className="s1"><strong>Website:</strong> BinaryDew’s site, which can be accessed
                    via this URL: <a href='/'>https://binarydew.com.</a><br/></span></li>
				<li className="li8"><span className="s1"><strong>You:</strong> a person or entity that is registered with BinaryDew to use
					the Services.<br/></span></li>
			</ul>
			<p className="p9"><br/></p>
			<div>
				<h1>Information automatically collected</h1>
				<p className="p10"><span className="s1">There is some information like your Internet Protocol (IP) address and/or
                    browser and device characteristics — is collected automatically when you visit our platform. This
                    information may be used to connect your computer to the Internet. Other information collected
                    automatically could be a login, e-mail address, password, computer and connection information such
                    as browser plug-in types and versions and time zone setting, operating systems and platforms,
                    purchase history, (we sometimes aggregate with similar information from other Users), the full
                    Uniform Resource Locator (URL) clickstream to, through and from our Website that may include date
                    and time; cookie number; parts of the site you viewed or searched for; and the phone number you used
                    to call our Customer Services. We may also use browser data such as cookies, Flash cookies (also
                    known as Flash Local Shared Objects) or similar data on certain parts of our Website for fraud
                    prevention and other purposes. During your visits, we may use software tools such as JavaScript to
                    measure and collect session information including page response times, download errors, length of
                    visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs),
                    and methods used to browse away from the page. We may also collect technical information to help us
                    identify your device for fraud prevention and diagnostic purposes.
                    <br/><br/>
                    We automatically collect certain information when you visit, use or navigate the platform. This
                    information does not reveal your specific identity (like your name or contact information) but may
                    include device and usage information, such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs, device name, country, location, information
                    about who and when you use our and other technical information. This information is primarily needed
                    to maintain the security and operation of our , and for our internal analytics and reporting
                    purposes.</span></p>
			</div>
			<p className="p12"><br/></p>
			
			
			<p className="p3"><br/></p>
			<h1>How Do We Use The Information We Collect?</h1>
			<p className="p10"><span className="s1">Any of the information we collect from you may be used in one of the following
                ways:</span></p>
			<ul className="ul1">
				<li className="li11"><span className="s3">To personalize your experience (your information helps us to better
                    respond to your individual needs)</span></li>
				<li className="li11"><span className="s3">To improve our service (we continually strive to improve our service
                    offerings based on the information and feedback we receive from you)</span></li>
				<li className="li11"><span className="s3">To improve customer service (your information helps us to more effectively
                    respond to your customer service requests and support needs)</span></li>
				<li className="li11"><span className="s3">To process transactions</span></li>
				<li className="li11"><span className="s3">To administer a contest, promotion, survey or other site feature</span>
				</li>
				<li className="li11"><span className="s3">To send periodic emails</span></li>
			</ul>
			<p className="p3"><br/></p>
			<h1>When do we use customer information from third parties?</h1>
			<p className="p10"><span className="s1">We receive some information from the third parties when you contact us. For
                example, when you submit your email address to us to show interest in becoming our customer, we receive
                information from a third party that provides automated fraud detection services to us. We also
                occasionally collect information that is made publicly available on social media websites. You can
                control how much of your information social media websites make public by visiting these websites and
                changing your privacy settings.</span></p>
			<p className="p3"><br/></p>
			<h1>Do we share the information we collect with third parties?</h1>
			<p className="p10"><span className="s1">We may share the information that we collect, both personal and non-personal,
                with third parties such as advertisers, contest sponsors, promotional and marketing partners, and others
                who provide our content or whose products or services we think may interest you. We may also share it
                with our current and future affiliated companies and business partners, and if we are involved in a
                merger, asset sale or other business reorganization, we may also share or transfer your personal and
                non-personal information to our successors-in-interest.
                <br/><br/>
                We may engage trusted third party service providers to perform functions and provide services to us,
                such as hosting and maintaining our servers and our service, database storage and management, e-mail
                management, storage marketing, credit card processing, customer service and fulfilling orders for
                products and services you may purchase through our platform. We will likely share your personal
                information, and possibly some non-personal information, with these third parties to enable them to
                perform these services for us and for you.
                <br/><br/>
                We may share portions of our log file data, including IP addresses, for analytics purposes with third
                parties such as web analytics partners, application developers, and ad networks. If your IP address is
                shared, it may be used to estimate general location and other technographics such as connection speed,
                whether you have visited the service in a shared location, and type of device used to visit the service.
                They may aggregate information about our advertising and what you see on the service and then provide
                auditing, research and reporting for us and our advertisers.
                <br/><br/>
                We may also disclose personal and non-personal information about you to government or law enforcement
                officials or private parties as we, in our sole discretion, believe necessary or appropriate in order to
                respond to claims, legal process (including subpoenas), to protect our rights and interests or those of
                a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or
                legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and
                regulations.
            </span></p>
			<p className="p3"><br/></p>
			<h1>Where and when is information collected from customers and end users?</h1>
			<p className="p10"><span className="s1">We will collect personal information that you submit to us. We may also receive
                personal information about you from third parties as described above.</span></p>
			<p className="p3"><br/></p>
			
			
			<p className="p3"><br/></p>
			<h1>Could my information be transferred to other countries?</h1>
			<p className="p10"><span className="s1">We are incorporated in Ghana. Information collected via our website, through
                direct interactions with you, or from use of our help services may be transferred from time to time to
                our offices or personnel, or to third parties, located throughout the world, and may be viewed and
                hosted anywhere in the world, including countries
                that may not have laws of general applicability regulating the use and transfer of such data. To the
                fullest extent allowed by applicable law, by using any of the above, you voluntarily consent to the
                trans- border transfer and hosting of such information.</span></p>
			<p className="p3"><br/></p>
			<h1>Is the information collected through our service secure?</h1>
			<p className="p10"><span className="s1">We take precautions to protect the security of your information. We have
                physical, electronic, and managerial procedures to help safeguard, prevent unauthorized access, maintain
                data security, and correctly use your information. However, neither people nor security systems are
                foolproof, including encryption systems. In addition, people can commit intentional crimes, make
                mistakes or fail to follow policies. Therefore, while we use reasonable efforts to protect your personal
                information, we cannot guarantee its absolute security. If applicable law imposes any non-disclaimable
                duty to protect your personal information, you agree that intentional misconduct will be the standards
                used to measure our compliance with that duty.</span></p>
			<p className="p3"><br/></p>
			<h1>Can I update or correct my information?</h1>
			<p className="p10"><span className="s1">The rights you have to request updates or corrections to the information we
                collect depend on your relationship with us. Personnel may update or correct their information as
                detailed in our internal company employment policies.
                Customers have the right to request the restriction of certain uses and disclosures of personally
                identifiable information as follows. You can contact us in order to (1) update or correct your
                personally identifiable information, (2) change your preferences with respect to communications and
                other information you receive from us, or (3) delete the personally identifiable information maintained
                about you on our systems (subject to the following paragraph), by cancelling your account. Such updates,
                corrections, changes and deletions will have no effect on other information that we maintain, or
                information that we have provided to third parties in accordance with this Privacy Policy prior to such
                update, correction, change or deletion. To protect your privacy and security, we may take reasonable
                steps (such as requesting a unique password) to verify your identity before granting you profile access
                or making corrections. You are responsible for maintaining the secrecy of your unique password and
                account information at all times.
                You should be aware that it is not technologically possible to remove each and every record of the
                information you have provided to us from our system. The need to back up our systems to protect
                information from inadvertent loss means that a copy of your information may exist in a non-erasable form
                that will be difficult or impossible for us to locate. Promptly after receiving your request, all
                personal information stored in databases we actively use, and other readily searchable media will be
                updated, corrected, changed or
                deleted, as appropriate, as soon as and to the extent reasonably and technically practicable.
                If you are an end user and wish to update, delete, or receive any information we have about you, you may
                do so by contacting the organization of which you are a customer.</span></p>
			<p className="p3"><br/></p>
			<h1>Personnel</h1>
			<p className="p10"><span className="s1">If you are one of our workers or applicants, we collect information you
                voluntarily provide to us. We use the information collected for Human Resources purposes in order to
                administer benefits to workers and screen applicants.
                You may contact us in order to (1) update or correct your information, (2) change your preferences with
                respect to communications and other information you receive from us, or (3) receive a record of the
                information we have relating to you. Such updates, corrections, changes and deletions will have no
                effect on other information that we maintain, or information that we have provided to third parties in
                accordance with this Privacy Policy prior to such update, correction, change or deletion.</span></p>
			<p className="p3"><br/></p>
			<h1>Sale of Business</h1>
			<p className="p10"><span className="s1">We reserve the right to transfer information to a third party in the event of a
                sale, merger or other transfer of all or substantially all of the assets of us or any of its Corporate
                Affiliates (as defined herein), or that portion of us or any of its
                Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or
                file a petition or have filed against us a petition in bankruptcy, reorganization or similar proceeding,
                provided that the third party agrees to adhere to the terms of this Privacy Policy.</span></p>
			<p className="p3"><br/></p>
			<h1>Affiliates</h1>
			<p className="p10"><span className="s1">We may disclose information (including personal information) about you to our
                Corporate Affiliates. For purposes of this Privacy Policy, "Corporate Affiliate" means any person or
                entity which directly or indirectly controls, is controlled by or is under common control with us,
                whether by ownership or otherwise. Any information relating to you that we provide to our Corporate
                Affiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy
                Policy.</span></p>
			<p className="p3"><br/></p>
			<h1>How Long Do We Keep Your Information?</h1>
			<p className="p10"><span className="s1">We keep your information only so long as we need it to provide service to you
                and fulfill the purposes described in this policy. This is also the case for anyone that we share your
                information with and who carries out services on our behalf. When we no longer need to use your
                information and there is no need for us to keep it to comply with our legal or regulatory obligations,
                we'll either remove it from our systems or depersonalize it so that we can't identify you.</span></p>
			<p className="p3"><br/></p>
			<h1>How Do We Protect Your Information?</h1>
			<p className="p10"><span className="s1">We implement a variety of security measures to maintain the safety of your
                personal information when you place an order or enter, submit, or access your personal information. We
                offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure
                Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be
                accessible by those authorized with special access rights to such systems, and are required to keep the
                information confidential. After a transaction, your private information (credit cards, social security
                numbers, financials, etc.) is never kept on file. We cannot, however, ensure or warrant the absolute
                security of any information you transmit to us or guarantee that your information on the Service may not
                be accessed, disclosed, altered, or destroyed by a breach of any of our physical, technical, or
                managerial safeguards.</span></p>
			<p className="p3"><br/></p>
			<h1>Governing Law</h1>
			<p className="p10"><span className="s1">The laws of Ghana, excluding its conflicts of law rules, shall govern this
                Agreement and your use of our service. Your use of our service may also be subject to other local,
                state, national, or international laws.</span></p>
			<p className="p3"><br/></p>
			<h1>Your Consent</h1>
			<p className="p10"><span className="s1">By using our service, registering an account, or making a purchase, you consent
                to this Privacy Policy.</span></p>
			<p className="p3"><br/></p>
			<h1>Links to Other Websites</h1>
			<p className="p10"><span className="s1">This Privacy Policy applies only to the Services. The Services may contain links
                to other websites not operated or controlled by . We are not responsible for the content, accuracy or
                opinions expressed in such websites, and such websites are not investigated, monitored or checked for
                accuracy or completeness by us. Please remember that when you use a link to go from the Services to
                another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any other
                website, including those that have a link on our platform, is subject to that website’s own rules and
                policies. Such third parties may use their own cookies or other methods to collect information about
                you.</span></p>
			<p className="p3"><br/></p>
			<h1>Cookies</h1>
			<p className="p10"><span className="s1">We use "Cookies" to identify the areas of our website that you have visited. A
                Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use
                Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable
                the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our
                website correctly or at all. We never place Personally Identifiable Information in Cookies.</span></p>
			<p className="p3"><br/></p>
			
			
			
			
			
			<p className="p15"><br/></p>
			
			
			
			<h1>Payment Details</h1>
			<p className="p10"><span className="s1">In respect to any credit card or other payment processing details you have
                provided us, we commit that this confidential information will be stored in the most secure manner
                possible.</span></p>
			<p className="p15"><br/></p>
			
			
			
			<h1>Kids' Privacy</h1>
			<p className="p10"><span className="s1">We do not address anyone under the age of 13. We do not knowingly collect
                personally identifiable information from anyone under the age of 13. If You are a parent or guardian and
                You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
                that We have collected Personal Data from anyone under the age of 13 without verification of parental
                consent, We take steps to remove that information from our servers.</span></p>
			<p className="p3"><br/></p>
			<h1>Changes To Our Privacy Policy</h1>
			<p className="p10"><span className="s1">If we decide to change our privacy policy, we will post those changes on this
                page, and/or update the Privacy Policy modification date below.</span></p>
			<p className="p3"><br/></p>
			<h1>Third-Party Services</h1>
			<p className="p10"><span className="s1">We may display, include or make available third-party content (including data,
                information, applications and other products services) or provide links to third-party websites or
                services ("Third- Party Services").</span></p>
			<p className="p10"><span className="s1">You acknowledge and agree that we shall not be responsible for any Third-Party
                Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality,
                decency, quality or any other aspect thereof. We do not assume and shall not have any liability or
                responsibility to you or any other person or entity for any Third-Party Services.</span></p>
			<p className="p10"><span className="s1">Third-Party Services and links thereto are provided solely as a convenience to
                you and you access and use them entirely at your own risk and subject to such third parties' terms and
                conditions.</span></p>
			<p className="p3"><br/></p>
			<h1>Facebook Pixel</h1>
			<p className="p10"><span className="s1">Facebook pixel is an analytics tool that allows you to measure the effectiveness
                of your advertising by understanding the actions people take on your website. You can use the pixel to:
                Make sure your ads are shown to the right people. Facebook pixel may collect
                information from your device when you use the service. Facebook pixel collects information that is held
                in accordance with its Privacy Policy.</span></p>
			<p className="p3"><br/></p>
			<h1>Tracking Technologies</h1>
			
			
			<li className="li11"><span className="s3">Cookies</span></li>
			<p className="p10"><span className="s1">
                We use Cookies to enhance the performance and functionality of our service but are non-essential to
                their use. However, without these cookies, certain functionality like videos may become unavailable or
                you would be required to enter your
                login details every time you visit our service as we would not be able to remember that you had logged
                in previously.
            </span></p>
			<li className="li11"><span className="s3">Local Storage</span></li>
			<p className="p10"><span className="s1">
                Local Storage sometimes known as DOM storage, provides web apps with methods and protocols for storing
                client-side data. Web storage supports persistent data storage, similar to cookies but with a greatly
                enhanced capacity and no information stored in the HTTP request header.
            </span></p>
			<li className="li11"><span className="s3">Sessions</span></li>
			<p className="p10"><span className="s1">
                We use "Sessions" to identify the areas of our website that you have visited. A Session is a small piece
                of data stored on your computer or mobile device by your web browser.
            </span></p>
			<p className="p3"><br/></p>
			<h1>Information about General Data Protection Regulation (GDPR)</h1>
			<p className="p10"><span className="s1">We may be collecting and using information from you if you are from the European
                Economic Area (EEA), and in this section of our Privacy Policy we are going to explain exactly how and
                why is this data collected, and how we maintain this data under protection from being replicated or used
                in the wrong way.</span></p>
			<p className="p12"><br/></p>
			<h1>What is GDPR?</h1>
			<p className="p10"><span className="s1">GDPR is an EU-wide privacy and data protection law that regulates how EU
                residents' data is protected by companies and enhances the control the EU residents have, over their
                personal data.
                <p className="p12"><br/></p>
                <h1>What is personal data?</h1>
                <p className="p10"><span className="s1">Any data that relates to an identifiable or identified individual. GDPR
                        covers a broad spectrum of information that could be used on its own, or in combination with
                        other pieces of information, to identify a person. Personal data extends beyond a person’s name
                        or email address. Some examples include financial information, political opinions, genetic data,
                        biometric data, IP addresses, physical address, sexual orientation, and ethnicity.
                        The Data Protection Principles include requirements such as:</span></p>
                <ul className="ul1">
                    <li className="li11"><span className="s3">Personal data collected must be processed in a fair, legal, and
                            transparent way and should only be used in a way that a person would reasonably
                            expect.</span></li>
                    <li className="li11"><span className="s3">Personal data should only be collected to fulfil a specific
                            purpose and it should only be used for that purpose. Organizations must specify why they
                            need the personal data when they collect it.</span></li>
                    <li className="li11"><span className="s3">Personal data should be held no longer than necessary to fulfil
                            its purpose.</span></li>
                    <li className="li11"><span className="s3">People covered by the GDPR have the right to access their own
                            personal data.
                            They can also request a copy of their data, and that their data be updated, deleted,
                            restricted, or moved to another organization.</span></li>
                </ul>
                <p className="p12"><br/></p>
                <h1>Why is GDPR important?</h1>
                <p className="p10"><span className="s1">GDPR adds some new requirements regarding how companies should protect
                        individuals' personal data that they collect and process. It also raises the stakes for
                        compliance by increasing enforcement and imposing greater fines for breach. Beyond these facts
                        it's simply the right thing to do. At Help Scout we strongly believe that your data privacy is
                        very important and we already have solid security and privacy practices in place that go beyond
                        the requirements of this new regulation.</span></p>
                <p className="p12"><br/></p>
                <h1>Individual Data Subject's Rights - Data Access, Portability and Deletion</h1>
                <p className="p10"><span className="s1">We are committed to helping our customers meet the data subject rights
                        requirements of GDPR. We process or store all personal data in fully vetted, DPA compliant
                        vendors. We do store
                        all conversation and personal data for up to 6 years unless your account is deleted. In which
                        case, we dispose of all data in accordance with our <a href='terms-of-service' target='_blank'>Terms of Service <MDBIcon fas icon="external-link-alt" /></a> and Privacy Policy, but we
                        will not hold it longer than 60 days.
                        <br/><br/>
                        We are aware that if you are working with EU customers, you need to be able to provide them with
                        the ability to access, update, retrieve and remove personal data. We got you! We've been set up
                        as self service from the start and have always given you access to your data and your customers
                        data. Our customer support team is here for you to answer any questions you might have about
                        working with the API.</span></p>
                <p className="p3"><br/></p>
                                <p className="p3"><br/></p>
                <h1>Contact Us</h1>
                <p className="p10"><span
					className="s1">Don't hesitate to contact us if you have any questions.</span></p>
                <ul className="ul1">
                    <li className="li16"><span className="s3">Via Email: <a href='mailto:support@binarydew.com'>support@binarydew.com</a></span></li>
                    <li className="li16"><span className="s3">Via Phone Number: <a href='tel:+233-595-855100'>+233-595-855100</a></span></li>
                    <li className="li16"><span className="s3">Via this Link: <a href='/contact'>https://binarydew.com/contact</a></span></li>
                    <li className="li16"><span className="s3">Via this Address: <a href='https://goo.gl/maps/2V2BvJjZmtJr9pbC6'>Accra, Ghana. 00233</a></span></li>
                </ul>
            </span></p>
		</div>
	);
};

export default React.memo(PrivacyPolicyComponent);
