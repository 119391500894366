import './tutorials.styles.scss';

import React, { useEffect } from 'react';
import AOS from 'aos';

import { MDBIcon } from 'mdb-react-ui-kit';
import useHashFragment from '../../hooks/useHashFragment';

const Tutorials = () => {

    useEffect(
        () => {
            AOS.init();
        }
        , []
    );
    
    useHashFragment();

    return (
        <div id="top" className='tutorials'>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>Tutorials</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='feature section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='200' data-aos-once='true'>
                            <div className='link-box feature-box'>
                                <a href="#binaryToken">BinaryToken</a>
                                <div className='tumb'>
                                    <img src='/images/tutorials/binary_token/binary_logo.png' alt=''/>
                                </div>
                                <h4 className='text-title'>Prepare Binary/Deriv API Token</h4>
                                <p>
                                    Learn how to create a new API Token on binary.com/deriv.com suitable for our platform.
                                </p>
                                <div className='link-button mt-3'>Read more...</div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>
                            <div className='link-box feature-box'>
                                <a href="#changeToken">Change Token</a>
                                <div className='tumb'>
                                    <img src='/images/tutorials/change_token/api_token.png' alt=''/>
                                </div>
                                <h4 className='text-title'>Update your API Token</h4>
                                <p>
                                    Learn how you can change the binary.com's/deriv.com's API token associated with your subscriptions.
                                </p>
                                <div className='link-button mt-3'>Read more...</div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-12' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                            <div className='link-box feature-box'>
                                <a href="#cancelSubscription">Cancel Subscription</a>
                                <div className='tumb'>
                                    <img src='/images/tutorials/cancel_subscription/cancelled.png' alt=''/>
                                </div>
                                <h4 className='text-title'>Cancel Subscription</h4>
                                <p>
                                    Learn how to cancel your active subscription(s) and refund your money.
                                </p>
                                <div className='link-button mt-3'>Read more...</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="binaryToken" className='section bg-white'>
                <div className='container'>
                    <h2 className='text-center fw-bold mb-4' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Prepare Binary/Deriv API Token</h2>
                    <div className='row g-0 mb-5' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                        <p>
                            When you buy a subscription from us, you will be asked to enter API token of your binary.com/deriv.com account.
                        </p>
                        <p>
                            We use this token to open contracts on your account. This is the safest and the most secure solution
                            since you will keep your money in your binary.com/deriv.com account. As promised, you don't need to deposit your
                            investing money into your BinaryDew's account.
                        </p>
                        <p>
                            Before being able to use our platform, you need to take following steps to create an API token on either of these platforms:
                        </p>
                        <p>
                            1. Login or create an account on <a href='https://binary.com' target='_blank' rel='noopener noreferrer'>binary.com</a>/<a href='https://deriv.com' target='_blank' rel='noopener noreferrer'>deriv.com</a>
                            <div className='text-danger'>
                                <b>Important Note:</b> Both platforms are similar and credentials created on one platform can be
                                interchangeably used on the other one too.
                            </div>
                        </p>
                        <p>
                            2. Directly follow one of these URLs: <a href='https://www.binary.com/en/user/security/api_tokenws.html' target='_blank' rel='noopener noreferrer'>binary.com</a>/<a href='https://app.deriv.com/account/api-token' target='_blank' rel='noopener noreferrer'>deriv.com</a> or...
                        </p>
                        <p>
                            <b className='text-binarydew'>Binary.com:</b> From top menubar of the binary.com website, navigate to "Settings" and then "Security & Limits"
                            <br/>
                            <b className='text-binarydew'>Deriv.com:</b> From top menubar of the deriv.com website, click on "Manage account settings" icon
                        </p>
                        <p>
                            Screenshot (<b className='text-binarydew'>binary.com</b>):
                        </p>
                        <div className='screenshot'>
                            <img src='/images/tutorials/binary_token/binary_step2_1.png' alt=''/>
                        </div>
                        <p>
                            Screenshot (<b className='text-binarydew'>deriv.com</b>):
                        </p>
                        <div className='screenshot'>
                            <img src='/images/tutorials/binary_token/deriv_step2_1.png' alt=''/>
                        </div>
                        <p>
                            Then from within the next screen, click on "API Token" item.
                        </p>
                        <p>
                            Screenshot (<b className='text-binarydew'>binary.com</b>):
                        </p>
                        <div className='screenshot'>
                            <img src='/images/tutorials/binary_token/binary_step2_2.png' alt=''/>
                        </div>
                        <p>
                            Screenshot (<b className='text-binarydew'>deriv.com</b>):
                        </p>
                        <div className='screenshot-deriv'>
                            <img src='/images/tutorials/binary_token/deriv_step2_2.png' alt=''/>
                        </div>
                        <p>
                            3. From the next screen...
                            <br/>
                            <b className='text-binarydew'>For Binary.com:</b> On the section named "Create new token" , choose an optional name and select following
                            scopes: "Read", "Trade", and "Trading information".
                            <br/>
                            <b className='text-binarydew'>For Deriv.com:</b> On the first section, Select following scopes: "Read", "Trade", and "Trading information",
                            and on the second section choose an optional name and click on "Create" button.
                        </p>
                        <p className='text-danger'>
                            <b>Important Note 1:</b> These are mandatory scopes and
                            our platform will be unable to provide you any services if you fail to correctly select these settings.
                            <br/>
                            <b>Important Note 2:</b> "Payments" and "Admin" scopes are not required and you should avoid selecting
                            them. We care about your account's security.
                        </p>
                        <p>
                            Screenshot (<b className='text-binarydew'>binary.com</b>):
                        </p>
                        <div className='screenshot'>
                            <img src='/images/tutorials/binary_token/binary_step3.png' alt=''/>
                        </div>
                        <p>
                            Screenshot (<b className='text-binarydew'>deriv.com</b>):
                        </p>
                        <div className='screenshot'>
                            <img src='/images/tutorials/binary_token/deriv_step3.png' alt=''/>
                        </div>
                        <p>
                            4. Your API token will be generated and added into the list below the form. Copy token value
                            as you will need it while buying subscriptions from us.
                        </p>
                        <p>
                            Screenshot (<b className='text-binarydew'>Both platforms</b>):
                        </p>
                        <div className='screenshot'>
                            <img src='/images/tutorials/binary_token/binary_step4.png' alt=''/>
                        </div>
                    </div>
                    <a href="#top" className="me-2">
                        <MDBIcon fas icon="angle-double-up me-2" />
                        <span>Go to top</span>
                    </a>
                </div>
            </div>
            <div id="changeToken" className='section bg-white'>
                <div className='container'>
                    <h2 className='text-center fw-bold mb-4' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Update your API Token</h2>
                    <div className='row g-0 mb-5' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                        No content yet. We will update this section soon.
                    </div>
                    <a href="#top" className="me-2">
                        <MDBIcon fas icon="angle-double-up me-2" />
                        <span>Go to top</span>
                    </a>
                </div>
            </div>
            <div id="cancelSubscription" className='section bg-white'>
                <div className='container'>
                    <h2 className='text-center fw-bold mb-4' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Cancel Subscription</h2>
                    <div className='row g-0 mb-5' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                        No content yet. We will update this section soon.
                    </div>
                    <a href="#top" className="me-2">
                        <MDBIcon fas icon="angle-double-up me-2" />
                        <span>Go to top</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Tutorials;
