import axios from 'axios';
import { getAPIUrl } from '../urlFactory';
import { apiErrorRethrow } from '../errors/ErrorHandlers';
import TraderAccount from '../contracts/TraderAccount';

/**
 * Gets list of trader accounts
 * @returns {Promise<TraderAccount[]>}
 */
export async function getTraderAccounts() {
	const listURL = new URL('profile/traders', getAPIUrl()).href;
	try {
		const response = await axios.get(
			listURL,
			{
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
			});

		return response.data.traders
					   .map(t => Object.assign(new TraderAccount(), t));
	} catch (err) {
		apiErrorRethrow(err);
	}
}

/**
 * Update account number or reset API token of a trader account
 * @param traderId {number}
 * @param account_number {number|undefined}
 * @param reset_token {boolean|undefined}
 * @return {Promise<TraderAccount>}
 */
export async function updateTraderAccounts(traderId, account_number, reset_token) {
	const updateURL = new URL(`profile/traders/${traderId}`, getAPIUrl()).href;
	try {
		const response = await axios.patch(
			updateURL,
			{
				account_number,
				reset_token,
			},
			{
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
			});
		
		return Object.assign(new TraderAccount(), response.data.trader)
	} catch (err) {
		apiErrorRethrow(err);
	}
}
