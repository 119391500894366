import './preloader.styles.scss';

import React, {useEffect, useRef} from 'react';

const Preloader = () => {
    const preloaderRef = useRef();

    useEffect(
        () => {
            setTimeout(
                () => {
                    preloaderRef.current.style.opacity = '0';
                    preloaderRef.current.style.display = 'none';
                },
                500
            );
        },
        []
    );

    return (
        <div className="preloader" ref={preloaderRef}>
            <div className="preloader-inner">
                <div className="preloader-icon">
                    <span />
                    <span />
                </div>
            </div>
        </div>
    );
};

export default Preloader;
