import './secret-copy-text.styles.scss';

import React, {useState} from 'react';
import {MDBIcon, MDBInput} from 'mdb-react-ui-kit';

const SecretCopyText = (props) => {
    const type = props.hasOwnProperty('type') ? props.type : 'alert';

    const [hidden, setHidden] = useState(props.hasOwnProperty('hidden') ? props.hidden : true);

    const showText = () => {
      setHidden(false);
    };

    const hideText = () => {
        setHidden(true);
    };

    const copyText = () => {
        navigator.clipboard.writeText(props.text)
            .then(
                () => {
                    console.log('Copying to clipboard was successful!');
                }
            )
            .catch(
                err => {
                    console.error('Could not copy text: ', err);
                }
            )
    };

    return type === 'alert' ?
        (
            <div className={`secret-copy-text w-100 d-flex justify-content-between alert ${props.hasOwnProperty('colorClass') ? `alert-${props.colorClass}` : ''} ${props.hasOwnProperty('className') ? props.className : ''}`}>
                {
                    hidden ?
                        '*'.repeat(props.text.length)
                        :
                        props.text
                }
                <div className='d-flex'>
                    {
                        (!props.hasOwnProperty('showBtn') || props.showBtn) && hidden ?
                            <MDBIcon fas icon='eye' className='d-flex align-items-center ms-1 px-1' onClick={showText} />
                            :
                            null
                    }
                    {
                        (!props.hasOwnProperty('showBtn') || props.showBtn) && !hidden ?
                            <MDBIcon fas icon='eye-slash' className='d-flex align-items-center ms-1 px-1' onClick={hideText} />
                            :
                            null
                    }
                    {
                        !props.hasOwnProperty('copyBtn') || props.copyBtn ?
                            <MDBIcon fas icon='copy' className='d-flex align-items-center ms-1 px-1' onClick={copyText} />
                            :
                            null
                    }
                </div>
            </div>
        )
        :
        (
            <div className='secret-copy-text w-100'>
                <MDBInput label={props.label} type="text" value={hidden ? '*'.repeat(props.text.length) : props.text} disabled={props.disabled}
                    onChange={props.onChange}>
                    <div className='input-btn-box d-flex position-absolute'>
                        {
                            (!props.hasOwnProperty('showBtn') || props.showBtn) && hidden ?
                                <MDBIcon fas icon='eye' className='d-flex align-items-center ms-1 px-1' onClick={showText} />
                                :
                                null
                        }
                        {
                            (!props.hasOwnProperty('showBtn') || props.showBtn) && !hidden ?
                                <MDBIcon fas icon='eye-slash' className='d-flex align-items-center ms-1 px-1' onClick={hideText} />
                                :
                                null
                        }
                        {
                            !props.hasOwnProperty('copyBtn') || props.copyBtn ?
                                <MDBIcon fas icon='copy' className='d-flex align-items-center ms-1 px-1' onClick={copyText} />
                                :
                                null
                        }
                    </div>
                </MDBInput>
            </div>
        )
        ;
};

export default SecretCopyText;
