import axios from 'axios';
import { getAPIUrl } from '../urlFactory';
import { apiErrorRethrow } from '../errors/ErrorHandlers';

/**
 * Total statistics of contracts
 * @typedef {{profit: number; loss: number; consecutive_losses_count: number; total_win_count: number; total_count: number}} StatsContractsTotals
 */
/**
 * Contract details
 * @typedef {
 *     {
 * 	buy_price: number,
 * 	contract_id: number,
 * 	contract_type: 'CALL'|'PUT',
 * 	duration_unit: 'd'|'s'|'m'|'h'|'t',
 * 	duration: number,
 * 	currency: string,
 * 	date_start: Date,
 * 	expiry_time: Date,
 * 	longcode: string,
 * 	payout: number,
 * 	purchase_time: Date,
 * 	shortcode: string,
 * 	symbol: string,
 * 	sell_price: number,
 * 	sell_time?: Date,
 * 	transaction_id: number,
 * }
 * } StatsContractObj
 */

/**
 * Get contracts of today to see how was our trading performance.
 * @returns {Promise<{contracts: StatsContractObj[]; totals: StatsContractsTotals}>}
 */
export async function getTodayContractsStats() {
    const listURL = new URL('stats/contracts/today', getAPIUrl()).href;
    try {
        const response = await axios.get(
            listURL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        return response.data;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Get total statistics of today contracts to see how was our trading performance.
 * @param {AbortSignal} cancelSignal
 * @return {Promise<StatsContractsTotals>}
 */
export async function getTodayContractsTotalsStats(cancelSignal) {
    const listURL = new URL('stats/contracts/totals', getAPIUrl()).href;
    try {
        const response = await axios.get(
            listURL,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                withCredentials : true,
                signal: cancelSignal,
            });
        return response.data.totals;
    } catch (err) {
        apiErrorRethrow(err);
    }
}
