import './header.styles.scss';

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { selectUserAccount } from '../../redux/core/user/user.selectors';
import { accountLogout } from '../../libs/client/AuthManager';
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import { getProfileImageLink } from '../../libs/urlFactory';

const Header = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUserAccount);

    const headerRef = useRef();
    const logoRef = useRef();
    const navbarRef = useRef();
    const mobileBtnRef = useRef();

    /**
     * Send notification
     * @param type {'info'|'success'|'error'|'warning'}
     * @param text
     * @returns {{payload: NotificationData, type: string}}
     */
    const sendNotification = (type, text) => dispatch(addNotification(type, text));

    const mobileBtnClick = (e) => {
        e.currentTarget.classList.toggle('active');
        navbarRef.current.classList.toggle('show');
    };

    const closeMenu = () => {
        mobileBtnRef.current.classList.remove('active');
        navbarRef.current.classList.remove('show');
    };

    /*const userBtnClick = (e) => {
        e.currentTarget.parentElement.classList.toggle('open');
    };*/

    const userLogout = () => {
        accountLogout(dispatch)
            .then(
                message => {
                    sendNotification('success', message);
                }
            )
            .catch(
                err => {
                    sendNotification('error', err.message);
                }
            );
    };

    useEffect(
        () => {
            window.onscroll = () => {
                const sticky = headerRef.current.offsetTop;
                const appEle = document.querySelector('.App');
                if (window.scrollY > sticky) {
                    headerRef.current.classList.add('sticky');
                    appEle.classList.add('scrolled');
                    logoRef.current.src = '/images/logo/logo-dark-web-sm.png';
                } else {
                    headerRef.current.classList.remove('sticky');
                    appEle.classList.remove('scrolled');
                    logoRef.current.src = '/images/logo/logo-light-web-sm.png';
                }
            };
        },
        []
    );

    return (
        <header className='header navbar-area' ref={headerRef}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-12'>
                        <div className='nav-inner'>
                            <nav className='navbar navbar-expand-lg'>
                                <NavLink className='navbar-brand' to='/'>
                                    <img src='/images/logo/logo-light-web-sm.png' onClick={closeMenu} alt='Logo' ref={logoRef} />
                                </NavLink>
                                <button className='navbar-toggler mobile-menu-btn' type='button' ref={mobileBtnRef} onClick={mobileBtnClick}>
                                    <span className='toggler-icon'></span>
                                    <span className='toggler-icon'></span>
                                    <span className='toggler-icon'></span>
                                </button>
                                <div className='collapse navbar-collapse sub-menu-bar' id='navbarSupportedContent' ref={navbarRef}>
                                    <ul id='nav' className='navbar-nav ms-auto'>
                                        <li className='nav-item' onClick={closeMenu}>
                                            <NavLink to='/'>Home</NavLink>
                                        </li>
                                        <li className='nav-item' onClick={closeMenu}>
                                            <NavLink to='/pricing'>Pricing</NavLink>
                                        </li>
                                        <li className='nav-item' onClick={closeMenu}>
                                            <NavLink to='/about'>About</NavLink>
                                        </li>
                                        <li className='nav-item' onClick={closeMenu}>
                                            <NavLink to='/contact'>Contact us</NavLink>
                                        </li>
                                        {
                                            user ?
                                                <>
                                                    <li className='nav-item d-md-none' onClick={closeMenu}>
                                                        <NavLink to='/dashboard'>Dashboard</NavLink>
                                                    </li>
                                                    <li className='nav-item d-md-none' onClick={closeMenu}>
                                                        <button type='button' onClick={userLogout}>Log out</button>
                                                    </li>
                                                </>
                                                :
                                                <>
                                                    <li className='nav-item d-md-none' onClick={closeMenu}>
                                                        <NavLink to='/login'>Log in</NavLink>
                                                    </li>
                                                    <li className='nav-item d-md-none' onClick={closeMenu}>
                                                        <NavLink to='/register'>Register</NavLink>
                                                    </li>
                                                </>
                                        }
                                    </ul>
                                </div>
                                {
                                    user ?
                                        <div className='header-user position-relative'>
                                            <div className='header-user-info'>
                                                <img src={getProfileImageLink()} alt={user.name} />
                                                <span className='ps-1'>{user.name}</span>
                                                <i className='fas fa-angle-down ps-1' />
                                                <i className='fas fa-angle-up ps-1' />
                                            </div>
                                            <ul className='header-user-sub-menu'>
                                                <li>
                                                    <NavLink to='/dashboard'>Dashboard</NavLink>
                                                </li>
                                                <li>
                                                    <button type='button' onClick={userLogout}>Log out</button>
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        <div className='button'>
                                            <NavLink to='/login' className='btn'>Log in</NavLink>
                                            <NavLink to='/register' className='btn'>Register</NavLink>
                                        </div>
                                }
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
