import './dashboard-home.styles.scss';

import React, { useEffect, useState } from 'react';
import { MDBBadge, MDBIcon } from 'mdb-react-ui-kit';
import AOS from 'aos';

import { getPrices, getSubscriptions } from '../../libs/client/SubscriptionManager';
import Loading from '../loading/loading.component';
import { useSelector } from 'react-redux';
import { selectUserAccount } from '../../redux/core/user/user.selectors';
import { getTodayContractsTotalsStats } from '../../libs/client/StatisticsManager';

const DashboardHome = () => {
    const [pricesList, setPricesList] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [pricesDataStatus, setPricesDataStatus] = useState(false);
    const [subscriptionsDataStatus, setSubscriptionsDataStatus] = useState(false);
    const user = useSelector(selectUserAccount);
    const traderBadgeClass = user.id <= 0 ? 'col-12 col-lg-3 col-md-3' : 'col-12 col-sm-4';
    /**
     * @type ({profit: number; loss: number; consecutive_losses_count: number; total_win_count: number; total_count: number})
     */
    const [totals, setTotals] = useState(null);

    useEffect(
        () => {
            const controller = new AbortController();
            
            getPrices()
                .then(
                    prices => {
                        setPricesList(prices);
                        setPricesDataStatus(true);
                    }
                )
                .catch(
                    err => {
                        console.log(err.message);
                        setPricesDataStatus(true);
                    }
                );
            getSubscriptions()
                .then(
                    subscriptions => {
                        setSubscriptions(subscriptions);
                        setSubscriptionsDataStatus(true);
                    }
                )
                .catch(
                    err => {
                        console.log(err.message);
                        setSubscriptionsDataStatus(true);
                    }
                );
            
            getTodayContractsTotalsStats(controller.signal)
                .then((totals) =>
                {
                    setTotals(totals);
                });
    
            return function onCancel() {
                controller.abort();
            };
        }
        , []
    );

    useEffect(
        () => {
            if(pricesDataStatus && subscriptionsDataStatus) {
                let total = 0;
                subscriptions.forEach(
                    (subscription) => {
                        const price = pricesList.find(p => p.id === subscription.type_id);
                        if(price) {
                            total += price.price_discounted
                        }
                    }
                );
                setTotalPrice(total);
                AOS.init();
            }
        }
        , [pricesList, subscriptions, pricesDataStatus, subscriptionsDataStatus]
    );

    const netProfit = (totals?.profit + totals?.loss) || 0;
    
    return subscriptions !== null && totals !== null?
        (
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-6 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='400' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
                         data-aos-once='true'>
                        <div className='about-us-stats-box stats-box-primary mt-0'>
                            <div className='tumb'>
                                <MDBIcon fas icon='clone' className='fa-3x' />
                            </div>
                            <h4 className='text-title'>Subscriptions count</h4>
                            <h3><MDBBadge color='primary'>{subscriptions.length}</MDBBadge></h3>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
                         data-aos-once='true'>
                        <div className='about-us-stats-box stats-box-success mt-4 mt-sm-0'>
                            <div className='tumb'>
                                <MDBIcon fas icon='dollar-sign' className='fa-3x' />
                            </div>
                            <h4 className='text-title'>Total payment amount</h4>
                            <h3><MDBBadge color='success'>{totalPrice}$</MDBBadge></h3>
                        </div>
                    </div>
                </div>
                <hr className="my-12"/>
                <div className='row mt-4'>
                    <div className={ `${traderBadgeClass} d-flex justify-content-center` }
                         data-aos='flip-right' data-aos-delay='400' data-aos-duration='1000' data-aos-easing="ease-in-cubic" data-aos-once='true'>
                        <div className='about-us-stats-box stats-box-primary mt-4 mt-md-0'>
                            <div className='tumb'>
                                <MDBIcon fas icon='smile' className='fa-3x' />
                            </div>
                            <h4 className='text-title'>Total wins</h4>
                            <h3><MDBBadge color='success'>{totals?.total_win_count || 0}</MDBBadge></h3>
                            of today
                        </div>
                    </div>
                    {
                        user.id <= 0
                            ?
                            <div className={ `${traderBadgeClass} d-flex justify-content-center` }
                                 data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic" data-aos-once='true'>
                                <div className='about-us-stats-box stats-box-primary mt-4 mt-md-0'>
                                    <div className='tumb'>
                                        <MDBIcon fas icon='frown' className='fa-3x' />
                                    </div>
                                    <h4 className='text-title'>Consecutive losses</h4>
                                    <h3><MDBBadge color='danger'>{totals?.consecutive_losses_count || 0}</MDBBadge></h3>
                                    of today
                                </div>
                            </div>
                            : undefined
                    }
                    <div className={ `${traderBadgeClass} d-flex justify-content-center` }
                         data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic" data-aos-once='true'>
                        <div className='about-us-stats-box stats-box-success mt-4 mt-md-0'>
                            <div className='tumb'>
                                <MDBIcon fas icon='list-ol' className='fa-3x' />
                            </div>
                            <h4 className='text-title'>Total contracts</h4>
                            <h3><MDBBadge color='dark'>{totals?.total_count || 0}</MDBBadge></h3>
                            of today
                        </div>
                    </div>
                    <div className={ `${traderBadgeClass} d-flex justify-content-center` }
                         data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic" data-aos-once='true'>
                        <div className='about-us-stats-box stats-box-success mt-4 mt-md-0'>
                            <div className='tumb'>
                                <MDBIcon fas icon='dollar' className='fa-3x' />
                            </div>
                            <h4 className='text-title'>Net Profit</h4>
                            <h3><MDBBadge color={netProfit === 0 ? 'dark' : netProfit > 0 ? 'success' : 'danger'}>{netProfit.toFixed(2)}$</MDBBadge></h3>
                            of today
                        </div>
                    </div>
                </div>
            </div>
        )
        :
        (
            <div className='col-12'>
                <Loading text='Loading...' />
            </div>
        );
};

export default DashboardHome;
