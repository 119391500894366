import { api } from '../config.js';

/**
 * Get base API URL
 * @param {string} version
 * @returns {string}
 */
export function getAPIUrl(version = 'v1')
{
	return `http${api.port === 80 ? '' : 's'}://${api.hostname}:${api.port}/${version}/`;
}

/**
 * Get captcha link
 * @param timestamp {number}
 * @returns {string}
 */
export function getCaptchaLink(timestamp)
{
	return new URL(`captcha?t=${timestamp}`, getAPIUrl()).href;
}

/**
 * Download copier EA
 * @param subscriptionId {number}
 * @param version {4|5}
 * @returns {string}
 */
export function getCopierEADownloadLink(subscriptionId, version)
{
	return new URL(`subscriptions/${subscriptionId}/expert_advisor?mt_version=${version}`, getAPIUrl()).href;
}

/**
 * Download trader EA
 * @param traderId {number}
 * @param version {4|5}
 * @returns {string}
 */
export function getTraderEADownloadLink(traderId, version)
{
	return new URL(`profile/traders/${traderId}/expert_advisor?mt_version=${version}`, getAPIUrl()).href;
}

/**
 * Get URL of account's profile image
 * @return {string}
 */
export function getProfileImageLink()
{
	return new URL(`profile/image?${(new Date()).getTime()}`, getAPIUrl()).href;
}
