import SubscriptionPageTypes from './subscription-page.types';

/**
 *
 * @type {{subscriptionId: number}}
 */
const INITIAL_STATE = {
    subscriptionId: 0,
    subscriptionSettingsId: 0
};

/**
 * Cart reducer
 * @param state
 * @param action
 * @returns {{subscriptionId: number}}
 * @constructor
 */
const SubscriptionPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SubscriptionPageTypes.SET_SUBSCRIPTION_ID:
            return {
                subscriptionId: action.payload,
                subscriptionSettingsId: 0
            };
        case SubscriptionPageTypes.UNSET_SUBSCRIPTION_ID:
            return INITIAL_STATE;
        case SubscriptionPageTypes.SET_SUBSCRIPTION_SETTINGS_ID:
            return {
                subscriptionId: 0,
                subscriptionSettingsId: action.payload
            };
        case SubscriptionPageTypes.UNSET_SUBSCRIPTION_SETTINGS_ID:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default SubscriptionPageReducer;
