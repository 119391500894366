export default class BasicUserInfo {
    /**
     * Represents user info
     * @param {number} id
     * @param {string} email
     * @param {string} name
     */
    constructor(id, email, name) {
        this.id = id;
        this.email = email;
        this.name = name;
    }

    /**
     * Clones the given object to an instance of AccountInfo
     * @param {Object} obj
     * @returns {BasicUserInfo|null}
     */
    static clone(obj) {
        if (!obj)
            return null;
        return new BasicUserInfo(obj.id, obj.email, obj.name);
    }

    /**
     * Creates instance of AccountInfo given account details & device info
     * @param {Object} obj
     * @returns {BasicUserInfo|null}
     */
    static createInstance(obj) {
        if (!obj)
            return null;
        return new BasicUserInfo(obj.id, obj.email, obj.name);
    }

    /**
     * Get initials of a given name
     * @param {string} fullName
     * @returns {string}
     * @private
     */
    _getInitials(fullName) {
        const allNames = fullName.trim().split(' ');
        return allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
    }

    /**
     * Get short name aka initials of a full name
     * @returns {string}
     */
    get short_name()
    {
        return this._getInitials(this.name);
    }
}