import {CircleLoader} from 'react-spinners';
import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({text, hasError}) => {
    return <div className="w-100 h-100 d-flex justify-content-center align-items-center"
                style={{backgroundColor: "#00000000"}}>
        <div>
            <div className="d-flex justify-content-center">
                {
                    hasError ?
                        <img height={50} width={50} src='/assets/icons/error.svg' alt='Error'/>
                        :
                        <CircleLoader loading={true} color="#c0c3cb"/>
                }
            </div>
            <div className="d-flex justify-content-center">{text}</div>
        </div>
    </div>
};

Loading.propTypes = {
    text        : PropTypes.string.isRequired,
    hasError    : PropTypes.bool
}

export default Loading;
