import './cart.styles.scss';

import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MDBBadge, MDBBtn, MDBIcon, MDBSpinner, MDBTooltip } from 'mdb-react-ui-kit';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import AOS from 'aos';

import { payment_options } from '../../config';
import { buySubscription } from '../../libs/client/SubscriptionManager';
import { selectUserAccount } from '../../redux/core/user/user.selectors';
import { selectCart } from '../../redux/core/cart/cart.selectors';
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import { validateBinaryToken } from '../../libs/client/BrokerManager';

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(selectUserAccount);
    const cart = useSelector(selectCart);

    const selectedSubscription = cart.items[0];

    const [mtAccountNumberElem, setMtAccountNumberElem] = useState('');
    const [binaryTokenElem, setBinaryTokenElem] = useState('');

    const config = {
        ...payment_options.flutterwave,
        tx_ref          : `BD_${selectedSubscription.id}_${mtAccountNumberElem}_${Date.now()}`,
        amount          : selectedSubscription.price_discounted,
        meta            : {
            consumer_id : user.id,
            mt_account  : mtAccountNumberElem,
        },
        customer        : {
            email       : user.email,
            // phonenumber : user.mobile,
            name        : user.name,
        },
        customizations  : {
            title: 'Buy Subscription',
            description: selectedSubscription.name,
            logo: `${window.location.origin}/android-chrome-192x192.png`,
        }
    };
    const handleFlutterPayment = useFlutterwave(config);

    const tooltipImgNavigatorSrc = 'assets/img/help/find-mt-account-number/navigator.png';
    const tooltipImgTitleSrc = 'assets/img/help/find-mt-account-number/title.png';

    /**
     * Send notification
     * @param type {'info'|'success'|'error'|'warning'}
     * @param text
     * @returns {{payload: NotificationData, type: string}}
     */
    const sendNotification = (type, text) => dispatch(addNotification(type, text));

    const onMetaTraderAccountNumberChange = (e) => {
        const accountNumber = parseInt(e.currentTarget.value);
        setMtAccountNumberElem( accountNumber ? accountNumber.toString() : '');
    };
    const onAPITokenChange = (e) => {
        if (/^[A-Za-z0-9]+$/.test(e.currentTarget.value) || e.currentTarget.value === '')
        {
            setBinaryTokenElem(e.currentTarget.value);
            if (paymentAllowed)
            {
                setPaymentAllowed(false);
            }
            if (binaryTokenReady)
            {
                setBinaryTokenReady(false);
            }
        }
    };
    
    const [paymentAllowed, setPaymentAllowed] = useState(false);
    const [binaryTokenReady, setBinaryTokenReady] = useState(null);
    const [binaryTokenInvalid, setBinaryTokenInvalid] = useState(null);
    let buyParams;
    if (selectedSubscription.type.endsWith('_mt') && mtAccountNumberElem.length >= 5)
    {
        setPaymentAllowed(true)
        buyParams = { mt_account_number: Number(mtAccountNumberElem) };
    }
    else if (selectedSubscription.type.endsWith('_binary') && binaryTokenElem.length >= 15)
    {
        if (!binaryTokenReady)
        {
            setBinaryTokenReady(true);
        }
        buyParams = { binary_token: binaryTokenElem };
    }
    
    const [binaryTokenVerificationInProgress, setBinaryTokenVerificationInProgress] = useState(false);
    
    useEffect(() =>
    {
        if (selectedSubscription.type.endsWith('_binary') && binaryTokenReady)
        {
            const controller = new AbortController();
    
            setBinaryTokenInvalid(false);
            setBinaryTokenVerificationInProgress(true);
            validateBinaryToken(binaryTokenElem, controller.signal)
                .then((verified) =>
                {
                    setPaymentAllowed(verified);
    
                    setBinaryTokenInvalid(!verified);
                    
                    setBinaryTokenVerificationInProgress(false);
                })
                .catch(() =>
                {
                    setBinaryTokenInvalid(true)
                    if (paymentAllowed)
                    {
                        setPaymentAllowed(false);
                    }
                    setBinaryTokenVerificationInProgress(false);
                });
            
            return function onCancel() {
                controller.abort();
            };
        }
    }, [selectedSubscription.type, binaryTokenReady, binaryTokenElem, paymentAllowed]);
    
    useEffect(
        () =>{
            // Preloading tooltip images...
            if (selectedSubscription.type.endsWith('_mt'))
            {
                const img = new Image();
                img.src = tooltipImgNavigatorSrc;
                const img2 = new Image();
                img2.src = tooltipImgTitleSrc;
            }

            AOS.init();
        },
        [ selectedSubscription.type ]
    );
    
    const [termsAccepted, setTermsAccepted] = useState(false);
    const onTermsOfService = (e) =>
    {
        if (paymentAllowed)
        {
            window.open('/terms-of-service', '_blank');
            e.preventDefault(); // Prevent change of checkbox checked state.
        }
    };
    const onPrivacyPolicy = (e) =>
    {
        if (paymentAllowed)
        {
            window.open('/privacy-policy', '_blank');
            e.preventDefault(); // Prevent change of checkbox checked state.
        }
    };

    return (
        <>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>Cart</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-login cart section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-10 offset-lg-1 col-md-12 offset-md-0 col-xl-8 offset-xl-2 col-12'>
                            <div className='card login-form inner-content' data-aos='fade-up' data-aos-delay='500' data-aos-once='true'>
                                <div className='card-body'>
                                    <div className='title'>
                                        <h3>Cart Info</h3>
                                    </div>
                                    <div className='input-head'>
                                        <div className='mb-3'>
                                            <h3 className='mb-0'>{ selectedSubscription.name }</h3>
                                        </div>
                                        <div className='mb-3'>
                                            <h5 className='mb-0'>{ selectedSubscription.price_discounted } $</h5>
                                        </div>
                                        <div className='form-group input-group'>
                                            <label>
                                                <i className='fas fa-id-card' />
                                            </label>
                                            {
                                                selectedSubscription.type === 'copier_mt'
                                                ?
                                                <>
                                                    <input
                                                        className='form-control'
                                                        type='text'
                                                        placeholder='MetaTrader Account #'
                                                        value={ mtAccountNumberElem }
                                                        onChange={ onMetaTraderAccountNumberChange }
                                                        required/>
                                                    <MDBTooltip tag='div'
                                                                wrapperProps={ { } }
                                                                wrapperClass='tooltip-wrapper'
                                                                placement={ user.device.platform === 'mobile' ? 'bottom' : 'right' }
                                                                className='tooltip-mt-account-number'
                                                                title={
                                                                <>
                                                                This is the trading account number that your broker has assigned to you for your MT4 or MT5 terminal.
                                                                <br/>
                                                                You can find your account number using one of the following methods:
                                                                <br/>
                                                                <br/>
                                                                1. Through your broker's website.
                                                                <br/>
                                                                2. Inside your MetaTrader 4/5 terminal in the “Accounts” section of the Navigator window.
                                                                <br/>
                                                                Screenshot:
                                                                <br/>
                                                                <img src={ tooltipImgNavigatorSrc } alt='Find MT Account # from navigator window'/>
                                                                <br/>
                                                                3. From the title bar of the MetaTrader 4/5 terminal.
                                                                <br/>
                                                                Screenshot:
                                                                <br/>
                                                                <img src={ tooltipImgTitleSrc } alt="Find MT Account # from MT's title bar"/>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                Additional help: <a href='https://www.forexrobottrader.com/support/pdf/help-find-account.pdf'>How to find MT Account Number</a>
                                                                </>
                                                            }>
                                                        <MDBIcon fas className='tooltip-icon ms-2 flex-shrink-0 p-1' icon='info-circle' />
                                                    </MDBTooltip>
                                                </>
                                                : selectedSubscription.type === 'copier_binary'
                                                    ?
                                                    <>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            placeholder='Your binary.com/deriv.com API token'
                                                            value={ binaryTokenElem }
                                                            onChange={ onAPITokenChange }
                                                            required/>
                                                        <MDBTooltip tag='div'
                                                                    wrapperProps={ { } }
                                                                    wrapperClass='tooltip-wrapper'
                                                                    placement={ user.device.platform === 'mobile' ? 'bottom' : 'right' }
                                                                    className='tooltip-binary-token'
                                                                    title={
                                                                    <>
                                                                    This is the API token of your binary.com/deriv.com account with
                                                                        <MDBBadge className='trader-account-type ms-2' color='dark'>Read</MDBBadge>,
                                                                        <MDBBadge className='trader-account-type ms-2' color='dark'>Trade</MDBBadge>, and
                                                                        <MDBBadge className='trader-account-type ms-2' color='dark'>Trading information</MDBBadge> scopes.
                                                                    <br/>
                                                                    <br/>
                                                                    For more information please visit our tutorials <a href='/tutorials#binaryToken' target='_blank'>page</a> <MDBIcon fas icon="external-link-alt" />.
                                                                    </>
                                                                }>
                                                            <MDBIcon fas className='tooltip-icon ms-2 flex-shrink-0 p-1' icon='info-circle' />
                                                        </MDBTooltip>
                                                    </>
                                                    : 'Unsupported subscription type!'
                                            }
                                        </div>
                                        {
                                            !binaryTokenInvalid ||
                                            (
                                                <div className='form-label'>
                                                                    <span className="text-danger">
                                                                        Invalid token. Please make sure you have correctly typed in your token
                                                                        and the token has all the required scopes (read, trade, and trading_information).
                                                                    </span>
                                                </div>
                                            )
                                        }
                                        <div className='form-group input-group'>
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="defaultUnchecked"
                                                       disabled={!paymentAllowed} checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)}/>
                                                <label className="custom-control-label" htmlFor="defaultUnchecked">
                                                    I read and accept your <span className='link-button' onClick={onTermsOfService}>Terms of Service <MDBIcon fas icon="external-link-alt" /></span>
                                                    , and <span className='link-button link-button-disabled' onClick={onPrivacyPolicy}>Privacy Policy <MDBIcon fas icon="external-link-alt" /></span>.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='button'>
                                        <MDBBtn
                                            color='binarydew'
                                            type='submit'
                                            disabled={!termsAccepted}
                                            onClick={
                                                () => {
                                                    handleFlutterPayment({
                                                        callback: (response) => {
                                                            buySubscription(selectedSubscription.id, buyParams, response)
                                                                .then((subscription) =>
                                                                {
                                                                    navigate(`/payment/${subscription.id}`, { state: { subscription } })
                                                                })
                                                                .catch((err) =>
                                                                {
                                                                    sendNotification('error', err.message);
                                                                })
                                                            closePaymentModal() // this will close the modal programmatically
                                                        },
                                                        onClose: () => {},
                                                    });
                                                }
                                            }
                                        >
                                            {
                                                binaryTokenVerificationInProgress ?
                                                    <>
                                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                                        Checking token...
                                                    </>
                                                    :
                                                    'Payment'
                                            }
                                        </MDBBtn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Cart;
