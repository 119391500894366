const env = process.env['NODE_ENV'] || 'development';
const apiHostname = 'api.binarydew.com';

module.exports = {
    env,
    api: {
        hostname    : apiHostname || window.location.hostname,
        port        : 443
    },
    auth: {
        google: {
            clientID: '167788403778-t2p6sg04r6dkfpar3222tsjdqfkuookl.apps.googleusercontent.com'
        }
    },
    payment_options : {
        flutterwave : {
            public_key      : 'FLWPUBK_TEST-a3f06a9e536293fbce148bfc35bdc504-X',
            currency        : 'USD',
            payment_options : 'card,mobilemoney,ussd'
        }
    },
    subscription_settings : {
        stake_size: {
            min: 0.35,
            max: 1000,
            step: 0.01,
        },
        martingale_multiplier: {
            min: 1,
            max: 10,
            step: 0.1,
            marks: {
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                6: 6,
                7: 7,
                8: 8,
                9: 9,
                10: 10,
            },
            risks: {
                lowest_risk: {
                    title: 'Lowest Risk',
                    min: 1,
                    max: 1,
                    color: 'green'
                },
                risky: {
                    title: 'Risky',
                    min: 1.1,
                    max: 5,
                    color: 'gold'
                },
                high_risk: {
                    title: 'High Risk',
                    min: 5.1,
                    max: 9.9,
                    color: 'orange'
                },
                highest_risk: {
                    title: 'Highest Risk',
                    min: 10,
                    max: 10,
                    color: 'red'
                },
            }
        }
    }
}
