import './payment.styles.scss';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MDBBtn, MDBIcon, MDBSpinner } from 'mdb-react-ui-kit';
import AOS from 'aos';

import { getCopierEADownloadLink } from '../../libs/urlFactory';
import { getCopierEAStatus, getPrices, getSubscription } from '../../libs/client/SubscriptionManager';
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import Loading from '../../components/loading/loading.component';
import SecretCopyText from "../../components/secret-copy-text/secret-copy-text.component";

const Payment = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    
    const [subscription, setSubscription] = useState(location?.state?.subscription || props?.location?.state?.subscription);
    const subscriptionId = Number(params?.subscriptionId);
    
    console.log(subscription);
    if (!subscription && !subscriptionId)
    {
        // Nothing to show here!
        navigate('/pricing');
    }
    
    useEffect(
        () =>{
            AOS.init();
        },
        []
    );
    
    const [plan, setPlan] = useState(undefined);
    
    useEffect(
        () => {
            /**
             * Send notification
             * @param type {'info'|'success'|'error'|'warning'}
             * @param text
             * @returns {{payload: NotificationData, type: string}}
             */
            const sendNotification = (type, text) => dispatch(addNotification(type, text));
            
            if (!subscription)
            {
                getPrices()
                    .then((prices) =>
                    {
                        getSubscription(subscriptionId)
                            .then((subObj) =>
                            {
                                setPlan(prices.find((p) => p.id === subObj.type_id));
                                setSubscription(subObj);
                            })
                            .catch((err) =>
                            {
                                sendNotification('error', err.message);
                            })
                    })
                    .catch((err) =>
                    {
                        sendNotification('error', err.message);
                    })
            }
            else
            {
                getPrices()
                    .then((prices) =>
                    {
                        setPlan(prices.find((p) => p.id === subscription.type_id));
                    })
                    .catch((err) =>
                    {
                        sendNotification('error', err.message);
                    })
            }
        },
        [subscription, subscriptionId, navigate, dispatch]
    );
    
    /**
     *
     * @type {'ready'|'not-ready'|'failed'}
     */
    const initialStatus = 'not-ready';
    const [mt4Status, setMt4Status] = useState(initialStatus);
    const [mt5Status, setMt5Status] = useState(initialStatus);
    
    useEffect(
        () =>{
            const getStatus = () => getCopierEAStatus(subscriptionId)
                .then((status) =>
                {
                    let done = true;
                    
                    if (status.mt4 !== 'not-ready')
                    {
                        setMt4Status(status.mt4);
                    }
                    else
                    {
                        done = false;
                    }
                    if (status.mt5 !== 'not-ready')
                    {
                        setMt5Status(status.mt5);
                    }
                    else
                    {
                        done = false;
                    }
                    
                    if (!done)
                    {
                        setTimeout(getStatus, 5000);
                    }
                })
                .catch(() => { });
            
            if (plan?.type.endsWith('_mt'))
            {
                setTimeout(getStatus, 5000);
            }
        },
        [ subscriptionId, plan ]
    );

    return !subscription ?
        <Loading text='Loading...' />
        :
        <>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>Payment Result</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-login section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12'>
                            <div className='card login-form inner-content' data-aos='fade-up' data-aos-delay='500' data-aos-once='true'>
                                <div className='card-body'>
                                    <div className='title'>
                                        <h3>Payment was successful</h3>
                                    </div>
                                    <div className='input-head'>
                                        {
                                            plan?.type.endsWith('_binary') ?
                                                <>
                                                    <div className='col-12 mb-4 d-flex align-items-center'>
                                                        <span className='payment-item-title flex-shrink-0'>Your Binary.com/Deriv.com token</span>
                                                        <div className='flex-grow-1'>
                                                            <SecretCopyText hidden={ true } showBtn={ true }
                                                                            colorClass='success'
                                                                            className='m-0 py-2 ps-3 pe-2'
                                                                            text={ subscription.binary_token }/>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mb-4 d-flex align-items-center'>
                                                        We will automatically open contracts for the account associated with this token.
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='col-12 mb-4 d-flex align-items-center'>
                                                        <span className='payment-item-title flex-shrink-0'>Secret Key</span>
                                                        <div className='flex-grow-1'>
                                                            <SecretCopyText hidden={ false } showBtn={ false }
                                                                            colorClass='success'
                                                                            className='m-0 py-2 ps-3 pe-2'
                                                                            text={ subscription.mt_secret_key }/>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 mb-4 d-flex align-items-center'>
                                                        <span className='payment-item-title'>Download EA mt4</span>
                                                        <MDBBtn tag='a'
                                                                floating={ mt4Status === 'ready' }
                                                                disabled={ mt4Status !== 'ready' }
                                                                color={ mt4Status === 'ready' ? 'success' : mt4Status ===
                                                                                                            'failed' ? 'danger' : 'warning' }
                                                                href={ getCopierEADownloadLink(subscription.id, 4) }
                                                                target='_blank'>
                                                            {
                                                                mt4Status === 'not-ready'
                                                                    ? <>
                                                                        <MDBSpinner size='sm' role='status' tag='span'
                                                                                    className='me-2'/>
                                                                        Compiling...
                                                                    </>
                                                                    : mt4Status === 'ready'
                                                                        ? <MDBIcon fas icon='download'/>
                                                                        : 'Compilation failed! Please contact support team'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                    <div className='col-12 d-flex align-items-center'>
                                                        <span className='payment-item-title'>Download EA mt5</span>
                                                        <MDBBtn tag='a'
                                                                floating={ mt5Status === 'ready' }
                                                                disabled={ mt5Status !== 'ready' }
                                                                color={ mt5Status === 'ready' ? 'success' : mt5Status ===
                                                                                                            'failed' ? 'danger' : 'warning' }
                                                                href={ getCopierEADownloadLink(subscription.id, 5) }
                                                                target='_blank'>
                                                            {
                                                                mt5Status === 'not-ready'
                                                                    ? <>
                                                                        <MDBSpinner size='sm' role='status' tag='span'
                                                                                    className='me-2'/>
                                                                        Compiling...
                                                                    </>
                                                                    : mt5Status === 'ready'
                                                                        ? <MDBIcon fas icon='download'/>
                                                                        : 'Compilation failed! Please contact support team'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        ;
};

export default Payment;
