import './subscriptions.styles.scss';

import React, { useEffect, useState } from 'react';
import AOS from 'aos';

import { getPrices, getSubscriptions } from '../../libs/client/SubscriptionManager';
import SubscriptionItem from '../../components/subscription-item/subscription-item.component';
import SubscriptionRow from "../subscription-row/subscription-row.component";
import { useSelector } from 'react-redux';
import { selectSubscriptionId } from '../../redux/subscription-page/subscription-page.selectors';

const Subscriptions = () => {
    const [pricesList, setPricesList] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [viewStyle, setViewStyle] = useState('table');
    const subscriptionId = useSelector(selectSubscriptionId);

    useEffect(
        () => {
            getPrices()
                .then(
                    prices => {
                        setPricesList(prices);
                        getSubscriptions()
                            .then(
                                subscriptions => {
                                    setSubscriptions(subscriptions);
                                    AOS.init();
                                }
                            )
                            .catch(
                                err => {
                                    console.log(err.message);
                                }
                            );
                    }
                )
                .catch(
                    err => {
                        console.log(err.message);
                    }
                );
        }
        , [subscriptionId /* Force re-render on subscription detail's modal box close */]
    );

    return (
        <>
            <div className='row mb-5' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>
                {
                    subscriptions.length > 0 ?
                        <>
                            <div className='col-12 mb-4 d-flex justify-content-center'>
                                <div className='btn-group' role='group' aria-label='Basic example'>
                                    <button
                                        type='button'
                                        className={'btn btn-white px-3 py-2' + (viewStyle === 'table' ? ' active' : '')}
                                        onClick={
                                            () => {
                                                setViewStyle('table');
                                            }
                                        }
                                    >
                                        <i className='fas fa-2x fa-th' />
                                    </button>
                                    <button
                                        type='button'
                                        className={'btn btn-white px-3 py-2' + (viewStyle === 'grid' ? ' active' : '')}
                                        onClick={
                                            () => {
                                                setViewStyle('grid');
                                            }
                                        }
                                    >
                                        <i className='fas fa-2x fa-grip-horizontal' />
                                    </button>
                                </div>
                            </div>
                            {
                                viewStyle === 'grid' ?
                                    subscriptions.map(subscription => (
                                        <div className='col-12 col-sm-6 col-md-4 col-lg-3 mb-4' key={subscription.id} data-aos='fade-up' data-aos-delay='200'>
                                            <SubscriptionItem subscription={subscription} plan={pricesList.find(p => p.id === subscription.type_id)} />
                                        </div>
                                    ))
                                    :
                                    (
                                        <div className='col-12'>
                                            <table className='table table-bordered table-striped w-100' data-aos='fade-up' data-aos-delay='200'>
                                                <thead>
                                                <tr className='bg-dark text-white'>
                                                    <th className='text-center fw-bold p-1 p-md-3'>Name</th>
                                                    <th className='text-center fw-bold p-1 p-md-3'>Pricing Plan</th>
                                                    <th className='text-center fw-bold p-1 p-md-3'>Fee</th>
                                                    <th className='text-center fw-bold d-none d-md-table-cell p-1 p-md-3'>Duration</th>
                                                    <th className='text-center fw-bold p-1 p-md-3'>Expired</th>
                                                    <th className='text-center fw-bold p-1 p-md-3'>Actions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    subscriptions.map(subscription => (
                                                        <SubscriptionRow subscription={subscription} plan={pricesList.find(p => p.id === subscription.type_id)} />
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                            }
                        </>
                        :
                        <div>
                            You have not bought a subscription yet. Why do you hesitate to earn money?!
                            <br/>
                            Visit our <a href='/pricing'>pricing page</a> to start profiting right away.
                        </div>
                }
            </div>
        </>
    );
};

export default Subscriptions;
