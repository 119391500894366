import React, { Component } from 'react';
import { connect } from 'react-redux';
import {removeNotification} from "../../redux/core/notifications/notifications.actions";
import * as appPropTypes from '../app-prop-types';
import PropTypes from 'prop-types';

import './notification.styles.scss';

class NotificationItem extends Component {

    componentDidMount() {
        setTimeout(
            () => { this.props.removeNotification(this.props.notification.id) },
            this.props.notification.duration
        );
    }

    render() {
        return (
            <div className={'notification-item ' + this.props.notification.type}>
                <div className='notification-text'>
                    {this.props.notification.text}
                </div>
                <div className='notification-close' onClick={
                    () => { this.props.removeNotification(this.props.notification.id) }
                }>
                    <img src='/assets/icons/close-notification.svg' alt='Close notification' />
                </div>
            </div>
        );
    }
}

NotificationItem.propTypes = {
    notification        : appPropTypes.NotificationType.isRequired,
    removeNotification  : PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    removeNotification : notification_id => dispatch(removeNotification(notification_id))
});

export default connect(null, mapDispatchToProps)(NotificationItem);
