import './register.styles.scss';

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import GoogleLogin from 'react-google-login';

import { auth } from '../../config';
import { accountLoginExternal, accountSignup } from '../../libs/client/AuthManager';
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import { selectUserLoginInProgress } from '../../redux/core/user/user.selectors';
import {toEnDigits} from '../../libs/utils';
import {getCaptchaLink} from '../../libs/urlFactory';
import AOS from 'aos';
import { ERROR_CODES_ENUM } from '../../libs/errors/ErrorHandlers';
import { MDBBadge } from 'mdb-react-ui-kit';

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const sendNotification = (type, text) => dispatch(addNotification(type, text));
    const [captchaState, setCaptchaState] = useState(true);
    const [captchaTimestampState, setCaptchaTimestampState] = useState(new Date().getTime());
    const userLoginInProgress = useSelector(selectUserLoginInProgress);

    const handleGoogleFailure = (result) => {
        sendNotification('error', result.details || result.error || 'Unknown Google error. Please contact support team.');
    };

    const handleGoogleLogin = async (googleData) => {
        accountLoginExternal('google', googleData.tokenId, dispatch)
            .then(account => {
                sendNotification('success', `Welcome ${account.name}`);

                const destination = location.state ?
                    // Redirect to the page that we are coming from
                    location.state.from
                    :
                    // Redirect to the user's dashboard
                    '/dashboard';

                navigate(destination);
            })
            .catch(err => {
                sendNotification('error', err.message);
            });
    };

    const [ signupComplete, setSignupComplete ] = useState(undefined);
    
    const onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        accountSignup(formData.get('email'), formData.get('name'), formData.get('captcha'), dispatch)
            .then(result => {
                setSignupComplete(formData.get('email'));
                sendNotification('success', `${result.message}`);
            })
            .catch(err => {
                if (err?.code === ERROR_CODES_ENUM.USER_CAPTCHA_REQUIRED)
                {
                    setCaptchaState(true);
                }
                setCaptchaTimestampState(new Date().getTime());
                sendNotification('error', err.message);
            });
    };

    useEffect(
        () => {
            AOS.init();
        },
        []
    );

    return (
        <>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>Register</h1>
                                {/*<ul className='breadcrumb-nav'>
                                    <li className='pe-0 me-0'><Link to='/'>Home</Link></li>
                                    <i className='fas fa-angle-right text-white mx-2' />
                                    <li>Register</li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-login section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12'>
                            {
                                !signupComplete
                                    ?
                                    <form className='card login-form inner-content' onSubmit={onFormSubmit}  data-aos='fade-up' data-aos-delay='500' data-aos-once='true'>
                                        <div className='card-body'>
                                            <div className='title'>
                                                <h3>Register Now</h3>
                                            </div>
                                            <div className='alt-option'>
                                                <GoogleLogin
                                                    clientId={auth.google.clientID}
                                                    buttonText='Google'
                                                    onSuccess={handleGoogleLogin}
                                                    onFailure={handleGoogleFailure}
                                                    cookiePolicy={'single_host_origin'}
                                                    render={renderProps => (
                                                        <button className="option-button bg-transparent" type='button' onClick={renderProps.onClick} disabled={userLoginInProgress ? 'disabled' : null}>
                                                            <img src="/images/account-login/google.png" alt="Google register" />Register with Google
                                                        </button>
                                                    )}
                                                />
                                            </div>
                                            <div className='or'><span>Or</span></div>
                                            <div className='input-head'>
                                                <div className='form-group input-group'>
                                                    <label>
                                                        <i className='fas fa-user' />
                                                    </label>
                                                    <input className='form-control' type='text' name='name' minLength='3' maxLength='30' placeholder='Full Name' required />
                                                </div>
                                                <div className='form-group input-group'>
                                                    <label>
                                                        <i className='fas fa-envelope' />
                                                    </label>
                                                    <input className='form-control' type='text' name='email' placeholder='Email' required />
                                                </div>
                                                {
                                                    captchaState ?
                                                        <>
                                                            <div className='d-flex justify-content-between mb-3'>
                                                                <img className='captcha-img' src={getCaptchaLink(captchaTimestampState)} alt='Captcha' />
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-info btn-rounded'
                                                                    onClick={
                                                                        () => {
                                                                            setCaptchaTimestampState(new Date().getTime());
                                                                        }
                                                                    }
                                                                >
                                                                    <i className='fas fa-2x fa-refresh' />
                                                                </button>
                                                            </div>
                                                            <div className='form-group input-group'>
                                                                <label>
                                                                    <i className='fas fa-shield' />
                                                                </label>
                                                                <input
                                                                    className='form-control'
                                                                    type='text'
                                                                    name='captcha'
                                                                    placeholder='Captcha'
                                                                    minLength='5'
                                                                    maxLength='5'
                                                                    onChange={
                                                                        (e) => {
                                                                            e.currentTarget.value = toEnDigits(e.currentTarget.value);
                                                                        }
                                                                    }
                                                                    required
                                                                />
                                                            </div></>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className='button'>
                                                <button className='btn' type='submit'>Register</button>
                                            </div>
                                            <h4 className='create-account'>Already have an account? <Link to='/login'>Log in</Link>
                                            </h4>
                                        </div>
                                    </form>
                                    :
                                    <div className='container'>
                                        <div className='row pb-4'>
                                            <div className='text-center w-100'>
                                                <h3><MDBBadge color='success'>Registration successful</MDBBadge></h3>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <p>
                                                Please check your inbox of the following email address:
                                                <span className='text-center w-100'><MDBBadge color='primary'>{ `${ signupComplete }` }</MDBBadge></span>
                                            </p>
                                            <p className='text-justify'>
                                                You will receive an email address containing your login credentials.
                                                You can then use the <a href='/login'>login</a> page to sign in to the system.
                                            </p>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
