import {ERROR_CODES_ENUM} from './ErrorHandlers';

export class Exception extends Error {
    /**
     *
     * @param {number} code
     * @param {string} message
     * @param {Error?} error
     * @param {boolean?} isCritical
     */
    constructor(code, message, error, isCritical) {
        super(message);
        this._code = code;
        this.innerError = error;
        this._isCritical =
            this.innerError != null ||
            isCritical ||
            code >= ERROR_CODES_ENUM.SYSTEM_UNKNOWN_ERROR ||
            false;
    }

    get code() {
        return this._code;
    }

    get isCritical() {
        return this._isCritical;
    }

    get getInnerException() {
        return this.innerError;
    }
}