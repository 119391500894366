import UserTypes from './user.types';

/**
 * Initial state of user
 * @type {
 *  {
 *      loginInProgress: boolean,
 *      account: AccountInfo|null,
 *  }
 * }
 */
const INITIAL_STATE = {
    loginInProgress         : false,
    /**
     * Holds account details that are always coming from server by
     * decoding JWT token.
     * If @account is null, no user is logged in.
     * @type {AccountInfo|null}
     */
    account             : null
};

/**
 *
 * @param state
 * @param action
 * @returns {
 *  {
 *      loginInProgress: boolean,
 *      account: AccountInfo|null,
 *  }
 * }
 * @constructor
 */
const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserTypes.LOGIN_USER:
        {
            const account = action.payload;

            return {
                ...state,
                account
            };
        }
        case UserTypes.LOGOUT_USER:
            return {
                ...state,
                account: null,
            };
        case UserTypes.SET_LOGIN_IN_PROGRESS:
        {
            const flag = action.payload;

            return { ...state, loginInProgress: flag };
        }
        default:
            return state;
    }
};

export default UserReducer;
