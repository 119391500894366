import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { loginCheck } from '../libs/client/AuthManager';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserAccount } from '../redux/core/user/user.selectors';
import Loading from './loading/loading.component';
import { addNotification } from '../redux/core/notifications/notifications.actions';
import PropTypes from 'prop-types';

const Authenticator = ({ needAuth }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(selectUserAccount);
	const [verificationState, setVerificationState] = useState(null);
	const [loadingText, setLoadingText] = useState('Loading...');
	
	useEffect(
		() => {
			/**
			 * Send notification
			 * @param type {'info'|'success'|'error'|'warning'}
			 * @param text
			 * @returns {{payload: NotificationData, type: string}}
			 */
			const sendNotification = (type, text) => dispatch(addNotification(type, text));
			
			// Verify user's token validity
			loginCheck(dispatch)
				.then(_ => {
					setVerificationState(true);
				})
				.catch(err => {
					setLoadingText(err.message);
					// setErrorState(true);
					setVerificationState(false);
					if (needAuth)
					{
						sendNotification('error', err.message);
					}
				});
		},
		// Adding any prop in this deps array will force token's verification on its value change
		[needAuth, dispatch, location.pathname]
	);
	
	if (user && needAuth)
	{
		return <Outlet />;
	}
	else if (verificationState === null)
	{
		return <Loading text={loadingText} hasError={false} />
	}
	else if ((!verificationState || !user) && needAuth)
	{
		// User needs to log in
		return <Navigate to={'/login'} state={{ from: location }} />
	}
	else
	{
		return <Outlet />;
	}
	
	// return (
	// 	<Route {...rest} render={
	// 		props => {
	// 			if (verificationState === null)
	// 			{
	// 				return <Loading text={loadingText} hasError={false} />
	// 			}
	// 			else if (verificationState === false)
	// 			{
	// 				return <Loading text={loadingText} hasError={true} />
	// 			}
	// 			else if (!user)
	// 			{
	// 				// When we are in verified state but user is null,
	// 				// then most probably the user had logged out.
	// 				return <Navigate to={'/login'} state={{ from: props.location }} />
	// 			}
	// 			else
	// 			{
	// 				return <Component {...rest} {...props} />
	// 			}
	// 		}
	// 	} />
	// )
}
Authenticator.propTypes = {
	needAuth: PropTypes.bool.isRequired
};


export default Authenticator;
