import { createSelector } from 'reselect';

const selectUserObj = state => state.user;

export const selectUserAccount = createSelector(
    [selectUserObj],
    user => user.account
);

export const selectUserLoginInProgress = createSelector(
    [selectUserObj],
    user => user.loginInProgress
);
