import axios from 'axios';
import { getAPIUrl } from '../urlFactory';
import { apiErrorRethrow } from '../errors/ErrorHandlers';

/**
 * Check validity of a binary.com token
 * @param {string} token
 * @param {AbortSignal} cancelSignal
 * @returns {Promise<boolean>}
 */
export async function validateBinaryToken(token, cancelSignal) {
	const listURL = new URL(`brokers/binary/verify/${token}`, getAPIUrl()).href;
	try {
		const response = await axios.get(
			listURL,
			{
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
				},
				signal: cancelSignal
			});
		return response.data.verified;
	} catch (err) {
		apiErrorRethrow(err);
	}
}
