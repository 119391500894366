import NotificationsTypes from './notifications.types';

/**
 *
 * @type {{notifications: NotificationData[], next_id: number}}
 */
const INITIAL_STATE = {
    next_id: 1,
    notifications : []
};

/**
 * Notifications reducer.
 * @param state
 * @param action
 * @returns {{notifications: NotificationData[], next_id: number}}
 * @constructor
 */
const NotificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NotificationsTypes.ADD_NOTIFICATION:
            const notification = action.payload;

            notification.id = state.next_id;

            return {
                ...state,
                notifications: [...state.notifications, notification],
                next_id: state.next_id + 1
            };
        case NotificationsTypes.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id !== action.payload)
            }
        case NotificationsTypes.REMOVE_ALL_NOTIFICATIONS:
        {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
};

export default NotificationsReducer;
