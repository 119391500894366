import './footer.styles.scss';

import React from 'react';

const Footer = () => {
    return (
        <footer className="footer section">
            <div className="footer-top">
                <div className="container">
                    <div className="inner-content">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-footer f-about">
                                    <div className="logo">
                                        <a href="/">
                                            <img src="/images/logo/logo-light-web-sm.png" alt="#" />
                                        </a>
                                    </div>
                                    <p>Making the world a better place through making profit for everyone.</p>
                                    <h4 className="social-title">Follow Us On:</h4>
                                    <ul className="social p-0 m-0">
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/">
                                                <i className="fab fa-pinterest" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.youtube.com/channel/UCbJ4727FI-i3Q2F6hg7NFjQ" target='_blank' rel='noreferrer'>
                                                <i className="fab fa-youtube" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div className="single-footer f-link">
                                    <h3>Legal</h3>
                                    <ul>
                                        <li>
                                            <a href="/privacy-policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="/terms-of-service">Terms of Service</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-12">
                                <div className="single-footer f-link">
                                    <h3>Support</h3>
                                    <ul>
                                        <li>
                                            <a href="/pricing">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="/tutorials">Tutorials</a>
                                        </li>
                                        {/*<li>
                                            <a href="/">API Status</a>
                                        </li>*/}
                                        <li>
                                            <a href="/contact">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="single-footer newsletter">
                                    <h3>Subscribe</h3>
                                    <p>Subscribe to our newsletter for the latest updates</p>
                                    <form action="#" method="get" target="_blank" className="newsletter-form">
                                        <input name="EMAIL" placeholder="Email address" required="required" type="email" />
                                        <div className="button">
                                            <button className="sub-btn">
                                                <i className="far fa-envelope" />
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="inner-content">
                        <div className="row" >
                            <div className="col-lg-6 col-md-6 col-12">
                                <p className="copyright-text mb-0">© 2022 BinaryDew Inc - All Rights Reserved</p>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12" style={{opacity: 0.1}}>
                                <p className="copyright-owner mb-0">
                                    Designed and Developed by <a href="/" rel="nofollow" target="_blank">BinaryDew Inc.</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
