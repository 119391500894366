import './about-us.styles.scss';

import React, { useEffect } from 'react';
import AOS from 'aos';
import { MDBBadge, MDBIcon } from 'mdb-react-ui-kit';

const AboutUs = () =>
{
	
	useEffect(
		() =>
		{
			AOS.init();
		}
		, []
	);
	
	return (
		<div id="top" className='about-us'>
			<div className='breadcrumbs'>
				<div className='container'>
					<div className='row align-items-center'>
						<div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
							<div className='breadcrumbs-content'>
								<h1 className='page-title'>About Us</h1>
								<div className='text-success-2'>Our mission is to make the world a better place through making profit for everyone.</div>
							</div>
						</div>
					</div>
				</div>
				<div className='about-us-stats-container feature'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-4 col-md-4 col-6 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
								 data-aos-once='true'>
								<div className='about-us-stats-box stats-box-success'>
									<div className='tumb'>
										<MDBIcon fas icon='smile' className='fa-3x' />
									</div>
									<h4 className='text-title'># of winning trades</h4>
									<h3><MDBBadge color='success'>381</MDBBadge></h3>
									this month
								</div>
							</div>
							<div className='col-lg-4 col-md-4 col-6 d-none d-md-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
								 data-aos-once='true'>
								<div className='about-us-stats-box stats-box-primary'>
									<div className='tumb'>
										<MDBIcon fas icon='dollar-sign' className='fa-3x' />
									</div>
									<h4 className='text-title'>Total net profit</h4>
									<h3><MDBBadge color='primary'>456,031$</MDBBadge></h3>
									this month
								</div>
							</div>
							<div className='col-lg-4 col-md-4 col-6 d-flex justify-content-center' data-aos='flip-right' data-aos-delay='200' data-aos-duration='1000' data-aos-easing="ease-in-cubic"
								 data-aos-once='true'>
								<div className='about-us-stats-box stats-box-danger'>
									<div className='tumb'>
										<MDBIcon fas icon='frown' className='fa-3x' />
									</div>
									<h4 className='text-title'># of losing trades</h4>
									<h3><MDBBadge color='danger'>27</MDBBadge></h3>
									this month
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='feature section section-about-us mt-150'>
				<div className='container'>
					<div className='row'>
						<div className='col-12'>
							<div className='section-title'>
								<h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Who we are?</h3>
								<h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>BinaryDew, Inc.</h2>
								<p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
									We have gathered a team of trading experts with astonishing trading backgrounds and experiences.
									Our traders have come up with a novel strategy with over 80% win rate weekly. This team, with the
									help of our IT department, has prepared an AI-based robot which is presented to you by BinaryDew Inc.
								</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='section-title'>
								<h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>Why selling online?</h3>
								<h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Why Not?</h2>
								<p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
									Some people ask us if this strategy is that much profitable, why do you need to run this
									business at all? Go and enjoy it. But, dear folks, as our mission statement clearly states,
									we seek the prosperity for everyone and we see ourselves responsible toward the society. We,
									members of the BinaryDew, strongly believe that we have been given this opportunity to serve
									everyone living on this earth to have a better life.
									<br/>
									Additionally, to be honest and very precise, who hates more money? :-))
								</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='section-title'>
								<h3 data-aos='zoom-in' data-aos-delay='200' data-aos-once='true'>What is our secret?</h3>
								<h2 data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Our Trading Strategy</h2>
								<p data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
									One of the most valuable assets of our company after its tremendous members,
									is the automatic, robust, unique, autonomous, and glorious strategy that we have.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(AboutUs);
