import TraderAccountsPageTypes from './trader-accounts-page.types';

/**
 * USet account ID
 * @param {number} accountId
 * @returns {{payload: number, type: string}}
 */
export const setTraderAccount = (accountId) => {
    return {
        type   : TraderAccountsPageTypes.SET_TRADER_ACCOUNT,
        payload: accountId
    };
};

/**
 * Unset account ID
 * @returns {{type: string}}
 */
export const unsetTraderAccount = () => {
    return {
        type   : TraderAccountsPageTypes.UNSET_TRADER_ACCOUNT
    };
};
