import './dashboard.styles.scss';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, NavLink, useLocation } from 'react-router-dom';
import AOS from 'aos';

import DashboardHome from '../../components/dashboard-home/dashboard-home.component';
import Subscriptions from '../../components/subscriptions/subscriptions.component';
import TraderAccounts from '../../components/trader-accounts/trader-accounts.component';
import { selectUserAccount } from '../../redux/core/user/user.selectors';

const Dashboard = () => {
    const location = useLocation();

    const user = useSelector(selectUserAccount);

    let title;
    switch (location.pathname) {
        case '/dashboard/subscriptions':
            title = 'Subscriptions';
            break;
        case '/dashboard/trader-accounts':
            title = user.id < 1 ? 'Trader Accounts' : 'Dashboard';
            break;
        default:
            title = 'Dashboard';
            break;
    }

    useEffect(
        () => {
            AOS.init();
        }
        , []
    );

    return (
        <>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>{title}</h1>
                                <ul className="breadcrumb-nav">
                                    <li>
                                        <NavLink end to='/dashboard'>Dashboard</NavLink>
                                    </li>
                                    <li>
                                        <NavLink end to='/dashboard/subscriptions'>Subscriptions</NavLink>
                                    </li>
                                    {
                                        user.id < 1 ?
                                            <li>
                                                <NavLink end to='/dashboard/trader-accounts'>Trader Accounts</NavLink>
                                            </li>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section bg-white'>
                <div className='container'>
                    <Routes>
                        <Route exact path='/' element={<DashboardHome />} />
                        <Route exact path='/subscriptions' element={<Subscriptions />} />
                        {
                            user.id < 1 ?
                                <Route exact path='/trader-accounts' element={<TraderAccounts />} />
                                :
                                null
                        }
                    </Routes>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
