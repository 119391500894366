import './pricing.styles.scss';

import React, { useEffect, useState } from 'react';
import AOS from 'aos';

import { getPrices } from '../../libs/client/SubscriptionManager';
import PricingItem from '../../components/pricing-item/pricing-item.component';

const Pricing = () => {
    const [pricesList, setPricesList] = useState([]);

    useEffect(
        () => {
            getPrices()
                .then(
                    prices => {
                        //const mt = prices.filter(p => p.type === 'copier_mt');
                        const binary = prices.filter(p => p.type === 'copier_binary');
                        setPricesList([
                            binary.find(p => p.color === 'bronze'),
                            binary.find(p => p.color === 'gold'),
                            binary.find(p => p.color === 'silver'),
                        ]);
                        AOS.init();
                    }
                )
                .catch(
                    err => {
                        console.log(err.message);
                    }
                );
        }
        , []
    );

    return (
        <>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>Pricing</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section bg-white'>
                <div className='container'>
                    {/*<h2 className='text-center fw-bold mb-8' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>MetaTrader Pricing</h2>
                    <div className='row mb-5' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                        {
                            pricesList.mt.map(
                                p => (
                                    <div className='col-6 col-sm-4 col-lg-3 mt-4 mt-lg-0' key={p.id}>
                                        <PricingItem price={p} offRibbon={true} />
                                    </div>
                                )
                            )
                        }
                    </div>*/}
                    {/*<h2 className='text-center fw-bold mb-8' data-aos='fade-up' data-aos-delay='400' data-aos-once='true'>Binary.com/Deriv.com Pricing</h2>*/}
                    <div className='row g-0 mb-5 justify-content-center' data-aos='fade-up' data-aos-delay='600' data-aos-once='true'>
                        {
                            pricesList.map(
                                p => (
                                    <div className='col-12 col-sm-4 mt-4 mt-lg-0' key={p.id} style={{zIndex : p.color === 'gold' ? 1 : 0}}>
                                        <PricingItem price={p} offRibbon={true} />
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pricing;
