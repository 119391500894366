/**
 * Sleeps for given number of milliseconds
 * @param ms {number}
 * @returns {Promise<unknown>}
 */
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Convert all digits in text to english digits
 * @param text {string}
 * @returns {string}
 */
export const toEnDigits = (text) => {
	const pn = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
	const an = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
	const en = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	
	for (let i = 0 ; i < 10 ; i++) {
		text = text.replace(pn[i], en[i]).replace(an[i], en[i]);
	}
	
	return text;
}

/**
 * returns a random integer between the specified values.
 * The value is no lower than min (or the next integer greater than min if min isn't an integer),
 * and is less than (but not equal to) max.
 * @param min {number}
 * @param max {number}
 * @return {number}
 */
export const getRandomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
