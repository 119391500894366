import './login.styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import AOS from 'aos';

import { auth } from '../../config';
import { accountLogin, accountLoginExternal } from '../../libs/client/AuthManager';
import { ERROR_CODES_ENUM } from '../../libs/errors/ErrorHandlers';
import { getCaptchaLink } from '../../libs/urlFactory';
import { toEnDigits } from '../../libs/utils';
import { selectUserAccount, selectUserLoginInProgress } from '../../redux/core/user/user.selectors';
import { addNotification } from '../../redux/core/notifications/notifications.actions';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [captchaState, setCaptchaState] = useState(false);
    const [captchaTimestampState, setCaptchaTimestampState] = useState(new Date().getTime());

    const userLoginInProgress = useSelector(selectUserLoginInProgress);
    const user = useSelector(selectUserAccount);
    
    useEffect(
        () =>{
            if (user)
            {
                navigate('/dashboard');
            }
        },
        [ navigate, user ]
    );

    /**
     * Send notification
     * @param type {'info'|'success'|'error'|'warning'}
     * @param text
     * @returns {{payload: NotificationData, type: string}}
     */
    const sendNotification = (type, text) => dispatch(addNotification(type, text));

    const handleGoogleFailure = (result) => {
        sendNotification('error', result.details || result.error || 'Unknown Google error. Please contact support team.');
    };

    const handleGoogleLogin = async (googleData) => {
        accountLoginExternal('google', googleData.tokenId, dispatch)
            .then(account => {
                sendNotification('success', `Welcome ${account.name}`);
                
                const destination = location.state ?
                    // Redirect to the page that we are coming from
                    location.state.from
                    :
                    // Redirect to the user's dashboard
                    '/dashboard';

                navigate(destination);
            })
            .catch(err => {
                sendNotification('error', err.message);
            });
    };

    const onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        accountLogin(formData.get('email'), formData.get('password'), formData.get('captcha'), dispatch)
            .then(account => {
                sendNotification('success', `Welcome ${account.name}`);

                const destination = location.state ?
                    // Redirect to the page that we are coming from
                    location.state.from
                    :
                    // Redirect to the user's dashboard
                    '/dashboard';

                navigate(destination);
            })
            .catch(err => {
                if (err?.code === ERROR_CODES_ENUM.USER_CAPTCHA_REQUIRED)
                {
                    setCaptchaState(true);
                }
                setCaptchaTimestampState(new Date().getTime());
                sendNotification('error', err.message);
            });
        e.preventDefault();
    };

    useEffect(
        () =>{
            AOS.init();
        },
        []
    );

    return (
        <>
            <div className='breadcrumbs'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 offset-lg-3 col-md-12 col-12'>
                            <div className='breadcrumbs-content'>
                                <h1 className='page-title'>Log in</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-login section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12'>
                            <form className='card login-form inner-content' onSubmit={onFormSubmit} data-aos='fade-up' data-aos-delay='500' data-aos-once='true'>
                                <div className='card-body'>
                                    <div className='title'>
                                        <h3>Log In Now</h3>
                                    </div>
                                    <div className='alt-option'>
                                        <GoogleLogin
                                            clientId={auth.google.clientID}
                                            buttonText='Google'
                                            onSuccess={handleGoogleLogin}
                                            onFailure={handleGoogleFailure}
                                            cookiePolicy={'single_host_origin'}
                                            render={renderProps => (
                                                <button className="option-button bg-transparent" type='button' onClick={renderProps.onClick} disabled={userLoginInProgress ? 'disabled' : null}>
                                                    <img src="/images/account-login/google.png" alt="Google log in" />Log in with Google
                                                </button>
                                            )}
                                        />
                                    </div>
                                    <div className='or'><span>Or</span></div>
                                    <div className='input-head'>
                                        <div className='form-group input-group'>
                                            <label>
                                                <i className='fas fa-envelope' />
                                            </label>
                                            <input className='form-control' type='email' name='email' placeholder='Enter your email' required />
                                        </div>
                                        <div className='form-group input-group'>
                                            <label>
                                                <i className='fas fa-lock' />
                                            </label>
                                            <input className='form-control' type='password' name='password' placeholder='Enter your password' minLength='6' maxLength='30' required />
                                        </div>
                                        {
                                            captchaState ?
                                                <>
                                                    <div className='d-flex justify-content-between mb-3'>
                                                        <img className='captcha-img' src={getCaptchaLink(captchaTimestampState)} alt='Captcha' />
                                                        <button
                                                            type='button'
                                                            className='btn btn-info btn-rounded'
                                                            onClick={
                                                                () => {
                                                                    setCaptchaTimestampState(new Date().getTime());
                                                                }
                                                            }
                                                        >
                                                            <i className='fas fa-2x fa-refresh' />
                                                        </button>
                                                    </div>
                                                    <div className='form-group input-group'>
                                                        <label>
                                                            <i className='fas fa-shield' />
                                                        </label>
                                                        <input
                                                            className='form-control'
                                                            type='text'
                                                            name='captcha'
                                                            placeholder='Captcha'
                                                            minLength='5'
                                                            maxLength='5'
                                                            onChange={
                                                                (e) => {
                                                                    e.currentTarget.value = toEnDigits(e.currentTarget.value);
                                                                }
                                                            }
                                                            required
                                                        />
                                                    </div></>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className='d-flex flex-wrap justify-content-between bottom-content'>
                                        <div className='form-check'>
                                            <input type='checkbox' className='form-check-input width-auto' id='remember' name='remember' value='1' />
                                            <label className='form-check-label' htmlFor='remember'>Remember me</label>
                                        </div>
                                        <Link className='lost-pass' to='/forgot'>Forgot password?</Link>
                                    </div>
                                    <div className='button'>
                                        <button className='btn' type='submit' disabled={userLoginInProgress ? 'disabled' : null}>Log in</button>
                                    </div>
                                    <h4 className='create-account'>Don't have an account? <Link to='/register'>Register</Link>
                                    </h4>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
