import './subscription-item.styles.scss';

import React from 'react';
import {useDispatch} from 'react-redux';

import { ReactComponent as Waves } from '../../svgs/waves.svg';
import {setSubscriptionId, setSubscriptionSettingsId} from '../../redux/subscription-page/subscription-page.actions';

const SubscriptionItem = ({ subscription, plan }) => {
    const dispatch = useDispatch();
    
    const setSubscriptionIdDispatch = (subscriptionId) => dispatch(setSubscriptionId(subscriptionId));
    const setSubscriptionSettingsIdDispatch = (subscriptionId) => dispatch(setSubscriptionSettingsId(subscriptionId));
    
    return (
        <div className='pricing-item'>
            {
                subscription.expired || new Date(subscription.expiry_date) <= new Date() ? <div className='color-bar expired'>Expired</div> : null
            }
            <div className='pricing-deco'>
                <Waves />
                <div className='pricing-price'>
                    <span className='pricing-currency'>$</span>
                    {plan.price_discounted}
                </div>
                <h3 className='pricing-title'>{plan.days / 30 > 1 ? (Math.floor(plan.days / 30)) + ' Months' : plan.days + ' Days'}</h3>
            </div>
            <ul className='pricing-feature-list'>
                <li className='pricing-feature'>{subscription.name || plan.name}</li>
            </ul>
            <div className='button mb-4'>
                <button className='btn' onClick={() => {setSubscriptionIdDispatch(subscription.id)}}>See details</button>
            </div>
            {
                plan?.type.endsWith('_binary')
                    ? (
                        <div className='button mb-4'>
                            <button className='btn' onClick={() => {setSubscriptionSettingsIdDispatch(subscription.id)}}>Settings</button>
                        </div>
                    )
                    : undefined
            }
        </div>
    );
};

export default SubscriptionItem;
