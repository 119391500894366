import deviceInfo from '../deviceInfo';
import BasicUserInfo from './BasicUserInfo';

export default class AccountInfo extends BasicUserInfo {
    /**
     * Represents account info
     * @param {number} id
     * @param {string} email
     * @param {string} name
     * @param {'account'|'admin'} type
     * @param {'internal'|'google'|'facebook'|'twitter'} provider - Authentication provider
     * @param {{flag: string, name: string, version: string, platform: string}|null} device
     */
    constructor(
        id,
        email,
        name,
        type,
        provider,
        device = null
    ) {
        super(id, email, name);

        /**
         * type of the user
         * @type {"account"|"admin"}
         */
        this.type = type;
        /**
         * Is provider
         * @type {'internal'|'google'|'facebook'|'twitter'}
         */
        this.provider = provider;
        /**
         * Access Token
         * @type {null}
         * @private
         */
        this._token = null;
        /**
         * User's device signature
         * @type {{flag: string, name: string, version: string, platform: string}|*}
         */
        this.device = device ?? deviceInfo();
    }

    /**
     * Clones the given object to an instance of AccountInfo
     * @param {Object} obj
     * @returns {AccountInfo|null}
     */
    static clone(obj) {
        if (!obj)
            return null;
        return new AccountInfo(
            obj.id,
            obj.email,
            obj.name,
            obj.type,
            obj.provider,
            obj.device
        );
    }

    /**
     * Creates instance of AccountInfo given account details & device info
     * @param {Object} obj
     * @param {{flag: string, name: string, version: string, platform: string}} device
     * @returns {AccountInfo|null}
     */
    static createInstance(obj, device) {
        if (!obj || !device)
            return null;
        return new AccountInfo(
            obj.id,
            obj.email,
            obj.name,
            obj.type,
            obj.provider,
            device
        );
    }

    /**
     * Gets JWT token
     * @returns {string|null}
     */
    get token()
    {
        return this._token;
    }

    /**
     * Sets JWT token
     * @param {string} token
     */
    set token(token)
    {
        this._token = token;
    }
}
