import PropTypes from 'prop-types';
import AccountInfo from '../libs/contracts/AccountInfo';
import NotificationData from '../libs/contracts/NotificationData';

export const DeviceType = PropTypes.shape(
    {
        flag    : PropTypes.string.isRequired,
        name    : PropTypes.string,
        version : PropTypes.string
    });

export const AccountType = PropTypes.instanceOf(AccountInfo);

export const UserType = PropTypes.shape(
    {
        account                 : AccountType,
        loginInProgress         : PropTypes.bool.isRequired,
    });

export const NotificationType = PropTypes.instanceOf(NotificationData);
