import './trader-accounts-details.styles.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCardFooter,
    MDBBtn,
    MDBInput, MDBSpinner, MDBIcon,
} from 'mdb-react-ui-kit';
import AOS from 'aos';

import { setTraderAccount, unsetTraderAccount } from '../../redux/trader-accounts-page/trader-accounts-page.actions';
import SecretCopyText from '../secret-copy-text/secret-copy-text.component';
import { selectTraderAccount } from '../../redux/trader-accounts-page/trader-accounts-page.selectors';
import { updateTraderAccounts } from '../../libs/client/TraderAccountsManager';
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import { getTraderEADownloadLink } from '../../libs/urlFactory';
import { getTraderEAStatus } from '../../libs/client/SubscriptionManager';

const TraderAccountsDetails = () => {
    const dispatch = useDispatch();
    const unsetTraderAccountDispatch = () => dispatch(unsetTraderAccount());
    const setTraderAccountDispatch = (trader) => dispatch(setTraderAccount(trader));
    /**
     *
     * @type {TraderAccount}
     */
    const traderAccount = useSelector(selectTraderAccount);
    const [accountNumberValue, setAccountNumberValue] = useState(traderAccount.account_number);
    const [accountNumberChangeAllowed, setAccountNumberChangeAllowed] = useState(false);
    const onError = (message) => dispatch(addNotification('error', message));
    const onSuccess = (message) => dispatch(addNotification('success', message));
    const [accountNumberChangeInProgress, setAccountNumberChangeInProgress] = useState(false);
    const [tokenResetInProgress, setTokenResetInProgress] = useState(false);

    useEffect(
        () =>{
            AOS.init();
        },
        []
    );
    
    /**
     *
     * @type {'ready'|'not-ready'|'failed'}
     */
    const initialStatus = 'not-ready';
    const [mt4Status, setMt4Status] = useState(initialStatus);
    const [mt5Status, setMt5Status] = useState(initialStatus);
    
    useEffect(
        () =>{
            const getStatus = () => getTraderEAStatus(traderAccount.id)
                .then((status) =>
                {
                    let done = true;
                    
                    if (status.mt4 !== 'not-ready')
                    {
                        setMt4Status(status.mt4);
                    }
                    else
                    {
                        done = false;
                    }
                    if (status.mt5 !== 'not-ready')
                    {
                        setMt5Status(status.mt5);
                    }
                    else
                    {
                        done = false;
                    }
                    
                    if (!done)
                    {
                        setTimeout(getStatus, 5000);
                    }
                })
                .catch(() => setTimeout(getStatus, 5000));
            
            void getStatus();
        },
        [ traderAccount.id ]
    );

    return (
        <div className='trader-account-details position-fixed d-flex align-items-center justify-content-center w-100 h-100'>
            <div className='trader-account-details-bg position-absolute w-100 h-100'
                 onClick={() =>
                 {
                     if (!accountNumberChangeInProgress && !tokenResetInProgress)
                     {
                         unsetTraderAccountDispatch();
                     }
                 }} />
            <MDBCard className='trader-account-details-info position-relative' data-aos="fade-down">
                <MDBCardHeader className='text-center'>Trader Account Details</MDBCardHeader>
                <MDBCardBody className='text-center'>
                    <div className='d-flex mb-4'>
                        <div className='flex-grow-1'>
                            <MDBInput className='w-100' label="Account Number" type="text" value={`${accountNumberValue}`}
                                      onChange={(e) => {
                                          const newValue = parseInt(e.currentTarget.value) || 0;
                                          setAccountNumberValue(newValue);
                                          setAccountNumberChangeAllowed(
                                              newValue !== traderAccount.account_number &&
                                              e.currentTarget.value.length >= 5
                                          );
                                      }}/>
                        </div>
                        <MDBBtn className='ms-2 px-2' color='binarydew' disabled={!accountNumberChangeAllowed}
                                onClick={() =>
                                {
                                    setAccountNumberChangeInProgress(true);
                                    updateTraderAccounts(traderAccount.id, accountNumberValue, undefined)
                                        .then((trader) => {
                                            setTraderAccountDispatch(trader);
                                            setAccountNumberChangeAllowed(false);
                                            setAccountNumberChangeInProgress(false);
                                            onSuccess('Account number successfully changed.');
                                        })
                                        .catch((err) => {
                                            setAccountNumberChangeInProgress(false);
                                            onError(err.message);
                                        });
                                }} >
                            {
                                accountNumberChangeInProgress
                                    ? <>
                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                        Saving...
                                    </>
                                : 'Change'
                            }
                        </MDBBtn>
                    </div>
                    <div className='d-flex mb-4'>
                        <div className='flex-grow-1'>
                            <SecretCopyText type='input' label='Token' text={`${traderAccount.token}`} />
                        </div>
                        <MDBBtn className='ms-2 flex-shrink-0' color='danger' style={{paddingLeft: '14.2px', paddingRight: '14.2px'}}
                                onClick={() =>
                                {
                                    setTokenResetInProgress(true);
                                    updateTraderAccounts(traderAccount.id, undefined, true)
                                        .then((trader) => {
                                            setTraderAccountDispatch(trader);
                                            setTokenResetInProgress(false);
                                            onSuccess('API token has successfully reset.');
                                        })
                                        .catch((err) => {
                                            setTokenResetInProgress(false);
                                            onError(err.message);
                                        });
                                }} >
                            {
                                tokenResetInProgress
                                    ? <>
                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                        Resetting...
                                    </>
                                    : 'Reset'
                            }
                        </MDBBtn>
                    </div>
                    <div className='d-flex mb-4'>
                        <div className='flex-grow-1'>
                            <MDBInput readonly={true} className='w-100' label="Registration date" type="text"
                                      value={ new Date(traderAccount.created_at).toLocaleDateString() } />
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <div className='flex-grow-1'>
                            <MDBInput readonly={true} className='w-100' label="Last update" type="text"
                                      value={
                                        traderAccount.updated_at
                                            ? new Date(traderAccount.created_at).toLocaleDateString()
                                            : 'Never'
                                      }
                            />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <MDBBtn tag='a'
                                className='w-100 me-2 p-0'
                                disabled={mt4Status !== 'ready'}
                                color={mt4Status === 'ready' ? 'binarydew' : mt4Status === 'failed' ? 'danger' : 'warning'}
                                href={getTraderEADownloadLink(traderAccount.id, 4)}
                                target='_blank'>
                            {
                                mt4Status === 'not-ready'
                                    ? <div className='pt-3 pb-3'>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-1' />
                                        Compiling MT4 EA...
                                    </div>
                                    : mt4Status === 'ready'
                                        ? <div className='pt-3 pb-3'>
                                            <MDBIcon fas icon='download' />
                                            <span className='ms-2'>Download MT4...</span>
                                        </div>
                                        : 'MT4 Compilation failed! Please contact support team'
                            }
                        </MDBBtn>
                        <MDBBtn tag='a'
                                className='w-100 p-0'
                                disabled={mt5Status !== 'ready'}
                                color={mt5Status === 'ready' ? 'binarydew' : mt5Status === 'failed' ? 'danger' : 'warning'}
                                href={getTraderEADownloadLink(traderAccount.id, 5)}
                                target='_blank'>
                            {
                                mt5Status === 'not-ready'
                                    ? <div className='pt-3 pb-3'>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-1' />
                                        Compiling MT5 EA...
                                    </div>
                                    : mt5Status === 'ready'
                                        ? <div className='pt-3 pb-3'>
                                            <MDBIcon fas icon='download' />
                                            <span className='ms-2'>Download MT5...</span>
                                        </div>
                                        : 'MT5 Compilation failed! Please contact support team'
                            }
                        </MDBBtn>
                    </div>
                </MDBCardBody>
                <MDBCardFooter className='text-center'>
                    <MDBBtn onClick={unsetTraderAccountDispatch}
                            disabled={accountNumberChangeInProgress || tokenResetInProgress}
                            className='btn-rounded'
                            color='dark'>
                        Close
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        </div>
    );
};

export default TraderAccountsDetails;
