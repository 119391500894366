import './profit-counter.styles.scss';

import React, { useEffect, useRef, useState } from 'react';
import { getRandomInt } from '../../libs/utils';

const ProfitCounter = ({ events }) => {
	const [ profit, setProfit ] = useState(0);
	const [ profitSpeed, setProfitSpeed ] = useState(1);
	const intervalRef = useRef(null);
	
	useEffect(() =>
	{
		const handle = setInterval(() =>
		{
			const randomNumber = getRandomInt(1, 75);
			
			setProfit((p) => p + (randomNumber * profitSpeed));
		}, 200);
		
		return () =>
		{
			clearInterval(handle);
		};
	}, [ profitSpeed ]);
	
	useEffect(() => {
		const handleMouseMove = () => {
			if (profitSpeed !== 10)
			{
				setProfitSpeed(10);
			}
			
			(() =>
			{
				clearTimeout(intervalRef.current);
				intervalRef.current = setTimeout(() =>
				{
					setProfitSpeed(1)
				}, 50);
			})();
		};
		
		const handleTouchMove = () => {
			if (profitSpeed !== 10)
			{
				setProfitSpeed(20);
			}
			
			(() =>
			{
				clearTimeout(intervalRef.current);
				intervalRef.current = setTimeout(() =>
				{
					setProfitSpeed(1)
				}, 50);
			})();
		};
		
		if (events) {
			events.handleMouseMove = handleMouseMove;
			events.handleTouchMove = handleTouchMove;
		}
	}, [events, profitSpeed]);
	
	return (
		<div className="profit-counter">
			<div className="profit container">
				<div>
					$<span>{profit.toLocaleString('en-US', { maximumFractionDigits: 0 })}</span>
				</div>
			</div>
		</div>
	);
};

export default ProfitCounter;
