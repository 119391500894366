export default class TraderAccount {
	/**
	 * Unique ID of the trader account
	 * @type {number}
	 */
	id;
	/**
	 * ID of the owner account
	 * @type {number}
	 */
	account_id;
	/**
	 * MetaTrader terminal type
	 * @type {'mt4'|'mt5'}
	 */
	type;
	/**
	 * Account number on MT4 or MT5 terminal
	 * @type {number}
	 */
	account_number;
	/**
	 * API token
	 * @type {string}
	 */
	token;
	/**
	 * Strategy parameters used by trader
	 * @type {object}
	 */
	parameters;
	/**
	 * Admin's user ID who created this trader.
	 * @type {number}
	 */
	created_by;
	/**
	 * Unix timestamp of the date that this trader account is registered.
	 * @type {number}
	 */
	created_at;
	/**
	 * Unix timestamp of the date that account details of this trader account has been updated.
	 * @type {number}
	 */
	updated_at;
}
