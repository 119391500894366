import './subscription-settings.styles.scss';
import 'rc-slider/assets/index.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBInput, MDBSpinner } from 'mdb-react-ui-kit';
import Slider from 'rc-slider';

import { subscription_settings } from '../../config';
import { unsetSubscriptionSettingsId } from '../../redux/subscription-page/subscription-page.actions';
import { selectSubscriptionSettingsId } from '../../redux/subscription-page/subscription-page.selectors';
import { getSubscription, updateSubscription } from '../../libs/client/SubscriptionManager';
import { addNotification } from '../../redux/core/notifications/notifications.actions';

const SubscriptionSettings = () => {
    const dispatch = useDispatch();

    const unsetSubscriptionSettingsIdDispatch = () => dispatch(unsetSubscriptionSettingsId());

    const subscriptionId = useSelector(selectSubscriptionSettingsId);

    const [settings, setSettings] = useState({
        volume     : subscription_settings.stake_size.min,
        multiplier : subscription_settings.martingale_multiplier.min
    });

    const [risk, setRisk] = useState(subscription_settings.martingale_multiplier.risks.lowest_risk);

    const calcRisk = (value) => {
        Object.values(subscription_settings.martingale_multiplier.risks).forEach(
            riskObj => {
                if(value >= riskObj.min && value <= riskObj.max) {
                    setRisk(riskObj);
                }
            }
        );
    };

    useEffect(
        () => {
            calcRisk(settings.multiplier);
        }
        , [settings]
    );
    
    useEffect(() =>
    {
        if (subscriptionId)
        {
            getSubscription(subscriptionId)
                .then((subObj) =>
                {
                    setSettings({
                        volume     : subObj.parameters.Subscription.Stake,
                        multiplier : subObj.parameters.Subscription.MartingaleParameters.Multiplier,
                    });
                });
        }
    }, [subscriptionId]);
    
    const onVolumeChange = (e) =>
    {
        setSettings(
            {
                ...settings,
                volume: parseFloat(e.currentTarget.value)
            }
        );
    };
    const onMultiplierSliderChange = (value) =>
    {
        setSettings(
            {
                ...settings,
                multiplier: parseFloat(value)
            }
        );
    };
    const onMultiplierInputChange = (e) =>
    {
        setSettings(
            {
                ...settings,
                multiplier: e.target.value < subscription_settings.martingale_multiplier.min ?
                                subscription_settings.martingale_multiplier.min
                                :
                                (
                                    e.target.value > subscription_settings.martingale_multiplier.max ?
                                        subscription_settings.martingale_multiplier.max
                                        :
                                        e.target.value
                                )
            }
        );
    };
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const onSave = () =>
    {
        const onError = (message) => dispatch(addNotification('error', message));
        const onSuccess = (message) => dispatch(addNotification('success', message));
    
        setUpdateInProgress(true);
        updateSubscription(subscriptionId, undefined, undefined, undefined, settings)
            .then(() => {
                setUpdateInProgress(false);
                onSuccess('Settings successfully updated.');
                unsetSubscriptionSettingsIdDispatch();
            })
            .catch((err) => {
                setUpdateInProgress(false);
                onError(err.message);
            });
    };
    
    return (
        <div className='subscription-settings position-fixed d-flex align-items-center justify-content-center w-100 h-100'>
            <div className='subscription-settings-bg position-absolute w-100 h-100' onClick={unsetSubscriptionSettingsIdDispatch} />
            <div className='card subscription-settings-info position-relative' data-aos='fade-down'>
                <div className='card-header text-center'>Subscription Settings</div>
                <div className='card-body'>
                    <MDBInput
                        className='w-100 mb-4'
                        label="Initial Stake Size ($)"
                        onChange={onVolumeChange}
                        value={`${settings.volume}`}
                        type="number"
                        min={subscription_settings.stake_size.min}
                        max={subscription_settings.stake_size.max}
                        step={subscription_settings.stake_size.step}
                    />
                    <div>Multiplier</div>
                    <div className='d-flex align-items-center'>
                        <div className='flex-grow-1'>
                            <Slider
                                min={subscription_settings.martingale_multiplier.min}
                                max={subscription_settings.martingale_multiplier.max}
                                value={settings.multiplier}
                                step={subscription_settings.martingale_multiplier.step}
                                marks={subscription_settings.martingale_multiplier.marks}
                                trackStyle={{ backgroundColor: risk.color }}
                                onChange={onMultiplierSliderChange}
                            />
                        </div>
                        <div className='flex-shrink-0 ms-3' style={{width: '50px'}}>
                            <MDBInput
                                className='w-100 pe-0'
                                type="number"
                                min={subscription_settings.martingale_multiplier.min}
                                max={subscription_settings.martingale_multiplier.max}
                                step={subscription_settings.martingale_multiplier.step}
                                value={`${settings.multiplier}`}
                                onChange={onMultiplierInputChange}
                            />
                        </div>
                    </div>
                    <div className='mt-4' style={{color: risk.color}}>{risk.title}</div>
                </div>
                <div className='card-footer d-flex justify-content-between'>
                    <div className='button'>
                        <MDBBtn className='btn' disabled={updateInProgress} onClick={onSave}>
                            {
                                updateInProgress
                                    ? <>
                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                        Updating...
                                    </>
                                    : 'Save'
                            }
                        </MDBBtn>
                    </div>
                    <MDBBtn className='btn-rounded' color='dark' onClick={unsetSubscriptionSettingsIdDispatch}>close</MDBBtn>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionSettings;
