import UserTypes from './user.types';

/**
 *
 * @param {AccountInfo} account
 * @returns {{payload: account, type: string}}
 */
export const loginUser = (account) => ({
    type: UserTypes.LOGIN_USER,
    payload: account
});

/**
 * Sets the flag to show if login procedure is in progress.
 * @param {boolean} flag
 * @returns {{payload: {flag}, type: string}}
 */
export const setLoginInProgress = (flag) => ({
    type: UserTypes.SET_LOGIN_IN_PROGRESS,
    payload: flag
});

/**
 * Logout user and clear all states
 * @returns {{type: string}}
 */
export const logoutUser = () => ({
    type: UserTypes.LOGOUT_USER
});
