import TraderAccountsPageTypes from './trader-accounts-page.types';

/**
 *
 * @type {TraderAccount|null}
 */
const INITIAL_STATE = null;

/**
 * Cart reducer
 * @param state
 * @param action
 * @returns {TraderAccount|null}
 * @constructor
 */
const TraderAccountsPageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TraderAccountsPageTypes.SET_TRADER_ACCOUNT:
            return action.payload;
        case TraderAccountsPageTypes.UNSET_TRADER_ACCOUNT:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default TraderAccountsPageReducer;
