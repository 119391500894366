import NotificationsTypes from './notifications.types';
import NotificationData from '../../../libs/contracts/NotificationData';

/**
 * Add a notification
 * @param {'info'|'success'|'error'|'warning'} type
 * @param {string} text
 * @returns {{payload: NotificationData, type: string}}
 */
export const addNotification = (type, text) => ({
    type: NotificationsTypes.ADD_NOTIFICATION,
    payload: new NotificationData(type, text)
});

/**
 * Add an error notification
  * @param {string} text
 * @returns {{payload: NotificationData, type: string}}
 */
export const addErrorNotification = (text) => ({
    type: NotificationsTypes.ADD_NOTIFICATION,
    payload: new NotificationData('error', text)
});

/**
 * Remove a notification
 * @param {number} notification_id
 * @returns {{payload, type: string}}
 */
export const removeNotification = notification_id => ({
    type: NotificationsTypes.REMOVE_NOTIFICATION,
    payload: notification_id
});

/**
 * Removes all notifications
 * @returns {{type: string}}
 */
export const removeAllNotifications = () =>
{
    return {
        type : NotificationsTypes.REMOVE_ALL_NOTIFICATIONS
    };
};
