import axios from 'axios';
import deviceInfo from '../deviceInfo';
import {getAPIUrl} from '../urlFactory';
import * as jwt from 'jsonwebtoken';
import {loginUser, logoutUser, setLoginInProgress} from '../../redux/core/user/user.actions';
import {apiErrorRethrow} from '../errors/ErrorHandlers';
import AccountInfo from '../contracts/AccountInfo';

/**
 * Decodes a given JWT token WITHOUT verifying it.
 * Also assigns user's device info to the returning instance.
 * @param token {String}
 * @returns {AccountInfo}
 * @throws {Error}
 */
const decodeToken = (token) => {
    const account = AccountInfo.clone(jwt.decode(token));
    account.device = deviceInfo();
    return account;
}

function _postProcessLogin(accessToken, dispatch) {
    let account = null;
    try {
        account = decodeToken(accessToken);
        // Store token for further use.
        // We should not store this token in local storage
        // because it xss attacks would be possible then.
        // We would use cookies for API calls and this stored
        // token in memory for our websocket connections
        account.token = accessToken;
        dispatch(loginUser(account));
        dispatch(setLoginInProgress(false));
    } catch (err) {
        dispatch(logoutUser());
        account = null;
        dispatch(setLoginInProgress(false));
        throw new Error(`An unexpected error occurred trying to log you in`);
    }
    return account;
}

/**
 * Sends credentials to the server's API and changes global state
 * of the application based on the response received
 * @param username {String}
 * @param password {String}
 * @param captcha {String?}
 * @param dispatch {React.Dispatch<any>}
 * @param remember_me {boolean}
 * @returns {Promise<AccountInfo>}
 */
export async function accountLogin(username, password, captcha, dispatch, remember_me = false) {
    const authURL = new URL('token', getAPIUrl()).href;
    dispatch(setLoginInProgress(true));
    let accessToken = '';
    try {
        const response = await axios.post(
            authURL,
            {
                auth_provider: 'internal',
                username,
                password,
                captcha,
                remember_me
            }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    //'referrer': window.location.hostname,
                },
            });
        accessToken = response.data.accessToken;
    } catch (err) {
        dispatch(logoutUser());
        dispatch(setLoginInProgress(false));
        apiErrorRethrow(err);
    }
    return _postProcessLogin(accessToken, dispatch);
}

/**
 * OAuth login for external providers
 * @param authProvider {'google'|'facebook'|'twitter'}
 * @param token {String}
 * @param dispatch {React.Dispatch<any>}
 * @param remember_me {boolean}
 * @param twitterVerifier {string|undefined}
 * @returns {Promise<AccountInfo>}
 */
export async function accountLoginExternal(authProvider, token, dispatch, remember_me = false, twitterVerifier = undefined) {
    const authURL = new URL('token', getAPIUrl()).href;
    dispatch(setLoginInProgress(true));
    let accessToken = '';
    try {
        const response = await axios.post(
            authURL,
            {
                auth_provider: authProvider,
                token,
                verifier: twitterVerifier,
                remember_me
            }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        accessToken = response.data.accessToken;
    } catch (err) {
        dispatch(logoutUser());
        dispatch(setLoginInProgress(false));
        apiErrorRethrow(err);
    }
    return _postProcessLogin(accessToken, dispatch);
}


/**
 * Checks if we are currently logged in or not and
 * changes the global application state based on that
 * @param dispatch {React.Dispatch<any>}
 * @returns {Promise<AccountInfo>}
 */
export async function loginCheck(dispatch) {
    const authURL = new URL('verify', getAPIUrl()).href;
    let accessToken = '';
    try {
        const response = await axios.get(
            authURL,
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
        accessToken = response.data.accessToken;
    } catch (err) {
        dispatch(logoutUser());
        apiErrorRethrow(err);
    }
    let account = null;
    try {
        account = decodeToken(accessToken);
        // Store token for further use.
        // We should not store this token in local storage
        // because xss attacks would be possible then.
        // We would use cookies for API calls and this stored
        // token in memory for our websocket connections
        account.token = accessToken;
        dispatch(loginUser(account));
    } catch (err) {
        dispatch(logoutUser());
        account = null;
        throw new Error(`An unexpected error occurred trying to log you in`);
    }
    return account;
}

/**
 * Logs the currently logged-in user out of system.
 * @param dispatch {React.Dispatch<any>}
 * @returns {Promise<string>}
 */
export async function accountLogout(dispatch) {
    const logoutURL = new URL('logout', getAPIUrl()).href;
    try {
        const response = await axios.post(
            logoutURL,
            {},
            {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });
        dispatch(logoutUser());
        return response.data.message;
    } catch (err) {
        apiErrorRethrow(err);
    }
}

/**
 * Register a user with an email address
 * @param email
 * @param name
 * @param captcha
 * @param dispatch
 * @return {Promise<{code: number, message: string}>}
 */
export async function accountSignup(email, name, captcha, dispatch) {
    const authURL = new URL('signup', getAPIUrl()).href;
    dispatch(setLoginInProgress(true));
    try {
        const response = await axios.post(
            authURL,
            {
                email,
                name,
                captcha,
            }, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    //'referrer': window.location.hostname,
                },
            });
        return response.data;
    } catch (err) {
        dispatch(setLoginInProgress(false));
        apiErrorRethrow(err);
    }
    return {
        code    : -1,
        message : 'Unexpected error occurred trying to sign you up! Please contact support team.'
    };
}
