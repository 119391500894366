import './pricing-item.styles.scss';

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';

import { selectUserAccount } from '../../redux/core/user/user.selectors';
import { addItem } from '../../redux/core/cart/cart.actions';
import { ReactComponent as Waves } from '../../svgs/waves.svg';

const PricingItem = ({ price, popularRibbon = false, offRibbon = false, onMouseEnterBtn = null, onMouseLeaveBtn = null, user, addItem }) => {
    const navigate = useNavigate();

    const onBtnClick = (e) => {
        if(user) {
            addItem(price);
            navigate('/cart', { state: { from: '/pricing'} });
        } else {
            navigate('/login', { state: { from: '/pricing'} });
        }
    };

    return (
        <div className={'pricing-item' + (price.most_popular ? ' featured' : '')}>
            <div className={ 'color-bar ' + price.color } />
            {
                popularRibbon && price.most_popular ?
                    <div className='pricing-ribbon'>Popular</div>
                    :
                    null
            }
            {
                offRibbon && price.discount_percentage > 0 ?
                    <div className='pricing-ribbon pricing-ribbon-off'>{price.discount_percentage}% OFF</div>
                    :
                    null
            }
            <div className={'pricing-deco'}>
                <Waves />
                <div className='pricing-price'>
                    <span className='pricing-currency'>$</span>
                    {price.price_discounted}
                </div>
                <h3 className='pricing-title'>{price.name}</h3>
            </div>
            <ul className='pricing-feature-list'>
                <li className='pricing-feature'>24/7 AUTOMATED TRADING</li>
                <li className='pricing-feature'>HIGH PRIORITY SUPPORT</li>
                <li className='pricing-feature'>$ 1,000 MINIMUM BALANCE</li>
            </ul>
            <div className='button mb-4'>
                <button className='btn' disabled={!price.enabled} onClick={onBtnClick} onMouseEnter={onMouseEnterBtn} onMouseLeave={onMouseLeaveBtn}>Order now</button>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    user    : selectUserAccount
});

const mapDispatchToProps = dispatch => ({
    addItem : (item) => dispatch(addItem(item))
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingItem);
