import CartTypes from './cart.types';

/**
 *
 * @type {{items: object[], price: number}}
 */
const INITIAL_STATE = {
    items : [],
    price : 0,
};

/**
 * Cart reducer
 * @param state
 * @param action
 * @returns {{items: items: object[], price: number}}
 * @constructor
 */
const CartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CartTypes.ADD_ITEM:
            return {
                items: [ action.payload ].concat(state.items),
                price: state.price + action.payload.price_discounted
            };
        case CartTypes.REMOVE_ITEM:
            const item = state.items.find(action.payload);
            return {
                items: state.items.filter(i => i.id !== item.id),
                price: state.price - item.price_discounted
            };
        case CartTypes.RESET_CART:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default CartReducer;
