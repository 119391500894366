import './trader-accounts.styles.scss';

import React, { useEffect, useState } from 'react';
import AOS from 'aos';

import TraderAccountsItem from "../trader-accounts-item/trader-accounts-item.component";
import { getTraderAccounts } from '../../libs/client/TraderAccountsManager';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import { selectTraderAccount } from '../../redux/trader-accounts-page/trader-accounts-page.selectors';

const TraderAccounts = () => {
    /**
     * @type {TraderAccount[]}
     */
    const initialState = [];
    const [traderAccounts, setTraderAccounts] = useState(initialState);
    const dispatch = useDispatch();
    const selectedTraderAccount = useSelector(selectTraderAccount);

    useEffect(
        () => {
            const onError = (message) => dispatch(addNotification('error', message));
            
            // Not fetching data when trader account modal is closed
            if (traderAccounts.length === 0 || selectedTraderAccount)
            {
                getTraderAccounts()
                    .then((traders) =>
                    {
                        setTraderAccounts(traders);
                        AOS.init();
                    })
                    .catch((err) =>
                    {
                        onError(err.message);
                    });
            }
        }
        , [ dispatch, traderAccounts.length, selectedTraderAccount /* Added this just to reload data when trader is modified */ ]
    );

    return (
        <div data-aos='fade-left'>
            <div className="section-title">
                <h2>Trader Accounts</h2>
            </div>
            <div className='row'>
                {
                    traderAccounts.length > 0
                        ? traderAccounts.map((t) => (
                            <div key={t.id} className='col-12 col-sm-6 col-lg-4 mb-4' data-aos="fade-up" data-aos-delay="200">
                                <TraderAccountsItem traderAccount={t} />
                            </div>
                        ))
                        : <div>You have not registered for a trader account yet.</div>
                }
            </div>
        </div>
    );
};

export default TraderAccounts;
