import './subscription-details.styles.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AOS from 'aos';

import {
    getCopierEAStatus,
    getPrices,
    getSubscription,
    updateSubscription,
} from '../../libs/client/SubscriptionManager';
import { getCopierEADownloadLink } from '../../libs/urlFactory';
import { selectSubscriptionId } from '../../redux/subscription-page/subscription-page.selectors';
import { unsetSubscriptionId } from '../../redux/subscription-page/subscription-page.actions';
import SecretCopyText from '../../components/secret-copy-text/secret-copy-text.component';
import { MDBBtn, MDBCardFooter, MDBIcon, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import { addNotification } from '../../redux/core/notifications/notifications.actions';
import { validateBinaryToken } from '../../libs/client/BrokerManager';

const SubscriptionDetails = () => {
    const dispatch = useDispatch();

    const unsetSubscriptionIdDispatch = () => dispatch(unsetSubscriptionId());

    const subscriptionId = useSelector(selectSubscriptionId);

    const [pricesList, setPricesList] = useState([]);
    const [plan, setPlan] = useState(null);
    const [subscription, setSubscription] = useState(null);
    
    const onError = useCallback(
        (message) => dispatch(addNotification('error', message)),
        [ dispatch ]
    );
    const onSuccess = (message) => dispatch(addNotification('success', message));
    const [accountNumberValue, setAccountNumberValue] = useState(null);
    const [accountNumberChangeAllowed, setAccountNumberChangeAllowed] = useState(false);
    const [accountNumberChangeInProgress, setAccountNumberChangeInProgress] = useState(false);
    const [binaryTokenElem, setBinaryTokenElem] = useState('');
    const [nameElem, setNameElem] = useState('');
    const [nameChangeAllowed, setNameChangeAllowed] = useState(false);

    useEffect(
        () => {
            getPrices()
                .then(
                    prices => {
                        setPricesList(prices);
                        getSubscription(subscriptionId)
                            .then(
                                subscription => {
                                    setSubscription(subscription);
                                    setNameElem(subscription.name || '');
                                    setBinaryTokenElem(subscription.binary_token);
                                    setAccountNumberValue(subscription.mt_account_number)
                                    AOS.init();
                                }
                            )
                            .catch(
                                err => {
                                    console.log(err.message);
                                }
                            );
                    }
                )
                .catch(
                    err => {
                        console.log(err.message);
                    }
                );
        }
        , [ subscriptionId ]
    );

    useEffect(
        () => {
            if(subscription && pricesList.length > 0) {
                setPlan(pricesList.find(p => p.id === subscription.type_id));
            }
        }, [ pricesList, subscription ]
    );

    /**
     *
     * @type {'ready'|'not-ready'|'failed'}
     */
    const initialStatus = 'not-ready';
    const [mt4Status, setMt4Status] = useState(initialStatus);
    const [mt5Status, setMt5Status] = useState(initialStatus);

    useEffect(
        () =>{
            const getStatus = () => getCopierEAStatus(subscriptionId)
                .then((status) =>
                {
                    let done = true;

                    if (status.mt4 !== 'not-ready')
                    {
                        setMt4Status(status.mt4);
                    }
                    else
                    {
                        done = false;
                    }
                    if (status.mt5 !== 'not-ready')
                    {
                        setMt5Status(status.mt5);
                    }
                    else
                    {
                        done = false;
                    }

                    if (!done)
                    {
                        setTimeout(getStatus, 5000);
                    }
                })
                .catch(() => setTimeout(getStatus, 5000));
    
            if (plan?.type.endsWith('_mt'))
            {
                void getStatus();
            }
        },
        [ subscriptionId, plan ]
    );
    
    const [tokenResetInProgress, setTokenResetInProgress] = useState(false);
    const [nameChangeInProgress, setNameChangeInProgress] = useState(false);
    const [binaryTokenReady, setBinaryTokenReady] = useState(null);
    const [binaryTokenInvalid, setBinaryTokenInvalid] = useState(true);
    const [binaryTokenVerificationInProgress, setBinaryTokenVerificationInProgress] = useState(false);
    const onAPITokenChange = (e) => {
        if (/^[A-Za-z0-9]+$/.test(e.currentTarget.value) || e.currentTarget.value === '')
        {
            setBinaryTokenElem(e.currentTarget.value);
            if (binaryTokenReady)
            {
                setBinaryTokenReady(false);
            }
        }
    };
    const onNameChange = (e) => {
        if (e.currentTarget.value.length <= 100)
        {
            setNameElem(e.currentTarget.value);
            setNameChangeAllowed(e.currentTarget.value.length > 0 && e.currentTarget.value !== subscription.name);
        }
    }
    
    if (plan?.type.endsWith('_binary') && binaryTokenElem.length >= 15)
    {
        if (!binaryTokenReady)
        {
            setBinaryTokenReady(true);
        }
    }
    
    useEffect(() =>
    {
        if (plan?.type.endsWith('_binary') && binaryTokenReady && binaryTokenElem !== subscription?.binary_token)
        {
            const controller = new AbortController();
            
            setBinaryTokenInvalid(false);
            setBinaryTokenVerificationInProgress(true);
            validateBinaryToken(binaryTokenElem, controller.signal)
                .then((verified) =>
                {
                    setBinaryTokenInvalid(!verified);
                    
                    if (!verified)
                    {
                        onError('Invalid token. Please make sure you have correctly typed in your token ' +
                                'and the token has all the required scopes (`read`, `trade`, and `trading_information`).');
                    }
                    
                    setBinaryTokenVerificationInProgress(false);
                })
                .catch((err) =>
                {
                    setBinaryTokenInvalid(true)
                    onError(err.message);
                    setBinaryTokenVerificationInProgress(false);
                });
            
            return function onCancel() {
                controller.abort();
            };
        }
    }, [plan, binaryTokenReady, binaryTokenElem, subscription?.binary_token, onError]);

    return (
        <div className='subscription-details position-fixed d-flex align-items-center justify-content-center w-100 h-100'>
            <div className='subscription-details-bg position-absolute w-100 h-100' onClick={unsetSubscriptionIdDispatch} />
            {
                subscription && plan ?
                    (
                        <div className='card subscription-details-info position-relative' data-aos='fade-down'>
                            <div className='card-header text-center'>Subscription Details</div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4 className='fw-bold mb-3'>{plan.name}</h4>
                                        <p>Price: <strong>{plan.price_discounted} $</strong></p>
                                        {
                                            plan.type.endsWith('_mt') ?
                                                <>
                                                    <div className='d-flex mb-4'>
                                                        <div className='flex-grow-1'>
                                                            <MDBInput type='text' label='Name' value={`${nameElem}`} disabled={nameChangeInProgress} onChange={onNameChange} />
                                                        </div>
                                                        <MDBBtn className='ms-2 flex-shrink-0' color='primary' style={{paddingLeft: '14.2px', paddingRight: '14.2px'}}
                                                                disabled={nameChangeInProgress || !nameChangeAllowed}
                                                                onClick={() =>
                                                                {
                                                                    setNameChangeInProgress(true);
                                                                    updateSubscription(subscription.id, nameElem, undefined, undefined, undefined)
                                                                        .then(() => {
                                                                            setNameChangeInProgress(false);
                                                                            subscription.name = nameElem;
                                                                            setNameChangeAllowed(false);
                                                                            onSuccess('Subscription name successfully changed.');
                                                                        })
                                                                        .catch((err) => {
                                                                            setNameChangeInProgress(false);
                                                                            onError(err.message);
                                                                        });
                                                                }} >
                                                            {
                                                                nameChangeInProgress
                                                                    ? <>
                                                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                                                        Updating...
                                                                    </>
                                                                    : 'Update'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                    <div className='d-flex mb-4'>
                                                        <div className='flex-grow-1'>
                                                            <MDBInput className='w-100' label="MetaTrader Account Number" type="text" value={`${accountNumberValue}`}
                                                                      onChange={(e) => {
                                                                          const newValue = parseInt(e.currentTarget.value) || 0;
                                                                          setAccountNumberValue(newValue);
                                                                          setAccountNumberChangeAllowed(
                                                                              newValue !== subscription.mt_account_number &&
                                                                              e.currentTarget.value.length >= 5
                                                                          );
                                                                      }}/>
                                                        </div>
                                                        <MDBBtn className='ms-2 px-2' color='binarydew' disabled={!accountNumberChangeAllowed}
                                                                onClick={() =>
                                                                {
                                                                    setAccountNumberChangeInProgress(true);
                                                                    updateSubscription(subscription.id, undefined, accountNumberValue, undefined, undefined)
                                                                        .then(() => {
                                                                            setAccountNumberChangeAllowed(false);
                                                                            setAccountNumberChangeInProgress(false);
                                                                            onSuccess('Account number successfully changed.');
                                                                        })
                                                                        .catch((err) => {
                                                                            setAccountNumberChangeInProgress(false);
                                                                            onError(err.message);
                                                                        });
                                                                }} >
                                                            {
                                                                accountNumberChangeInProgress
                                                                    ? <>
                                                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                                                        Saving...
                                                                    </>
                                                                    : 'Change'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                    <div className='d-flex mb-4'>
                                                        <div className='flex-grow-1'>
                                                            <SecretCopyText type='input' label='EA Secret Key' text={`${subscription.mt_secret_key}`} colorClass='binarydew' />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className='d-flex mb-4'>
                                                        <div className='flex-grow-1'>
                                                            <MDBInput type='text' label='Name' value={`${nameElem}`} disabled={nameChangeInProgress} onChange={onNameChange} />
                                                        </div>
                                                        <MDBBtn className='ms-2 flex-shrink-0' color='primary' style={{paddingLeft: '14.2px', paddingRight: '14.2px'}}
                                                                disabled={nameChangeInProgress || !nameChangeAllowed}
                                                                onClick={() =>
                                                                {
                                                                    setNameChangeInProgress(true);
                                                                    updateSubscription(subscription.id, nameElem, undefined, undefined, undefined)
                                                                        .then(() => {
                                                                            setNameChangeInProgress(false);
                                                                            subscription.name = nameElem;
                                                                            setNameChangeAllowed(false);
                                                                            onSuccess('Subscription name successfully changed.');
                                                                        })
                                                                        .catch((err) => {
                                                                            setNameChangeInProgress(false);
                                                                            onError(err.message);
                                                                        });
                                                                }} >
                                                            {
                                                                nameChangeInProgress
                                                                    ? <>
                                                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                                                        Updating...
                                                                    </>
                                                                    : 'Update'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                    <div className='d-flex mb-4'>
                                                        <div className='flex-grow-1'>
                                                            <SecretCopyText type='input' label='Binary Token' text={`${binaryTokenElem}`} disabled={false} onChange={ onAPITokenChange } />
                                                        </div>
                                                        <MDBBtn className='ms-2 flex-shrink-0' color='danger' style={{paddingLeft: '14.2px', paddingRight: '14.2px'}}
                                                                disabled={binaryTokenVerificationInProgress || binaryTokenInvalid || !binaryTokenReady}
                                                                onClick={() =>
                                                                {
                                                                    setTokenResetInProgress(true);
                                                                    updateSubscription(subscription.id, undefined, undefined, binaryTokenElem, undefined)
                                                                        .then(() => {
                                                                            setTokenResetInProgress(false);
                                                                            onSuccess('API token successfully updated.');
                                                                        })
                                                                        .catch((err) => {
                                                                            setTokenResetInProgress(false);
                                                                            onError(err.message);
                                                                        });
                                                                }} >
                                                            {
                                                                tokenResetInProgress
                                                                    ? <>
                                                                        <MDBSpinner grow size='sm' role='status' tag='span' />
                                                                        Updating...
                                                                    </>
                                                                    : binaryTokenVerificationInProgress
                                                                        ? <>
                                                                            <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                                                            Checking token...
                                                                        </>
                                                                        : 'Update'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                </>
                                        }
                                        <p>Activation date: <strong>{new Date(subscription.activation_date).toLocaleString()}</strong></p>
                                        <p>Expired: <strong>{subscription.expired || new Date(subscription.expiry_date) <= new Date() ? 'Yes' : 'No'}</strong></p>
                                        <p>Expiry date: <strong>{new Date(subscription.expiry_date).toLocaleString()}</strong></p>
                                        {
                                            plan.type === 'copier_mt' ?
                                                <>
                                                    <div className='col-12 mb-4 d-flex align-items-center'>
                                                        <span className='me-1'>Download EA mt4:</span>
                                                        <MDBBtn tag='a'
                                                                floating={mt4Status === 'ready'}
                                                                disabled={mt4Status !== 'ready'}
                                                                color={mt4Status === 'ready' ? 'binarydew' : mt4Status === 'failed' ? 'danger' : 'warning'}
                                                                href={getCopierEADownloadLink(subscription.id, 4)}
                                                                target='_blank'>
                                                            {
                                                                mt4Status === 'not-ready'
                                                                    ? <>
                                                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                                                        Compiling...
                                                                    </>
                                                                    : mt4Status === 'ready'
                                                                        ? <MDBIcon fas icon='download' />
                                                                        : 'Compilation failed! Please contact support team'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                    <div className='col-12 d-flex align-items-center'>
                                                        <span className='me-1'>Download EA mt5:</span>
                                                        <MDBBtn tag='a'
                                                                floating={mt5Status === 'ready'}
                                                                disabled={mt5Status !== 'ready'}
                                                                color={mt5Status === 'ready' ? 'binarydew' : mt5Status === 'failed' ? 'danger' : 'warning'}
                                                                href={getCopierEADownloadLink(subscription.id, 5)}
                                                                target='_blank'>
                                                            {
                                                                mt5Status === 'not-ready'
                                                                    ? <>
                                                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                                                        Compiling...
                                                                    </>
                                                                    : mt5Status === 'ready'
                                                                        ? <MDBIcon fas icon='download' />
                                                                        : 'Compilation failed! Please contact support team'
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            <MDBCardFooter className='text-center'>
                                <MDBBtn onClick={unsetSubscriptionIdDispatch}
                                        className='btn-rounded'
                                        color='dark'>
                                    Close
                                </MDBBtn>
                            </MDBCardFooter>
                        </div>
                    )
                    :
                    null
            }
        </div>
    );
};

export default SubscriptionDetails;
